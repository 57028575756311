import "./consentListItemOpenButtonView.scss";

import { TextHelper } from "~/tools/textHelper";

import { AcceptsMouseFocusView } from "../../common/mouseSupport/acceptsMouseFocusView";

export class ConsentListItemOpenButton extends AcceptsMouseFocusView {
  constructor() {
    super("", "consentListItemOpenButton");

    TextHelper.createTextElement({
      rootElement: this.rootElement,
      classname: "text closed",
      content: t("generic.open"),
    });

    TextHelper.createTextElement({
      rootElement: this.rootElement,
      classname: "text expanded",
      content: t("generic.close"),
    });
  }
}
