import { DS } from "dslib-tv";

export type PaginatedFetch<M> = (page: number) => Promise<[M[], string | undefined]>;

export class PaginatedModelSource<M> extends DS.ModelSource<M> {
  // initial params
  private _fetching$: DS.Listenable<boolean>;
  get fetching$(): DS.IListenable<boolean> {
    return this._fetching$;
  }

  private _onError: (() => void) | undefined;

  paginatedFetch: PaginatedFetch<M>;
  pageLastFetched?: number;
  pageFetching?: number;
  /** our list of potential waiters on fetch, waiting for previous fetches to complete
   * if fetch of page 0 is going on and fetch 1 is asked, it's "queued" in the sense it's added
   * to that object, waiting on page 0.
   * Once fetch 0 completes, we go over the list, and resolve the waiting ones, which in turn will fetch, and potentially unblock other calls, etc
   */
  private _waitingForPreviousContent: {
    requested: { from: number; count?: number };
    resolve: (value: M[] | PromiseLike<M[]>) => void;
  }[] = [];

  /**
   * Create a paginated model source
   *
   * This class extends ModelSource. This class is commonly used for paginate endPoints
   *
   * Constraints on the api: it needs to return an array of objects, which will be modeled as M by the modelFactory
   */
  constructor(paginatedFetch: PaginatedFetch<M>, onError?: () => void) {
    super([]);
    // we're starting without data
    this.isComplete$.value = false;
    this.version$.value = undefined;
    this.paginatedFetch = paginatedFetch;
    this._fetching$ = new DS.Listenable<boolean>(false);
    this._onError = onError;
  }

  async fetch(from: number, count?: number): Promise<M[]> {
    if (this.pageFetching !== undefined) {
      // we need to wait our turn
      Log.api.debug(`(${from}, ${count}), on hold, page ${this.pageFetching} fetching`);
      return new Promise<M[]>(resolve => this._waitingForPreviousContent.push({ requested: { from, count }, resolve }));
    }

    Log.api.debug(`(${from}, ${count}), starting loop, has ${this.value.length} elements`);
    this._fetching$.value = true;

    while (this.value.length < from + (count ?? Number.MAX_SAFE_INTEGER) && !this.isComplete$.value) {
      try {
        // need to fetch more. We're giving a generic counter param as the input - we don't know how the API handles pagination
        // we'll keep asking incremental pages until we get back enough data for the fetch - or it's complete
        // first requested page is -1
        this.pageFetching = (this.pageLastFetched ?? -1) + 1;

        Log.api.info(`(${from}, ${count}), fetching`, this.pageFetching);

        const [response, url] = await this.paginatedFetch(this.pageFetching);
        // NOT TRIGGERING THE LISTENABLE ON PURPOSE
        // the fetchModelSource would always mutate its content. It's not what we want here
        // I feel it's kind of a hack
        this.value.push(...response);
        // we've retrieved something so now we have a version - and it won't change
        this.version$.value = this.version$.value ?? 1;
        this.isComplete$.value = url === undefined;

        // data has been retrieved
        this.pageLastFetched = this.pageFetching;
        this.pageFetching = undefined;
        // }
      } catch (error: unknown) {
        Log.api.error("modelSource fetch error", error);
        this.isComplete$.value = true;
        this._onError?.();

        // we couldn't fetch last page, so we stay where we were
        this.pageFetching = undefined;
      }
    }

    Log.api.info(`(${from}, ${count}), ended loop, now has ${this.value.length} elements`);

    const pendingMaybeFetches = [...this._waitingForPreviousContent];
    this._waitingForPreviousContent = [];
    pendingMaybeFetches.forEach(pendingMaybeFetch => {
      Log.api.debug(
        `from: ${pendingMaybeFetch?.requested.from}, count: ${pendingMaybeFetch?.requested.count}, resumed`
      );
      pendingMaybeFetch.resolve(this.fetch(pendingMaybeFetch?.requested.from, pendingMaybeFetch?.requested.count));
    });

    Log.api.debug(`(${from}, ${count}), returning data`);
    this._fetching$.value = false;
    // now we have enough data! return what was asked for
    return this.value.slice(from, count !== undefined ? from + count : undefined);
  }
}
