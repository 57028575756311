import { GigyaErrorReponse } from "./gigya/models";

export class StatusError extends Error {
  readonly statusCode: number;
  constructor(statusCode: number, message?: string) {
    super(message);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, StatusError.prototype);

    this.statusCode = statusCode;
  }
}

export class GigyaError extends Error {
  readonly errorCode: number;
  readonly response: GigyaErrorReponse;

  constructor(errorCode: number, message: string, response: unknown) {
    super(message);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, GigyaError.prototype);

    this.errorCode = errorCode;
    this.response = GigyaErrorReponse.parse(response);
  }
}
