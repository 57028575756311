import "./playerPanel.scss";

import { Config } from "../../../config";
import { DS } from "../../../libs";
import { IPlayer } from "../../../tools/player";
import { MediaType, WidgetRecommandation } from "../../../utils/rtbf/models";
import { EmptyContentView } from "../../contents/empty/emptyContentView";
import { BingeView } from "../binge/bingeView";
import { ButtonReplayView, WidgetRecommandationView } from "../recommandation/widgetRecommandation";

type PlayerPanelType = "RECOMMENDED" | "REPLAY" | "BINGE";

export class PlayerPanel extends DS.View {
  private _widget: WidgetRecommandation | undefined;
  private _className = "playerPanel";

  constructor(player: IPlayer, mediaType: MediaType) {
    super("PlayerPanel");

    this._widget = player.asset$.value?.recommended ?? undefined;

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "gradient",
    });

    const src: PlayerPanelType[] = [];
    if (player.asset$.value?.resource?.embed?.next !== undefined) {
      src.push("BINGE");
    } else {
      if (player.asset$.value?.resource?.isAdReplacement !== true) {
        src.push("REPLAY");
        this._className += " replayButton";
      }
      if (this._hasRecommandations() === true) {
        src.push("RECOMMENDED");
      }
    }

    this.delegate = DS.createListComponent(
      {
        id: "",
        className: this._className,
        modelSource$: new DS.ModelSource<PlayerPanelType>(src),
        viewFactory: item => {
          switch (item) {
            case "REPLAY":
              return new ButtonReplayView();
            case "RECOMMENDED":
              if (this._widget !== undefined) return new WidgetRecommandationView(this._widget);
              return new EmptyContentView();
            case "BINGE":
              return new BingeView(mediaType);
          }
        },
        onSelect: btn => {
          switch (btn) {
            case "REPLAY":
              player.trackAnalytics("reco_watch_again");
              player.play(false, true);
          }
          return true;
        },
        scrollingMode: { type: DS.ScrollingType.none, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
      },
      list => {
        void list.setFocusOnIndex(
          player.asset$.value?.resource?.embed?.next === undefined && this._hasRecommandations() === true ? 1 : 0
        );
      }
    );
  }

  onHidden() {
    this.rootElement.style.visibility = "hidden";
  }

  onShown() {
    this.rootElement.style.visibility = "visible";
  }

  private _hasRecommandations() {
    return this._widget !== undefined && this._widget.data.length > 0;
  }
}
