import "./contentDetailsView.scss";

import { Config } from "~/config";
import { DS } from "~/libs";

import { Casting, Tag } from "../../../../utils/rtbf/models";
import { ClassType } from "../contentButtonView/contentButtonView";
import { ContentCastingView } from "../contentCastingView/contentCastingView";
import { ContentDescriptionView } from "../contentDescriptionView/contentDescriptionView";
import { ContentDetailsTitleView } from "../contentDetailsTitleView/contentDetailsTitleView";
import { ContentTagView } from "../contentTagView/contentTagView";

export enum DetailType {
  title = "title",
  description = "description",
  casting = "casting",
  tags = "tags",
}

export class ContentDetailsView extends DS.View implements DS.IPage {
  private _list: DS.IListComponent<
    DetailType,
    ContentDetailsTitleView | ContentDescriptionView | ContentCastingView | ContentTagView
  >;
  private _modelSource$ = new DS.ModelSource<DetailType>([]);

  constructor(type: ClassType, title: string, description: string, casting: Casting[], tags: Tag[]) {
    super("DetailsPage", "detailsView");

    this._modelSource$.value = [DetailType.title, DetailType.description];
    if (casting.length !== 0) this._modelSource$.value.push(DetailType.casting);
    if (tags.length !== 0) this._modelSource$.value.push(DetailType.tags);

    this.delegate = this._list = DS.createListComponent({
      id: "",
      className: "detailsContainer",
      modelSource$: this._modelSource$,
      viewFactory: item => {
        switch (item) {
          case DetailType.title:
            return new ContentDetailsTitleView(title);
          case DetailType.description:
            return new ContentDescriptionView(description, { rejectFocus: false, hideOverflow: false });
          case DetailType.casting:
            return new ContentCastingView(type, casting);
          case DetailType.tags:
            return new ContentTagView(type, tags);
        }
      },
      scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
      scrollDuration: Config.scrollDuration,
      mouseSupport: Config.mouseSupport && {
        focusRange: "page",
        wheelScrollBy: 300,
      },
    });
  }

  isPopup = () => true;
}
