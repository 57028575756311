/* eslint-disable @typescript-eslint/no-explicit-any */
import { string, z } from "zod";

import { Image, Logo, LogoFast, ProductOffering, Rating, Stamp } from "./base";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const isISODate = require("is-iso-date");

export const MediaType = z.enum(["VIDEO", "AUDIO"]);

export type MediaType = z.infer<typeof MediaType>;
/**
 *
 * ChannelCard
 *
 */
export const ChannelCard = z
  .object({
    resourceType: z.literal("CHANNEL"),
    label: z.string(),
    fastTv: z.boolean(),
    path: z.string(),
    pathTitle: z.string(),
    logo: Logo.nullable(),
    logoFast: LogoFast.nullable(),
    streamId: z.string(),
    streamAssetId: z.string().nullable(),
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }));
export type ChannelCard = z.infer<typeof ChannelCard>;

/**
 *
 * ProgramCard
 *
 * */
export const ProgramCard = z.object({
  id: string(),
  title: z.string(),
  path: z.string(),
  pathTitle: z.string(),
  resourceType: z.literal("PROGRAM"),
  illustration: Image.nullable(),
  hero: Image.nullable(),
});

export type ProgramCard = z.infer<typeof ProgramCard>;

/**
 *
 * RadioLiveCard
 *
 * */
export const RadioLiveCard = z
  .object({
    id: z.string(),
    title: z.string().nullable(),
    resourceType: z.literal("RADIO_LIVE"),
    subtitle: z.string().nullable(),
    duration: z.number(),
    scheduledFrom: z.string().refine(isISODate, { message: "Not a valid ISO string date " }).or(z.literal("")),
    scheduledTo: z.string().refine(isISODate, { message: "Not a valid ISO string date " }).or(z.literal("")),
    channel: ChannelCard,
  })
  .transform(({ id, ...input }) => ({ id: Math.random().toString(36).substring(2, 9), mediaId: id, ...input }));

export type RadioLiveCard = z.infer<typeof RadioLiveCard>;

/**
 *
 * ProductCard
 * TODO: everything is optional see if we can do better
 */
export const ProductCard = z
  .object({
    id: z.string().optional(),
    resourceType: z.literal("PRODUCT_OFFERING").optional(),
    path: z.string().nullable().optional(),
    pathTitle: z.string().nullable().optional(),
    illustration: Image.nullable().optional(),
    productOffering: ProductOffering.optional(),
    label: z.string().optional(),
    tagline: z.string().optional().optional(),
    type: z.enum(["SUBSCRIPTION", "Pass"]).optional(),
    promotionShortLabel: z.string().optional(),
    durationLabel: z.string().optional(),
    stamp: Stamp.nullish()
      .transform(arg => (arg ? arg : undefined))
      .optional(),
  })
  .transform(({ id, ...input }) => ({ id: id ?? Math.random().toString(36).substring(2, 9), ...input }));

export type ProductCard = z.infer<typeof ProductCard>;

/**
 *
 * MediaBase extended MediaCard and MediaPremiumCard
 *
 */
const MediaBase = z.object({
  id: z.string(),
  assetId: z.string().nullable(),
  path: z.string(),
  pathTitle: z.string(),
  type: MediaType,
  title: z.string(),
  subtitle: z
    .string()
    .nullish()
    .transform(arg => (arg != null ? arg : undefined)),
  description: z.string().nullish(),
  publishedFrom: z.string().refine(isISODate, { message: "Not a valid ISO string date " }).or(z.literal("")),
  publishedTo: z.string().refine(isISODate, { message: "Not a valid ISO string date " }).or(z.literal("")),
  duration: z.number(),
  rating: Rating.optional(),
  hasAudioDescriptions: z.boolean(),
  hasMultilingualVersions: z.boolean(),
  hasSubtitles: z.boolean(),
  stamp: Stamp.nullish().transform(arg => (arg ? arg : undefined)),
  illustration: Image.nullable(),
  channelLabel: z.string(),
  categoryLabel: z.string(),
  products: z.array(
    z.object({
      id: z.string(),
      label: z.string(),
    })
  ),
  releaseDate: z.string().nullable(),
});

/**
 * MEDIA CARD
 */
export const MediaCard = MediaBase.extend({
  resourceType: z.literal("MEDIA"),
});
export type MediaCard = z.infer<typeof MediaCard>;

/**
 * MEDIA PREMIUM CARD
 */

export const MediaPremiumCard = MediaCard.extend({
  resourceType: z.literal("MEDIA_PREMIUM"),
});
export type MediaPremiumCard = z.infer<typeof MediaPremiumCard>;

/**
 *
 * TvLiveCard
 *
 */
export const TvLiveCard = z.object({
  id: z.string(),
  assetId: z.string().nullable(),
  streamId: z.string().nullable(),
  resourceType: z.literal("LIVE"),
  path: z.string(),
  pathTitle: z.string(),
  type: MediaType,
  title: z.string(),
  subtitle: z.string(),
  scheduledFrom: z.string().refine(isISODate, { message: "Not a valid ISO string date " }).or(z.literal("")),
  scheduledTo: z.string().refine(isISODate, { message: "Not a valid ISO string date " }).or(z.literal("")),
  duration: z.number(),
  rating: Rating.nullish().transform(arg => arg ?? undefined),
  hasAudioDescriptions: z
    .boolean()
    .nullish()
    .transform(arg => (arg ?? false ? arg : undefined)),
  hasMultilingualVersions: z
    .boolean()
    .nullish()
    .transform(arg => (arg ?? false ? arg : undefined)),
  hasSubtitles: z
    .boolean()
    .nullish()
    .transform(arg => (arg ?? false ? arg : undefined)),
  illustration: Image.nullable(),
  channelLabel: z.string(),
  categoryLabel: z.string(),
  stamp: Stamp.nullish().transform(arg => (arg ? arg : undefined)),
  releaseDate: z.string().nullable(),
  isAdReplacement: z.boolean(),
});

export type TvLiveCard = z.infer<typeof TvLiveCard>;
export function isTvLiveCard(data: any): data is TvLiveCard {
  return (data as TvLiveCard)?.resourceType === "LIVE";
}

/**
 *
 * CategoryCard
 *
 */
export const CategoryCard = z
  .object({
    resourceType: z.literal("CATEGORY"),
    label: z.string(),
    path: z.string(),
    pathTitle: z.string(),
    illustration: Image.nullable(),
    logo: Logo.nullish().transform(arg => (arg ? arg : undefined)),
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }));

export type CategoryCard = z.infer<typeof CategoryCard>;

/**
 *
 * PromoBox
 *
 */
export const PromoBoxCard = z
  .object({
    title: z.string(),
    subtitle: z.string(),
    description: z.string().nullable(),
    externalUrl: z.string().nullable(),
    deeplink: z
      .string()
      .nullable()
      .transform(arg => (arg != null ? arg : undefined)),
    mediaId: z.string().nullable(),
    promoType: z.enum(["DEFAULT", "LIVE"]),
    image: Image,
    logo: Logo.nullish().transform(arg => (arg ? arg : undefined)),
    backgroundColor: z.string(),
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }));

export type PromoBoxCard = z.infer<typeof PromoBoxCard>;

/**
 *
 * tab list Card
 * A card for tab
 *
 */
export const TabListCard = z
  .object({
    title: z.string(),
    subtitle: z.string(),
    type: z.literal("MEDIA_LIST"),
    contentPath: z.string(),
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }));

export type TabListCard = z.infer<typeof TabListCard>;

/**
 *
 * CTA Card
 *
 */
export const CtaCard = z
  .object({
    label: z.string(),
    path: z.string(),
    action: z.string().optional(),
  })
  .transform(({ ...input }) => ({
    id: Math.random().toString(36).substring(2, 9),
    resourceType: "CTA" as const,
    resource: null,
    media: null,
    contentPath: input.path,
    ...input,
  }));

export type CtaCard = z.infer<typeof CtaCard>;

/* quick link card
 * A card for QuickLinks
 *
 */

export const QuickLinkCard = z
  .object({
    label: z.string(),
    path: z.string(),
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }));

export type QuickLinkCard = z.infer<typeof QuickLinkCard>;

/**
 * FavoriteCard
 *
 */
export const FavoriteCard = z
  .object({
    id: z.string(),
    createdAt: z.string().refine(isISODate, { message: "Not a valid ISO string date " }).optional(),
    updatedAt: z.string().refine(isISODate, { message: "Not a valid ISO string date " }).optional(),
    resource: z.union([MediaCard, ProgramCard]).nullable(),
  })
  .transform(({ ...input }) => ({ resourceType: "FAVORITE" as const, ...input }));

export type FavoriteCard = z.infer<typeof FavoriteCard>;
export type FavoriteCardWithResource = Omit<FavoriteCard, "resource"> & { resource: MediaCard | ProgramCard };

/**
 * PlayHistoryIdsCard and PlayHistoryCard
 *
 */
const PlayHistoryCardBase = z.object({
  mediaId: z.string(),
  isOngoing: z.boolean(),
  currentOffset: z.number(),
  updatedAt: z.string().refine(isISODate, { message: "Not a valid ISO string date " }).or(z.number()).optional(),
});
export const PlayHistoryIdsCard = PlayHistoryCardBase;
export type PlayHistoryIdsCard = z.infer<typeof PlayHistoryCardBase>;

export const PlayHistoryCard = PlayHistoryCardBase.extend({
  media: MediaCard.nullable(),
}).transform(({ ...input }) => ({
  id: Math.random().toString(36).substring(2, 9),
  resourceType: "HISTORY" as const,
  ...input,
}));

export type PlayHistoryCard = z.infer<typeof PlayHistoryCard>;
export type PlayHistoryCardWithMedia = Omit<PlayHistoryCard, "media"> & { media: MediaCard };

/**
 * Guest Card
 *
 */
export const GuestCard = z
  .object({
    description: z.string(),
    icon: Image.nullable(),
    background: Image.nullable(),
    cta: CtaCard.nullable(),
  })
  .transform(({ ...input }) => ({
    id: Math.random().toString(36).substring(2, 9),
    resourceType: "GUEST" as const,
    media: null,
    resource: null,
    path: "",
    ...input,
  }));

export type GuestCard = z.infer<typeof GuestCard>;

/**
 * Empty Card
 *
 */
export const EmptyCard = z
  .object({
    description: z.string(),
    icon: Image.nullable(),
    background: Image.nullable(),
    cta: CtaCard.nullable(),
  })
  .transform(({ ...input }) => ({
    id: Math.random().toString(36).substring(2, 9),
    resourceType: "EMPTY" as const,
    media: null,
    resource: null,
    path: "",
    ...input,
  }));

export type EmptyCard = z.infer<typeof EmptyCard>;

export const StateCard = z
  .object({
    empty: EmptyCard,
    guest: GuestCard,
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }));

export type StateCard = z.infer<typeof StateCard>;
