import { DS } from "~/libs";

import { REDBEE_ANALYTICS_EVENT } from "./constants";
import { EventPool } from "./EventPool";
import { IDeviceStats, TCastModel } from "./utils/helpers";

const DEFAULT_HEADERS = {
  "content-type": "application/json",
};

const referrer = (): string => {
  if (document.referrer) return document.referrer;
  if (window.self === window.top) return "";
  // if in an iframe we want to get the parent page url
  // window.top can throw an error ( just by accessing it! ) so it needs to be wrapped.
  try {
    return window.top?.location.href ?? "";
  } catch (e) {
    return "";
  }
};

const DEFAULT_DEVICE: Partial<IDeviceInfo> = {
  model: "DESKTOP",
  appType: "browser",
  pageUrl: window.location.href,
  referrer: referrer(),
};

interface IPlayerFields {
  Technology: string;
  TechVersion: string;
  Player: string;
  Version: string;
  StreamingTechnology: string;
  CDNVendor?: string;
  AnalyticsPostInterval?: number;
  AnalyticsBucket?: number;
  AnalyticsTag?: string;
}

type TTestSegment = string | undefined;

export type Model = "TV" | "TABLET" | "PHONE" | "DESKTOP" | "PLAYSTATION 4" | "PLAYSTATION 5" | TCastModel;

export interface IDeviceInfo {
  id: string;
  model?: Model;
  modelNumber?: string;
  manufacturer?: string;
  appType?: "samsung_tv" | "lg_tv" | "chromecast" | "browser";
  appName?: string;
  pageUrl?: string;
  referrer?: string;
  deviceStats?: IDeviceStats;
}

export interface IRedBeeAnalyticsOptions {
  customer: string;
  businessUnit: string;
  sessionToken: string;
  /**
   * @deprecated exposureBaseUrl is deprecated and will be removed in a future release. Please  use analyticsBaseUrl instead.
   */
  exposureBaseUrl?: string;
  analyticsBaseUrl?: string;
  device: IDeviceInfo;
  debug?: boolean;
}

export class RedBeeAnalytics {
  private _customer: string;
  private _businessUnit: string;
  private _baseUrl = "";
  private _customerSpecificBaseUrl = "";

  private _assetId = "";

  private _eventPool: EventPool | null = null;
  private _sessionId: string | null = null;
  private _startTime: number;
  private readonly _requestHeaders: { [header: string]: string };
  private _droppedFramesCount = 0;

  private _device: IDeviceInfo;
  private _playerFields: IPlayerFields;

  private _testSegment: TTestSegment = undefined;
  private readonly _debug: boolean = false;

  constructor({
    customer,
    businessUnit,
    sessionToken,
    exposureBaseUrl,
    analyticsBaseUrl,
    device,
    debug,
  }: IRedBeeAnalyticsOptions) {
    if (!customer || !businessUnit) {
      throw new Error("No Customer or BusinessUnit defined");
    }
    this._customer = customer;
    this._businessUnit = businessUnit;
    if (!sessionToken) {
      throw new Error("No sessionToken defined");
    }

    this._debug = debug ?? false;

    if (analyticsBaseUrl != null) {
      this.setAnalyticsBaseUrl(analyticsBaseUrl);
    } else if (exposureBaseUrl != null) {
      Log.analytics.warn(
        "exposureBaseUrl is deprecated and will be removed in a future release. Please use analyticsBaseUrl instead."
      );
      this.setAnalyticsBaseUrl(exposureBaseUrl);
    }

    this._requestHeaders = Object.assign(
      {
        Authorization: `Bearer ${sessionToken}`,
      },
      DEFAULT_HEADERS
    );

    this._device = Object.assign({}, DEFAULT_DEVICE, device);
    this._playerFields = {
      Player: "",
      Version: "",
      Technology: "",
      TechVersion: "",
      StreamingTechnology: "",
    };

    this._startTime = 0;
  }

  private async _send(payload: any): Promise<boolean | undefined> {
    const url = new URL(`${this._customerSpecificBaseUrl}/eventsink/send`).toString();
    const data = {
      DispatchTime: Date.now(),
      Customer: this._customer,
      BusinessUnit: this._businessUnit,
      SessionId: this._sessionId,
      Payload: payload,
    };
    if (this._debug) {
      Log.analytics.debug("send", url, data, this._requestHeaders);
      return;
    }
    try {
      const response = await DS.HttpRequest.make(url, {
        method: "post",
        body: JSON.stringify(data),
        headers: this._requestHeaders,
      });
      return response.status === 200;
    } catch (error) {
      Log.analytics.error("_send() failed", error);
      payload.forEach((event: any) => {
        this._sessionId != null && this._eventPool?.add(this._sessionId, event);
      });
    }
  }

  private _isActive(): boolean {
    return !!(this._sessionId != null && this._eventPool);
  }

  setAnalyticsBaseUrl(analyticsBaseUrl: string): void {
    this._baseUrl = analyticsBaseUrl;
    this._customerSpecificBaseUrl = new URL(
      `/v2/customer/${this._customer}/businessunit/${this._businessUnit}`,
      this._baseUrl
    ).toString();
  }

  clear(): void {
    Log.analytics.info("clear", this._sessionId);
    this._sessionId = null;
    this._eventPool = null;
  }

  async init(sessionId: string): Promise<boolean | undefined> {
    if (!this._customerSpecificBaseUrl) {
      throw new Error("[RedBeeAnalytics] analyticsBaseUrl was never set");
    }
    if (!sessionId) {
      Log.analytics.info("no sessionId provided, session will not be tracked");
      return;
    }
    Log.analytics.debug("init", sessionId);
    this.clear();
    this._sessionId = sessionId;
    this._eventPool = new EventPool(sessionId);
    this._eventPool.on(EventPool.SEND, this._send.bind(this));
    if (this._debug) return;
  }

  deviceInfoEvent(): void {
    if (!this._isActive()) {
      return;
    }
    const deviceInfoEvent = {
      EventType: "Device.Info",
      Height: window.screen.height,
      Width: window.screen.width,
      Name: window.navigator.product,
      ...this.getDefaultFields(),
    };

    this._sessionId != null && this._eventPool?.add(this._sessionId, deviceInfoEvent);
  }

  created({
    sessionId = this._sessionId,
    assetId,
    autoplay = true,
    player,
    playerVersion,
    streamingTechnology,
    cdnProvider,
    analyticsPostInterval,
    analyticsBucket,
    analyticsTag,
    analyticsBaseUrl,
    analyticsPercentage,
    requestId,
    deviceModel,
    deviceModelNumber,
    deviceStats,
  }: {
    sessionId?: string | null;
    assetId?: string;
    autoplay?: boolean;
    player?: string;
    playerVersion?: string;
    streamingTechnology?: string;
    cdnProvider?: string;
    analyticsPostInterval?: number;
    analyticsBucket?: number;
    analyticsTag?: string;
    analyticsBaseUrl?: string;
    analyticsPercentage?: number;
    requestId?: string;
    deviceModel?: Model;
    deviceModelNumber?: string;
    deviceStats?: IDeviceStats;
  }): void {
    if (!this._isActive()) {
      return;
    }
    Log.analytics.debug("created", sessionId);
    this._sessionId = sessionId;
    this._assetId = assetId ?? "";
    this._device.model = deviceModel || this._device.model;
    this._device.deviceStats = deviceStats;
    this._device.modelNumber = deviceModelNumber ?? this._device.modelNumber;

    this.updatePlayerFields({
      Player: player,
      Version: playerVersion,
      StreamingTechnology: streamingTechnology,
      ...(cdnProvider != null && { CDNVendor: cdnProvider }),
      ...(analyticsPostInterval != null && {
        AnalyticsPostInterval: analyticsPostInterval,
      }),
      ...(analyticsBucket != null && { AnalyticsBucket: analyticsBucket }),
      ...(analyticsTag != null && { AnalyticsTag: analyticsTag }),
      ...(analyticsBaseUrl != null && { AnalyticsBaseUrl: analyticsBaseUrl }),
      ...(analyticsPercentage != null && { AnalyticsPercentage: analyticsPercentage }),
    });

    if (analyticsPostInterval != null) {
      this._eventPool?.updateInterval(analyticsPostInterval);
    }

    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.CREATED,
      AssetId: this._assetId,
      AutoPlay: autoplay,
      ...(requestId != null && { RequestId: requestId }),
      ...this.getDefaultFields(),
    };

    sessionId != null && this._eventPool?.add(sessionId, payload);
    this.deviceInfoEvent();
  }

  assetLoaded({
    sessionId = this._sessionId,
    assetId,
    programId,
  }: {
    sessionId?: string | null;
    assetId?: string;
    programId?: string;
  }): void {
    if (!this._isActive()) {
      return;
    }
    Log.analytics.debug("HandshakeStarted", this._assetId);
    if (assetId != null && this._assetId !== assetId) {
      this._assetId = assetId;
    }
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.HANDSHAKE,
      AssetId: this._assetId,
      ...(programId != null && { programId }),
      ...this.getDefaultFields(),
    };
    sessionId != null && this._eventPool?.add(sessionId, payload);
  }

  playerReady({
    playSessionId = this._sessionId,
    startTime,
    playerTech,
    techVersion,
    testSegment,
  }: {
    playSessionId?: string | null;
    startTime: number;
    playerTech: string;
    techVersion?: string;
    testSegment?: TTestSegment;
  }): void {
    if (!this._isActive()) {
      return;
    }
    Log.analytics.debug("playerReady", playSessionId);
    this._startTime = startTime;
    this._testSegment = testSegment;

    this.updatePlayerFields({
      Technology: playerTech,
      TechVersion: techVersion,
    });

    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.PLAYER_READY,
      ...this.getDefaultFields(),
    };

    playSessionId != null && this._eventPool?.add(playSessionId, payload);
    this._sessionId = playSessionId;
  }

  playing({
    sessionId = this._sessionId,
    bitrate,
    duration = 0,
    mediaLocator,
  }: {
    sessionId?: string | null;
    bitrate?: number;
    duration?: number;
    mediaLocator: string;
  }): void {
    if (!this._isActive() || sessionId == null) {
      return;
    }
    Log.analytics.debug("playing");
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.PLAYING,
      AssetId: this._assetId,
      Bitrate: bitrate,
      VideoLength: duration * 1000,
      MediaLocator: mediaLocator,
      //OffsetTime: this._startTime,
      ...this.getDefaultFields(),
    };
    this._eventPool?.add(sessionId, payload, true);
  }

  paused(currentTime: number): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    Log.analytics.debug("paused", currentTime);
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.PAUSED,
      ...this.getDefaultFields(currentTime),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  seeked(seekTime: number): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    Log.analytics.debug("seeked", seekTime);
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.SEEKED,
      ...this.getDefaultFields(seekTime),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  startCasting(): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    Log.analytics.debug("startCasting", Date.now());
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.START_CASTING,
      ...this.getDefaultFields(),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  stopCasting(): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    Log.analytics.debug("stopCasting", Date.now());
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.STOP_CASTING,
      ...this.getDefaultFields(),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  startAirPlay(): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    Log.analytics.debug("startAirPlay", Date.now());
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.START_AIRPLAY,
      ...this.getDefaultFields(),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  stopAirPlay(): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    Log.analytics.debug("stopAirPlay", Date.now());
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.STOP_AIRPLAY,
      ...this.getDefaultFields(),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  heartbeat(nowTime: number): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    Log.analytics.debug("heartbeat");
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.HEARTBEAT,
      ...this.getDefaultFields(nowTime),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  resume(currentTime: number): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    Log.analytics.debug("resume", currentTime);
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.RESUME,
      ...this.getDefaultFields(currentTime),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  bitrateChanged(nowTime: number, bitrate: number): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    if (!nowTime) return;
    Log.analytics.debug("bitrateChanged", nowTime, bitrate);
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.BITRATE_CHANGED,
      Bitrate: bitrate,
      ...this.getDefaultFields(nowTime),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  buffering(nowTime: number): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    Log.analytics.debug("buffering", nowTime);
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.BUFFERING,
      ...this.getDefaultFields(nowTime),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  buffered(nowTime: number): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    Log.analytics.debug("buffered", nowTime);
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.BUFFERED,
      ...this.getDefaultFields(nowTime),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  dispose(nowTime?: number): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    Log.analytics.debug("dispose");
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.ABORTED,
      ...this.getDefaultFields(nowTime),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  mediaEnded(nowTime: number): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    Log.analytics.debug("mediaEnded");
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.ENDED,
      ...this.getDefaultFields(nowTime),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  adStarted(nowTime: number, adId: string): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    Log.analytics.debug(`ssai adStarted, adId: ${adId}`);
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.AD_STARTED,
      AdMediaId: adId,
      ...this.getDefaultFields(nowTime),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  adCompleted(nowTime: number, adId?: string): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    Log.analytics.debug(`ssai adCompleted, adId: ${adId}`);
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.AD_ENDED,
      AdMediaId: adId,
      ...this.getDefaultFields(nowTime),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  programChanged(nowTime: number, programId: string | undefined): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    Log.analytics.debug("programChanged", nowTime, programId);
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.PROGRAM_CHANGED,
      ProgramId: programId,
      ...this.getDefaultFields(nowTime),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  error({
    currentTime,
    errorCode,
    errorMessage,
    supportedDevice = true,
    deviceStats,
  }: {
    currentTime: number;
    errorCode?: number;
    errorMessage: string;
    supportedDevice?: boolean;
    deviceStats?: IDeviceStats;
  }): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    Log.analytics.debug("error", errorCode, errorMessage);
    this._device.deviceStats = deviceStats;
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.ERROR,
      AssetId: this._assetId,
      Code: errorCode,
      Message: errorMessage,
      SupportedDevice: supportedDevice ? "yes" : "no",
      ...this.getDefaultFields(currentTime),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  drmSessionUpdate(type: string): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    Log.analytics.debug("drmSessionUpdate", type);
    const payload = {
      EventType: REDBEE_ANALYTICS_EVENT.DRM_SESSION_UPDATED,
      Code: 0,
      Message: type,
      ...this.getDefaultFields(),
    };
    this._eventPool?.add(this._sessionId, payload);
  }

  droppedFrames(droppedFrames: number): void {
    if (!this._isActive() || this._sessionId == null) {
      return;
    }
    this._droppedFramesCount = droppedFrames;
  }

  updatePlayerFields(updates: Partial<IPlayerFields>) {
    this._playerFields = {
      ...this._playerFields,
      ...updates,
    };
  }

  getOffset(currentTime: number): number {
    return Math.floor(currentTime * 1000);
  }

  getDefaultFields(currentTime?: number) {
    return {
      Timestamp: Date.now(),
      DeviceId: this._device.id,
      DeviceModel: this._device.model,
      DeviceModelNumber: this._device.modelNumber,
      DeviceStats: this._device.deviceStats,
      Manufacturer: this._device.manufacturer,
      AppType: this._device.appType,
      ...(this._device.appName != null && { AppName: this._device.appName }),
      PageUrl: this._device.pageUrl,
      Referrer: this._device.referrer,
      UserAgent: window.navigator.userAgent,
      OS: DS.platform.type,
      OSVersion: DS.platform.version,
      OffsetTime: typeof currentTime === "number" && currentTime > 0 ? this.getOffset(currentTime) : undefined,
      TotalNumberOfDroppedFrames: this._droppedFramesCount,
      TestSegment: this._testSegment,
      ...this._playerFields,
    };
  }

  public destroy() {
    this._eventPool?.destroy();
    this._eventPool = null;
    this.clear();
  }
}

// old analytics https://github.com/ericssonbroadcastservices/emp-analytics
