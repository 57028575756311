import "./preferencePage.scss";
import "~/pages/settings/settingsPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { ConsentListType, ConsentListView } from "~/components/views/consent/list/consentListView";
import { ConsentSidebar } from "~/components/views/consent/sidebar/consentSidebar";
import { PreferenceChoiceButtonType } from "~/components/views/consent/sidebarButton/consentSidebarButton";
import { Config } from "~/config";
import { DS } from "~/libs";
import { RootMenuPage } from "~/rootMenu/rootMenuPage";
import { PreferenceType } from "~/utils/rtbf/models";

import { DIDOMI } from "../../../utils/didomi";

export class PreferencePageView extends AcceptsMouseFocusView implements DS.IPage {
  list: DS.IListComponent<"widget" | "choiceButton", ConsentListView | ConsentSidebar>;

  constructor() {
    super("preferencePageView", "preferencePageView settingsPages");

    const preferences: PreferenceType[] = [];

    Object.values(DIDOMI.PreferenceList).filter(value => {
      preferences.push({ id: value });
    });

    const choices: PreferenceChoiceButtonType[] = [];

    Object.keys(PreferenceChoiceButtonType).filter(key => choices.push(key as PreferenceChoiceButtonType));

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageTitle",
      innerText: t("consent_preferences.title"),
    });

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "preferencePageDesc",
      innerText: t("consent_preferences.description"),
    });

    this.delegate = this.list = DS.createListComponent(
      {
        id: "preferenceWidgetID",
        className: "preferenceWidget",
        modelSource$: new DS.ModelSource(["widget", "choiceButton"]),
        viewFactory: item => {
          switch (item) {
            case "widget":
              return new ConsentListView({
                type: ConsentListType.preferences,
                preferences: preferences,
              });
            case "choiceButton":
              return new ConsentSidebar(choices);
          }
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
      },
      list => {
        void list.setFocusOnIndex(0);
      }
    );
  }
}

export class PreferencePage extends RootMenuPage implements DS.IPage {
  constructor() {
    super("ConsentPage", "RootMenuPage", () => {
      return new PreferencePageView();
    });
  }
}

export class PreferencePageFull extends DS.View implements DS.IPage {
  constructor() {
    super("ConsentPage", "ConsentPageFull");
    this.delegate = new PreferencePageView();
  }
}
