import { TCString } from "@iabtcf/core";

import { CallFlow } from "~/callflow";
import { Partners } from "~/utils/didomi/export";

import { Config } from "../datas";
import { DidomiApi } from "../datas/api/apiDidomi";
import { navigationStack } from "../main";
import { loaderPageSingleton } from "../pages/splash/splashPage";
import { DIDOMI } from "../utils/didomi";
import { ConsentEvent, PartnerType, Purposes } from "../utils/rtbf/models";
import { DevicePreferenceHelper } from "./devicePreferencesManager";
import { RootPageHelper } from "./errorPageHelper";
import { deepCopyObject } from "./objectHelper";
import { TrackingHelper } from "./trackingHelper";

export class ConsentManager {
  private static _instance: ConsentManager;
  private _purposes: Purposes[] = Object.values(DIDOMI.PreferenceList).map(values => {
    return {
      id: values,
      enabled: false,
    };
  });
  private _partners: PartnerType[] = DIDOMI.Partners;
  private _partnersIds = this._partners.map(partner => partner.id);
  private _savedConsentEvent: ConsentEvent = this._defaultConsentInit();
  private _tempConsentEvent: ConsentEvent = this._defaultConsentInit();

  //METHODS
  /**
   * Define next step completion
   */
  static get instance() {
    return (ConsentManager._instance = ConsentManager._instance ?? new ConsentManager());
  }

  get partners() {
    return this._partners;
  }

  get consentEvent() {
    return this._savedConsentEvent;
  }

  setConsents(consents: ConsentEvent | undefined) {
    if (consents !== undefined) {
      this._tempConsentEvent = deepCopyObject(consents);
      this._savedConsentEvent = deepCopyObject(consents);
    }
  }

  /**
   * initialize a consent event object
   * @returns initialize object
   */
  private _defaultConsentInit() {
    // deepcopy because we don't want objects to have same reference
    return deepCopyObject({
      organization_id: Config().DIDOMI.organization_id,
      consents: {
        purposes: [...this._purposes],
        vendors: {
          enabled: [],
          disabled: [...this._partnersIds],
        },
      },
    });
  }

  /**
   * Actions to execute once consents are validated
   */
  private _onCompletion(deletePages = 1) {
    if (RootPageHelper.isTopPage() === false) {
      navigationStack.destroyStack();
      navigationStack.pushPage(loaderPageSingleton);
      void CallFlow.initHome();
    } else {
      for (let index = 0; index < deletePages; index++) {
        navigationStack.removePage(navigationStack.topPage);
      }
    }
  }

  /**
   * check if All partners are enabled
   */
  areAllPartnersAccepted() {
    if (DevicePreferenceHelper.tcfcs === "") return false;
    const tcModel = TCString.decode(DevicePreferenceHelper.tcfcs);
    // FIXME: Find a proper way to check consents
    // -2 because Google Analytics Products and Mediamath sdk_id aren't recognized by the lib
    // (Fix it by defining athe correct CMP with a JSON or an URL ?)
    return tcModel.vendorConsents.size === Partners.length - 2;
  }

  /**
   * allow all partners
   */
  acceptAllPartners() {
    this._tempConsentEvent.consents.vendors.disabled = [];
    this._tempConsentEvent.consents.vendors.enabled = [...this._partnersIds];
  }

  /**
   * allow all preferences
   */
  acceptAllPreferences() {
    this._tempConsentEvent.consents?.purposes?.forEach(purpose => (purpose.enabled = true));
  }

  /**
   * unallow all partners
   */
  rejectAllPartners() {
    this._tempConsentEvent.consents.vendors.enabled = [];
    this._tempConsentEvent.consents.vendors.disabled = [...this._partnersIds];
  }

  /**
   * unallow all preference
   */
  rejectAllPreferences() {
    this._tempConsentEvent.consents?.purposes?.forEach(purpose => (purpose.enabled = false));
  }

  /**
   * give status of a partner as enable or disable
   * @param id of the partner
   * @returns true or false
   */
  getPartnerStatus(id: string) {
    if (this.consentEvent.consents.vendors.enabled)
      return this.consentEvent.consents.vendors.enabled.includes(id) ?? false;
    return false;
  }

  /**
   * give status of a preference as enable or disable
   * @param id of the purpose
   * @returns true or false
   */
  getPurposeStatus(id: string) {
    if (this.consentEvent.consents.purposes !== undefined)
      return this.consentEvent.consents.purposes.find(purpose => id === purpose.id)?.enabled ?? false;
    return false;
  }

  /**
   * enable or disable a purpose
   * @param id id of the purpose
   */
  togglePurpose(id: string) {
    let isPurposeRegistered = false;
    const tempConsentEvent: ConsentEvent = deepCopyObject(this._tempConsentEvent);
    tempConsentEvent.consents.purposes?.find((purpose, index) => {
      if (purpose.id === id && tempConsentEvent.consents?.purposes !== undefined) {
        tempConsentEvent.consents.purposes[index].enabled = !tempConsentEvent.consents?.purposes[index].enabled;
        isPurposeRegistered = true;
        return true;
      }
    });
    if (!isPurposeRegistered) {
      tempConsentEvent.consents.purposes?.push({
        id,
        enabled: true,
      });
    }
    this._tempConsentEvent = {
      ...tempConsentEvent,
    };
  }

  /**
   * enable or disable a partner
   * @param id id of the partner
   */
  togglePartner(id: string) {
    // deep copy to avoid any reference issues
    const tempConsentEvent: ConsentEvent = deepCopyObject(this._tempConsentEvent);
    if (tempConsentEvent.consents.vendors.disabled?.includes(id) === false) {
      tempConsentEvent.consents.vendors.disabled?.push(id);
      tempConsentEvent.consents.vendors.enabled = tempConsentEvent.consents.vendors.enabled?.filter(
        partner => partner !== id
      );
    } else {
      tempConsentEvent.consents.vendors.enabled?.push(id);
      tempConsentEvent.consents.vendors.disabled = tempConsentEvent.consents.vendors.disabled?.filter(
        partner => partner !== id
      );
    }
    this._tempConsentEvent = {
      ...tempConsentEvent,
    };
  }

  // SYNCING METHOD
  syncTempConsent() {
    this._tempConsentEvent = deepCopyObject(this._savedConsentEvent);
  }

  syncTempPartnersConsent() {
    // deep copy to avoid any reference issues
    this._tempConsentEvent = deepCopyObject({
      ...this._savedConsentEvent,
      consents: {
        purposes: this._tempConsentEvent.consents.purposes,
        vendors: {
          enabled: this._savedConsentEvent.consents.vendors.enabled,
          disabled: this._savedConsentEvent.consents.vendors.disabled,
        },
      },
    });
  }

  /**
   * Register preference as permanant and send them to didomi
   */
  async validatePartnersChoice() {
    // deep copy to avoid any reference issues
    this._savedConsentEvent = deepCopyObject({
      ...this._tempConsentEvent,
      consents: {
        ...this._tempConsentEvent.consents,
        purposes:
          this._savedConsentEvent.consents.purposes !== undefined ? [...this._savedConsentEvent.consents.purposes] : [],
        vendors: {
          enabled: this._tempConsentEvent.consents.vendors.enabled,
          disabled: this._tempConsentEvent.consents.vendors.disabled,
        },
      },
    });
    this._onCompletion();
    this.syncTempPartnersConsent();
    if (!this.areAllPartnersAccepted()) {
      TrackingHelper.reLoadChartbeat();
    } else {
      TrackingHelper.reLoadChartbeat(false);
    }
    await DidomiApi.sendDidomiEvent(this._savedConsentEvent);
  }

  /**
   * Register preference as permanant and send them to didomi
   */
  async validatePreferencesChoice() {
    // deep copy to avoid any reference issues
    this._savedConsentEvent = deepCopyObject({
      ...this._tempConsentEvent,
      consents: {
        ...this._tempConsentEvent.consents,
        vendors: { ...this._tempConsentEvent.consents.vendors },
        purposes:
          this._tempConsentEvent.consents.purposes !== undefined ? [...this._tempConsentEvent.consents.purposes] : [],
      },
    });
    this._onCompletion();
    await DidomiApi.sendDidomiEvent(this._savedConsentEvent);
  }

  /**
   * Validate all didomi event
   */
  async validateAll(deletePages = 1) {
    this.acceptAllPartners();
    this.acceptAllPreferences();
    this._savedConsentEvent = deepCopyObject(this._tempConsentEvent);
    this._onCompletion(deletePages);
    await DidomiApi.sendDidomiEvent(this._savedConsentEvent);
  }

  /**
   * Reject all didomi event
   */
  async rejectAll(deletePages = 1) {
    this.rejectAllPartners();
    this.rejectAllPreferences();
    this._savedConsentEvent = deepCopyObject(this._tempConsentEvent);
    this._onCompletion(deletePages);
    await DidomiApi.sendDidomiEvent(this._savedConsentEvent);
  }
}

export const ConsentHelper = ConsentManager.instance;
