import "./personnalizeSubtitlePage.scss";
import "~/pages/settings/settingsPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";
import { RootMenuPage } from "~/rootMenu/rootMenuPage";

import { FocusTracker } from "../../../components/focusTracker";
import { SubtitlePersonnalizationButtonView } from "../../../components/views/settings/settingsSelectionButtonView/settingsSelectionButtonView";
import { Config } from "../../../config";
import { DevicePreferenceHelper, SubtitlePersonnalizationType } from "../../../tools/devicePreferencesManager";

export enum PersonnalizeSubitleButtons {
  TOP = "TOP",
  BOTTOM = "BOTTOM",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  BIG = "BIG",
  STYLEA = "STYLEA",
  STYLEB = "STYLEB",
  STYLEC = "STYLEC",
  STYLED = "STYLED",
}

export enum PersonnalizationItems {
  position = "position",
  size = "size",
  style = "style",
}

export class PersonnalizeSubtitlesPage extends RootMenuPage implements DS.IPage {
  constructor() {
    super("PersonnalizeSubtitlePage", "RootMenuPage", () => {
      return new PersonnalizeSubtitlesPageView();
    });
  }
}

export class PersonnalizeSubtitlesPageView extends AcceptsMouseFocusView implements DS.IPage {
  private _subtitlePreview: HTMLElement;
  static subtitlePersonnalizationMap = {
    SMALL: "2.25em",
    MEDIUM: "2.75em",
    BIG: "3.75em",

    STYLEA: {
      color: "#fff",
      backgroundColor: "transparent",
      textShadow:
        "rgb(0, 0, 0) 3px 0px 0px, rgb(0, 0, 0) 2.83487px 0.981584px 0px, rgb(0, 0, 0) 2.35766px 1.85511px 0px, rgb(0, 0, 0) 1.62091px 2.52441px 0px, rgb(0, 0, 0) 0.705713px 2.91581px 0px, rgb(0, 0, 0) -0.287171px 2.98622px 0px, rgb(0, 0, 0) -1.24844px 2.72789px 0px, rgb(0, 0, 0) -2.07227px 2.16926px 0px, rgb(0, 0, 0) -2.66798px 1.37182px 0px, rgb(0, 0, 0) -2.96998px 0.42336px 0px, rgb(0, 0, 0) -2.94502px -0.571704px 0px, rgb(0, 0, 0) -2.59586px -1.50383px 0px, rgb(0, 0, 0) -1.96093px -2.27041px 0px, rgb(0, 0, 0) -1.11013px -2.78704px 0px, rgb(0, 0, 0) -0.137119px -2.99686px 0px, rgb(0, 0, 0) 0.850987px -2.87677px 0px, rgb(0, 0, 0) 1.74541px -2.43999px 0px, rgb(0, 0, 0) 2.44769px -1.73459px 0px, rgb(0, 0, 0) 2.88051px -0.838247px 0px",
    },
    STYLEB: {
      color: "#fff",
      backgroundColor: "rgba(0,0,0,0.5)",
      textShadow:
        "rgb(0, 0, 0) 3px 0px 0px, rgb(0, 0, 0) 2.83487px 0.981584px 0px, rgb(0, 0, 0) 2.35766px 1.85511px 0px, rgb(0, 0, 0) 1.62091px 2.52441px 0px, rgb(0, 0, 0) 0.705713px 2.91581px 0px, rgb(0, 0, 0) -0.287171px 2.98622px 0px, rgb(0, 0, 0) -1.24844px 2.72789px 0px, rgb(0, 0, 0) -2.07227px 2.16926px 0px, rgb(0, 0, 0) -2.66798px 1.37182px 0px, rgb(0, 0, 0) -2.96998px 0.42336px 0px, rgb(0, 0, 0) -2.94502px -0.571704px 0px, rgb(0, 0, 0) -2.59586px -1.50383px 0px, rgb(0, 0, 0) -1.96093px -2.27041px 0px, rgb(0, 0, 0) -1.11013px -2.78704px 0px, rgb(0, 0, 0) -0.137119px -2.99686px 0px, rgb(0, 0, 0) 0.850987px -2.87677px 0px, rgb(0, 0, 0) 1.74541px -2.43999px 0px, rgb(0, 0, 0) 2.44769px -1.73459px 0px, rgb(0, 0, 0) 2.88051px -0.838247px 0px",
    },
    STYLEC: {
      color: "#FFCC00",
      backgroundColor: "#000",
      textShadow: "none",
    },
    STYLED: {
      color: "#000",
      backgroundColor: "#fff",
      textShadow: "none",
    },
  };
  constructor() {
    super("personnalizeSubtitlePageView", "personnalizeSubtitlePageView settingsPages");

    this._subtitlePreview = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "subtitlePreview",
      innerText: t("languageSubtitlePage.subtitlePreview"),
    });

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageTitle",
      innerText: t("languageSubtitlePage.personnalizeSubtitle"),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageIcon",
      style: {
        backgroundImage: `url(${require("@assets/images/languageSubtitle/languageSubtitle_logo.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    const modelSourcePersonnalizationList = [
      PersonnalizationItems.position,
      PersonnalizationItems.size,
      PersonnalizationItems.style,
    ];

    this.delegate = DS.createListComponent({
      id: `personnalizeSubtitle/list`,
      className: "personnalizeSubtitleList",
      modelSource$: new DS.ModelSource(modelSourcePersonnalizationList),
      viewFactory: type => {
        switch (type) {
          case PersonnalizationItems.position:
            return new SubtitlePersonnalizationPositionWidgetView();
          case PersonnalizationItems.style:
            return new SubtitlePersonnalizationStyleWidgetView();
          case PersonnalizationItems.size:
            return new SubtitlePersonnalizationSizeWidgetView();
          default:
            return new DS.View("");
        }
      },
      scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
      scrollDuration: Config.scrollDuration,
      mouseSupport: Config.mouseSupport,
    });

    DevicePreferenceHelper.subtitlePersonnalizationListener$.didChange(
      value => this._updatePreviewStyle(value),
      this,
      true
    );
  }

  private _updatePreviewStyle(value: SubtitlePersonnalizationType) {
    const subtitleStyle = value.style;
    const subtitleSize = value.size;

    this._subtitlePreview.style.color = PersonnalizeSubtitlesPageView.subtitlePersonnalizationMap[subtitleStyle].color;
    this._subtitlePreview.style.backgroundColor =
      PersonnalizeSubtitlesPageView.subtitlePersonnalizationMap[subtitleStyle].backgroundColor;
    this._subtitlePreview.style.textShadow =
      PersonnalizeSubtitlesPageView.subtitlePersonnalizationMap[subtitleStyle].textShadow;
    this._subtitlePreview.style.fontSize = PersonnalizeSubtitlesPageView.subtitlePersonnalizationMap[subtitleSize];
  }
}

export class SubtitlePersonnalizationWidgetView extends DS.View {
  focusTracker?: FocusTracker;
  selectedButton$!: DS.Listenable<SubtitlePersonnalizationButtonView>;
  list?: DS.IListComponent<PersonnalizeSubitleButtons, SubtitlePersonnalizationButtonView>;
  modelSourcebuttonList!: PersonnalizeSubitleButtons[];
  type!: PersonnalizationItems;
  constructor() {
    super("subtitlePersonnalizationWidgetView", "subtitlePersonnalizationWidgetView");
  }

  /**
   * update Preferences in localhost
   * @param btn the btn that will be selected
   */
  private _updatePreference(btn: PersonnalizeSubitleButtons) {
    const devicePreferences = DevicePreferenceHelper.cachedPreferences();
    const subtitlepersonnalizationPreferences = devicePreferences.subtitlePersonnalization;
    subtitlepersonnalizationPreferences[this.type as string] = btn;
    devicePreferences.subtitlePersonnalization = {
      ...subtitlepersonnalizationPreferences,
    };
    DevicePreferenceHelper.save(devicePreferences);
  }

  /**
   * Render the view
   */
  buildSelection() {
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "personnalizedLabel",
      innerText: t(`languageSubtitlePage.${this.type}`),
    });
    this.delegate = this.list = DS.createListComponent(
      {
        id: `subtitlePersonnalizationList/list`,
        className: "subtitlePersonnalizationList",
        modelSource$: new DS.ModelSource(this.modelSourcebuttonList),
        viewFactory: idButton => {
          const view = new SubtitlePersonnalizationButtonView(idButton, this.type);
          if (idButton.toString() === DevicePreferenceHelper.cachedPreferences().subtitlePersonnalization[this.type]) {
            this.selectedButton$ = new DS.Listenable<SubtitlePersonnalizationButtonView>(view);
            view.select();
          }
          return view;
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
        onSelect: btn => {
          this.selectedButton$.value = this.list?.viewFromId(btn) as SubtitlePersonnalizationButtonView;
          this._updatePreference(btn);
          return true;
        },
      },
      list => {
        this.focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        this.collectRelease(this.focusTracker.onRelease);
        this.selectedButton$.didChange(
          (newView, oldView) => {
            oldView.removeSelection();
            newView.select();
          },
          this,
          false
        );
      }
    );
  }
}
class SubtitlePersonnalizationPositionWidgetView extends SubtitlePersonnalizationWidgetView {
  constructor() {
    super();
    this.modelSourcebuttonList = [PersonnalizeSubitleButtons.TOP, PersonnalizeSubitleButtons.BOTTOM];
    this.type = PersonnalizationItems.position;
    this.buildSelection();
  }
}

class SubtitlePersonnalizationSizeWidgetView extends SubtitlePersonnalizationWidgetView {
  constructor() {
    super();
    this.modelSourcebuttonList = [
      PersonnalizeSubitleButtons.SMALL,
      PersonnalizeSubitleButtons.MEDIUM,
      PersonnalizeSubitleButtons.BIG,
    ];
    this.type = PersonnalizationItems.size;
    this.buildSelection();
  }
}

class SubtitlePersonnalizationStyleWidgetView extends SubtitlePersonnalizationWidgetView {
  constructor() {
    super();
    this.modelSourcebuttonList = [
      PersonnalizeSubitleButtons.STYLEA,
      PersonnalizeSubitleButtons.STYLEB,
      PersonnalizeSubitleButtons.STYLEC,
      PersonnalizeSubitleButtons.STYLED,
    ];
    this.type = PersonnalizationItems.style;
    this.buildSelection();
  }
}
