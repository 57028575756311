import "./faqPage.scss";
import "~/pages/settings/settingsPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";

import { RootMenuPage } from "../../../rootMenu/rootMenuPage";
import { createQRCode } from "../../../tools/uiHelper";

export class FaqPageView extends AcceptsMouseFocusView implements DS.IPage {
  constructor() {
    super("faqPageView", "faqPageView settingsPages");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageTitle",
      innerText: t("faq.faq_title"),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageIcon",
      style: {
        backgroundImage: `url(${require("@assets/images/faq/faq_logo.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });

    const faqPageDesc = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageDesc",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: faqPageDesc,
      className: "pageSubtitle",
      innerText: t("faq.faq_subtitle"),
    });
    DS.DOMHelper.createElement({
      tagName: "span",
      parent: faqPageDesc,
      className: "pageText1",
      innerText: t("generic.scanQRCode_text"),
    });
    DS.DOMHelper.createElement({
      tagName: "span",
      parent: faqPageDesc,
      className: "pageWebsite",
      innerText: t("faq.faq_website"),
    });
    DS.DOMHelper.createElement({
      tagName: "span",
      parent: faqPageDesc,
      className: "pageText1",
      innerText: t("faq.faq_desc2"),
    });

    this.rootElement.appendChild(createQRCode(t("faq.faq_qrcode_url"), "settingsQRCode"));
  }
}

export class FAQPage extends RootMenuPage implements DS.IPage {
  constructor() {
    super("FAQPage", "RootMenuPage", () => {
      return new FaqPageView();
    });
  }
}
