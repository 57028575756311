import "./consentSidebar.scss";

import { DS } from "~/libs";
import { PartnerPage, PartnerPageFull } from "~/pages/consent/partner/partnerPage";

import { Config } from "../../../../config";
import { navigationStack } from "../../../../main";
import { CookiesPage, CookiesPageFull } from "../../../../pages/settings/cookies/cookiesPage";
import {
  GeneralConditionPage,
  GeneralConditionPageFull,
} from "../../../../pages/settings/generalCondition/generalConditionPage";
import { PrivacyPage, PrivacyPageFull } from "../../../../pages/settings/privacy/privacyPage";
import { ConsentHelper } from "../../../../tools/consentHelper";
import { RootPageHelper } from "../../../../tools/errorPageHelper";
import {
  ConsentSidebarButtonView,
  PartnersChoiceButtonType,
  PreferenceChoiceButtonType,
} from "../sidebarButton/consentSidebarButton";

export class ConsentSidebar extends DS.View {
  constructor(buttonTypes: (PreferenceChoiceButtonType | PartnersChoiceButtonType)[]) {
    super("", "consentSidebar");

    this.delegate = DS.createListComponent({
      id: "",
      className: "consentSidebarList",
      modelSource$: new DS.ModelSource(buttonTypes),
      viewFactory: type => {
        return new ConsentSidebarButtonView(type);
      },
      scrollingMode: { type: DS.ScrollingType.none, horizontal: false },
      scrollDuration: Config.scrollDuration,
      mouseSupport: Config.mouseSupport,
      onSelect: type => {
        switch (type) {
          case PreferenceChoiceButtonType.partners:
            ConsentHelper.syncTempPartnersConsent();
            navigationStack.pushPage(this.isRootMenuPage() === true ? new PartnerPage() : new PartnerPageFull());
            break;
          case PreferenceChoiceButtonType.cookies:
            navigationStack.pushPage(this.isRootMenuPage() === true ? new CookiesPage() : new CookiesPageFull());
            break;
          case PreferenceChoiceButtonType.privacy:
            navigationStack.pushPage(this.isRootMenuPage() === true ? new PrivacyPage() : new PrivacyPageFull());
            break;
          case PreferenceChoiceButtonType.cgu:
            navigationStack.pushPage(
              this.isRootMenuPage() === true ? new GeneralConditionPage() : new GeneralConditionPageFull()
            );
            break;
          case PreferenceChoiceButtonType.acceptAllPreference:
            void ConsentHelper.validateAll();
            break;
          case PartnersChoiceButtonType.acceptAllPartners:
            void ConsentHelper.validateAll(2);
            break;
          case PartnersChoiceButtonType.rejectAllPartners:
            void ConsentHelper.rejectAll(2);
            break;
          case PartnersChoiceButtonType.validatePartners:
            void ConsentHelper.validatePartnersChoice();
            break;
          case PreferenceChoiceButtonType.rejectAllPreference:
            void ConsentHelper.rejectAll();
            break;
          case PreferenceChoiceButtonType.validatePreferences:
            void ConsentHelper.validatePreferencesChoice();
            break;
        }
        return true;
      },
    });
  }

  isRootMenuPage() {
    return RootPageHelper.isTopPage();
  }
}
