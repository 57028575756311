import { z } from "zod";

import {
  AdditionalLink,
  Casting,
  CategoryCard,
  ChannelCard,
  Cta,
  MediaCard,
  MediaPremiumCard,
  ProductCard,
  ProgramCard,
  Rating,
  Tag,
  TvLiveCard,
} from ".";
import { Filters, FilterTypes, Image, Logo, ProductOffering } from "./base";

const isISODate = require("is-iso-date");

const MediaType = z.enum(["VIDEO", "AUDIO"]);

export const WidgetFilter = z
  .object({
    label: z.string(),
    fieldLabel: z.string(),
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }));

export const WidgetSearch = z
  .object({
    contentPath: z.string().nullable(),
    placeholder: z.string(),
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }));

const pageTypeWithTitle = ["CATEGORY", "CHANNEL", "PATH"] as const;
export const PageTypeWithTitle = z.enum(pageTypeWithTitle);
export type PageTypeWithTitle = z.infer<typeof PageTypeWithTitle>;

const pageTypeWithContent = ["MEDIA", "LIVE", "PROGRAM", "PREMIUM", "PREMIUM_PRODUCT", "EXPLORER", "MOSAIC"] as const;
export const PageTypeWithContent = z.enum(pageTypeWithContent);
export type PageTypeWithContent = z.infer<typeof PageTypeWithContent>;

export const PageType = z.enum([...pageTypeWithTitle, ...pageTypeWithContent]);

export const GenericPageContent = z
  .object({
    pageType: PageTypeWithTitle,
    title: z.string(),
    filters: Filters.optional(),
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }));

export type GenericPageContent = z.infer<typeof GenericPageContent>;

export const PageProductContent = z
  .object({
    pageType: z.literal("PREMIUM"),
    title: z.string(),
    subtitle: z.string().optional(),
    description: z.string().optional(),
    background: Image,
    cta: Cta,
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }));

export type PageProductContent = z.infer<typeof PageProductContent>;

export const PageMosaicContent = z
  .object({
    pageType: z.literal("MOSAIC"),
    title: z.string(),
    filters: Filters.optional(),
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }));

export type PageMosaicContent = z.infer<typeof PageMosaicContent>;
export function pageMosaicContentHasFilters(content: unknown): FilterTypes {
  const mosaic = PageMosaicContent.safeParse(content);
  if (mosaic.success) {
    if (mosaic.data.filters?.categories !== undefined && mosaic.data.filters?.periods !== undefined) {
      return "both";
    }
    if (mosaic.data.filters?.categories !== undefined) {
      return "categories";
    }
  }
  return false;
}

export const PageExplorerContent = z
  .object({
    pageType: z.literal("EXPLORER"),
    title: z.string(),
    searchForm: WidgetSearch,
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }));

export type PageExplorerContent = z.infer<typeof PageExplorerContent>;

export const PageProductOfferingContent = z
  .object({
    pageType: z.literal("PREMIUM_PRODUCT"),
    title: z.string(),
    subtitle: z.string(),
    description: z.string(),
    promotionLabel: z.string(),
    promotionShortLabel: z.string(),
    salesIncentiveLabel: z.string(),
    salesIncentiveAltLabel: z.string(),
    durationLabel: z.string(),
    background: Image,
    logo: Image,
    productOffering: ProductOffering,
    widgetFilter: WidgetFilter.optional(),
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }));

export type PageProductOfferingContent = z.infer<typeof PageProductOfferingContent>;

export const PageMediaContent = z.object({
  id: z.string(),
  assetId: z.string().nullable(),
  pageType: z.literal("MEDIA"),
  type: MediaType,
  title: z.string(),
  subtitle: z.string(),
  description: z.string(),
  shortDescription: z.string(),
  isPremium: z.boolean(),
  publishedFrom: z.string().refine(isISODate, { message: "Not a valid ISO string date " }),
  publishedTo: z.string().refine(isISODate, { message: "Not a valid ISO string date " }),
  duration: z.number(),
  rating: Rating.nullish().transform(arg => arg ?? undefined),
  hasAudioDescriptions: z.boolean(),
  hasMultilingualVersions: z.boolean(),
  hasSubtitles: z.boolean(),
  background: Image.nullable(),
  logo: Logo.nullable(),
  casting: z.array(Casting).nullable(),
  tags: z.array(Tag).nullable(),
  program: ProgramCard.nullable(),
  category: CategoryCard.nullable(),
  channel: ChannelCard.nullable(),
  products: z.array(ProductCard),
});

export type PageMediaContent = z.infer<typeof PageMediaContent>;

export const PageLiveContent = z.object({
  id: z.string(),
  assetId: z.string().nullable(),
  pageType: z.literal("LIVE"),
  type: MediaType,
  title: z.string(),
  subtitle: z.string(),
  description: z.string(),
  shortDescription: z.string(),
  scheduledFrom: z.string().refine(isISODate, { message: "Not a valid ISO string date " }).or(z.literal("")),
  scheduledTo: z.string().refine(isISODate, { message: "Not a valid ISO string date " }).or(z.literal("")),
  duration: z.number(),
  rating: Rating.nullish().transform(arg => arg ?? undefined),
  hasAudioDescriptions: z.boolean(),
  hasMultilingualVersions: z.boolean(),
  hasSubtitles: z.boolean(),
  background: Image,
  logo: Logo.nullable(),
  casting: z.array(Casting).nullable(),
  tags: z.array(Tag).nullable(),
  program: ProgramCard.nullable(),
  category: CategoryCard.nullable(),
  channel: ChannelCard.nullable(),
  isAdReplacement: z.boolean().nullish(),
});

export type PageLiveContent = z.infer<typeof PageLiveContent>;

export const PageProgramContent = z.object({
  id: z.string(),
  pageType: z.literal("PROGRAM"),
  type: z.string(),
  title: z.string(),
  description: z.string(),
  videoCount: z.number().nullable(),
  seasonCount: z.number().nullable(),
  background: Image.nullable(),
  logo: Logo.nullable(),
  logoPartner: Logo.nullable(),
  additionalLinks: z.array(AdditionalLink).nullable().optional(),
  channel: ChannelCard.nullable(),
  category: CategoryCard.nullable(),
  media: z.union([MediaCard, MediaPremiumCard]).nullable(),
  live: TvLiveCard.nullish(),
});

export type PageProgramContent = z.infer<typeof PageProgramContent>;

export const PageContent = z.union([
  PageProductContent,
  PageExplorerContent,
  PageMosaicContent,
  PageProductOfferingContent,
  PageMediaContent,
  PageLiveContent,
  PageProgramContent,
  GenericPageContent,
]);

export type PageContent = z.infer<typeof PageContent>;
