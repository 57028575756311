import "./bingeView.scss";

import { DS } from "dslib-tv";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";

import { Config } from "../../../config";
import { navigationStack } from "../../../main";
import { DevicePreferenceHelper } from "../../../tools/devicePreferencesManager";
import { IPlayer, playerAudio, playerVideo } from "../../../tools/player";
import { MediaCard, MediaType, TvLiveCard } from "../../../utils/rtbf/models";
import { FocusTracker } from "../../focusTracker";

enum PlayerBingeComponent {
  TILE = "TILE",
  BUTTON = "BUTTON",
}

class TileBingeView extends AcceptsMouseFocusView {
  nextButtonOverlay: HTMLDivElement;

  constructor(media: MediaCard | TvLiveCard, player: IPlayer) {
    super(media.id, "tileBingeView");
    let episodePlay, barPlay;
    if (media.resourceType === "MEDIA") {
      episodePlay = require("@assets/images/icons/nextEpisodePlay.png");
      barPlay = require("@assets/images/icons/nextEpisodeBar.png");
    } else if (media.resourceType === "LIVE") {
      episodePlay = require("@assets/images/icons/nextEpisodePlayLive.png");
      barPlay = require("@assets/images/icons/nextEpisodeBarLive.png");
    }
    const cardContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "cardCoverContainer",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileCover",
      style: {
        backgroundImage: `url(${media.illustration?.xl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileTitle",
      innerText: media.title,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileSubtitle",
      innerText: media.subtitle,
    });
    const nextButton = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "nextButton",
    });
    this.nextButtonOverlay = DS.DOMHelper.createElement({
      tagName: "div",
      parent: nextButton,
      className: "nextButtonOverlay",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: nextButton,
      className: `nextButtonIcon play ${player.asset$.value?.type === "LIVE" && "live"}`,
      style: {
        backgroundImage: `url(${episodePlay})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: nextButton,
      className: `nextButtonIcon bar ${player.asset$.value?.type === "LIVE" && "live"}`,
      style: {
        backgroundImage: `url(${barPlay})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    const nextButtonTitle = DS.DOMHelper.createElement({
      tagName: "div",
      parent: nextButton,
      className: `nextButtonTitle ${player.asset$.value?.type === "LIVE" && "live"}`,
      innerText: t("bingeWatching.nextEpisode"),
    });
    if (media.resourceType === "LIVE") {
      nextButtonTitle.style.color = "#fff";
    }
  }
}

class ButtonBinge extends AcceptsMouseFocusView {
  constructor() {
    super("buttonBinge", "buttonBinge");
    const cardContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileTitle",
      innerText: t("bingeWatching.notNow"),
    });
  }
}

export class BingeView extends AcceptsMouseFocusView {
  private _focusTracker?: FocusTracker;
  private _media: MediaCard | TvLiveCard | undefined;
  private _nextContentTileView: TileBingeView | undefined;
  private _waitTime = 10;
  private _player: IPlayer;
  private _isInAutoBinge = false;

  constructor(mediaType: MediaType) {
    super("bingeView", "bingeView");

    if (mediaType === MediaType.enum.VIDEO) {
      this._player = playerVideo;
    } else {
      this._player = playerAudio;
    }

    this._media = this._player.asset$.value?.binge ?? undefined;
    if (this._media) this._nextContentTileView = new TileBingeView(this._media, this._player);

    // Ratio from video to determinate if we launch automatic or not
    const preferences = DevicePreferenceHelper.cachedPreferences();

    // Binge Watching title part
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "toBeContinuedText",
      innerText: t("bingeWatching.toBeContinued"),
    });
    this.delegate = DS.createListComponent(
      {
        id: "",
        className: "bingeList",
        modelSource$: new DS.ModelSource([PlayerBingeComponent.TILE, PlayerBingeComponent.BUTTON]),
        viewFactory: item => {
          switch (item) {
            case PlayerBingeComponent.TILE:
              return this._nextContentTileView ?? new DS.View("");
            case PlayerBingeComponent.BUTTON:
              return new ButtonBinge();
          }
        },
        crossSectionWidth: 1,
        scrollingMode: { type: DS.ScrollingType.none, horizontal: false },
        scrollDuration: Config.scrollDuration,
        spatialFocus: true,
        mouseSupport: Config.mouseSupport && { focusRange: "visible" },
        onSelect: card => {
          switch (card) {
            case PlayerBingeComponent.TILE:
              // TODO: push the playerPage with new assetID
              void this._loadNextAsset(false);
              break;
            case PlayerBingeComponent.BUTTON:
              if (this._player.percentRatio$.value === 100) {
                navigationStack.removePage(navigationStack.topPage);
                void this._player.destroy();
              } else {
                this._player.showControls$.value = true;
              }
              break;
          }
          return true;
        },
      },
      list => {
        if (!this._focusTracker) {
          let className = "focusRect ";
          playerVideo.isLive() ? (className += "live") : (className += "media");
          this._focusTracker = new FocusTracker(list, className, "cardContainer");
          this._focusTracker.rootElement.style.opacity = "1";
        }

        this.collectRelease(this._focusTracker.onRelease);
      }
    );

    if (preferences.bingeEnable) {
      /* Binge view is always created when the pop-up is showing on the screen, so if we have pressed no to the two first pop-ups (at 95% and 99%),
        then when the binge is creted again at the end of the show - to go back. If the second pop-up hasn't been dismissed
        then this code won't be executed and the new episode will start */
      if (this._player.percentRatio$.value === 100) {
        navigationStack.removePage(navigationStack.topPage);
        window.setTimeout(() => {
          void this._player.destroy();
        }, 1);
      } else {
        this._player.currentTime$.didChange(value => {
          if (this._player.duration$.value - value <= 1) {
            void this._loadNextAsset();
          }
          if (this._player.duration$.value - value <= this._waitTime) {
            this._triggerProgressBar();
          }
        }, this);
      }
    }
  }

  private async _loadNextAsset(auto = true) {
    this._player.trackAnalytics(`binge_watching_next_${auto ? "auto" : "manual"}`, {
      boolean_value: true,
    });
    if (this._media) {
      await this._player.load(this._media.id, this._media.resourceType, { collector: this });
      this._player.play(true);
      this._player.showControls$.value = true;
    }
  }

  onRelease() {
    super.onRelease();
  }

  private _triggerProgressBar() {
    if (this._nextContentTileView === undefined || this._isInAutoBinge === true) return;
    this._isInAutoBinge = true;
    const buttonOverlay = this._nextContentTileView.nextButtonOverlay;
    buttonOverlay.classList.add(this._player.isLive() ? "live" : "media");
    buttonOverlay.classList.add("selected");
    buttonOverlay.style.transition = `width ${this._waitTime}s ease-in-out`;
  }
}
