import { CallFlow } from "~/callflow";
import { DS } from "~/libs";
import { IOptions } from "~/libs/ui/httpRequest";
import { LogConsentHelper } from "~/tools/logConsentHelper";
import { isNumeric } from "~/tools/snippets/isNumeric";
import { objectToQueryString } from "~/tools/snippets/objectToQueryString";
import { ApiErrorResponse, ApiResponse } from "~/utils/api";
import {
  GetGigyaConsentInfos,
  GigyaAccountConsentList,
  GigyaDeviceAuthorizationResponse as DeviceAuthorizationResponse,
  GigyaDeviceAuthorizationResponse,
  GigyaRevokeResponse,
  GigyaTokenPollingError as GigyaTokenError,
  GigyaTokenPollingErrorResponse,
  GigyaTokens,
  GigyaTokensResponse,
  GigyaUserInfo,
  GigyaUserInfoRawPref,
  SiteConsentDetails,
} from "~/utils/gigya/models";
import { parseJwt } from "~/utils/parseJWT";

import { Config } from "../consts";

const basePairingUrl = `https://${Config().GIGYA.pairingUrl}/oidc/op/v1.0/${Config().GIGYA.apiKey}`;
const scope = ["uid", "profile", "address", "phone", "email", "data", "preferences"];
const grantTypes = ["urn", "ietf", "params", "oauth", "grant-type", "device_code"];

export type GigyaRequestType = "userinfo" | "revoke";

class _APIGigyaOIDC {
  private _localStorageTokens = "gigyaTokens";
  private _tokens: GigyaTokens | undefined = undefined;
  private _userInfo$ = new DS.Listenable<GigyaUserInfo | undefined>(undefined);
  private _deviceAuthorization$ = new DS.Listenable<GigyaDeviceAuthorizationResponse | undefined>(undefined);

  constructor() {
    this._tokens = this._loadFromLocalStorage();
  }

  private async _apiWrapper(url: string, type: GigyaRequestType): Promise<ApiResponse> {
    const { res, error }: { res: unknown; error: unknown } = await (async () => {
      try {
        return {
          res: await DS.HttpRequest.make(url, await APIGigyaOIDC.requestOptions(type)),
          error: undefined,
        };
      } catch (error) {
        return {
          error,
          res: ApiErrorResponse.passthrough().parse(error),
        };
      }
    })();

    const response = res !== undefined ? ApiResponse.passthrough().parse(res) : undefined;
    if (response?.status === 401) {
      await CallFlow.logout();
    }

    if (response?.status === 200) {
      return response;
    }

    Log.api.error("Gigya OIDC API error", res, JSON.stringify(res));
    throw error instanceof Error ? error : new Error("Gigya OIDC API couldn't be made");
  }

  endDeviceAuthorization() {
    this._deviceAuthorization$.value = undefined;
  }

  /*
   * Return Gigya id_token, and renew it if necessary
   * @throws An error if the token renewal failed;
   */
  async idToken(): Promise<string | undefined> {
    if (this._tokens?.id_token === undefined) return;

    if (this.shouldRenewGigyaTokens(this._tokens.id_token)) {
      await this._renewGigyaTokens(this._tokens.refresh_token);
    }
    return this._tokens.id_token;
  }

  /*
   * Return Gigya access_token, and renew it if necessary
   * @throws An error if the token renewal failed;
   */
  async accessToken(): Promise<string | undefined> {
    if (this._tokens?.access_token === undefined) return;

    if (this.shouldRenewGigyaTokens(this._tokens.id_token)) {
      await this._renewGigyaTokens(this._tokens.refresh_token);
    }

    return this._tokens.access_token;
  }

  private async _renewGigyaTokens(refreshToken: string) {
    const tokens = await APIGigyaOIDC.renewTokenRequest(refreshToken);
    if (tokens !== undefined && "id_token" in tokens) {
      APIGigyaOIDC.saveTokens(tokens);
    } else {
      void CallFlow.logout();
    }
  }

  /*
   * Return Gigya refresh_token
   */
  refreshToken(): string {
    if (this._tokens?.refresh_token === undefined) {
      throw new Error("Gigya refresh_token is undefined");
    }
    return this._tokens.refresh_token;
  }

  saveTokens(tokens: GigyaTokens | undefined) {
    if (tokens === undefined) {
      this.clear();
    } else {
      this._tokens = tokens;
      DS.Storage.setItem(this._localStorageTokens, JSON.stringify(tokens));
    }
  }

  private _loadFromLocalStorage(): GigyaTokens | undefined {
    const localStorage = DS.Storage.getItem(this._localStorageTokens);
    if (localStorage === null || localStorage === "") return undefined;

    const parsed = GigyaTokens.safeParse(JSON.parse(localStorage));
    return parsed.success === true ? parsed.data : undefined;
  }

  clear() {
    this._userInfo$.value = undefined;
    this._tokens = undefined;
    DS.Storage.removeItem(this._localStorageTokens);
  }

  /**
   * Parse id token, parse it and check if it is expired
   * @param idToken The Gigya id token
   */
  shouldRenewGigyaTokens(idToken: string): boolean {
    const { exp } = parseJwt(idToken);
    if (exp !== "" && isNumeric(exp) === true) {
      // Multiply by 1000 because exp field is in seconds since epoch, not milliseconds like in javascript
      return new Date() > new Date(exp * 1000);
    }
    return true;
  }

  /**
   * Request a pairing authentification
   * @throws Parsing error
   * @throws Fetch error
   */
  deviceAuthorization = async (): Promise<void> => {
    const request = await DS.HttpRequest.make(
      `${basePairingUrl}/device_authorization?client_id=${Config().GIGYA.clientId}&scope=${scope.join("%20")}`,
      {
        method: "post",
      }
    );

    this._deviceAuthorization$.value = DeviceAuthorizationResponse.parse(request.json);
  };

  /**
   * Request to query tokens after a device authorization request
   * @throws Parsing error
   * @throws Fetch error
   */
  tokenRequest = async (deviceCode: string): Promise<GigyaTokens | GigyaTokenError | undefined> => {
    try {
      const request = await DS.HttpRequest.make(
        `${basePairingUrl}/token?client_id=${
          Config().GIGYA.clientId
        }&device_code=${deviceCode}&grant_type=${grantTypes.join("%3A")}`,
        {
          method: "post",
        }
      );
      const parsed = GigyaTokensResponse.parse(request);
      return parsed.json;
    } catch (error) {
      const parsedError = GigyaTokenPollingErrorResponse.parse(error);
      return parsedError.json;
    }
  };

  /**
   * Request to renew tokens
   * @throws Parsing error
   * @throws Fetch error
   */
  renewTokenRequest = async (refreshToken: string): Promise<GigyaTokens | GigyaTokenError | undefined> => {
    try {
      const parameters = {
        client_id: Config().GIGYA.clientId,
        client_secret: Config().GIGYA.clientSecret,
        scope: scope.join("%20"),
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      };
      const request = await DS.HttpRequest.make(`${basePairingUrl}/token?${objectToQueryString(parameters)}`, {
        method: "post",
      });
      Log.api.info("Gigya API - refreshToken", JSON.stringify(parameters), JSON.stringify(request));
      const parsed = GigyaTokensResponse.parse(request);
      return parsed.json;
    } catch (error) {
      const parsedError = GigyaTokenPollingErrorResponse.parse(error);
      return parsedError.json;
    }
  };

  async requestOptions(type: GigyaRequestType): Promise<IOptions> {
    switch (type) {
      case "userinfo":
        return {
          method: "get",
          headers: { Authorization: `Bearer ${await this.accessToken()}` },
        };
      case "revoke":
        return {
          method: "post",
          // btoa is used to convert the string to base64
          headers: {
            Authorization: `Basic ${window.btoa(`${Config().GIGYA.clientId}:${Config().GIGYA.clientSecret}`)}`,
          },
        };
    }
  }

  /**
   * Request user infos
   * @throws Parsing error
   * @throws Fetch error
   */
  updateUserInfo = async (): Promise<undefined> => {
    if ((await this.accessToken()) === undefined) return;

    const request = await this._apiWrapper(`${basePairingUrl}/userinfo`, "userinfo");

    // Parse in two times, first to access preferences key update structure, and then merge the result to userInfos and parse it again
    const preferences: GigyaAccountConsentList = {};
    const rawUserInfo = GigyaUserInfoRawPref.parse(request.json);
    try {
      LogConsentHelper.keyCollapser(rawUserInfo.preferences, "", preferences);
      rawUserInfo.preferences = preferences;
    } catch (error) {
      Log.api.error("Failed to parse user consents", rawUserInfo.preferences);
      rawUserInfo.preferences = {};
    }

    this._userInfo$.value = GigyaUserInfo.parse(rawUserInfo);
  };

  /**
   * Request to query GIGYA site consent details
   * @throws Parsing error
   * @throws Fetch error
   */
  siteConsentsRequest = async (): Promise<SiteConsentDetails> => {
    const request = await DS.HttpRequest.make(
      `https://accounts.${Config().GIGYA.dataCenter}/accounts.getSiteConsentDetails?APIKey=${Config().GIGYA.apiKey}`,
      {
        method: "post",
      }
    );

    const consentInfos = GetGigyaConsentInfos.parse(request.json);
    if (consentInfos.errorCode !== 0) {
      throw new Error("Gigya getConsentDetails error : " + consentInfos.errorCode);
    }

    const consentDetailsList = consentInfos.siteConsentDetails;
    // hardcoded RTBF blacklist of wrong contract (requested by RTBF)
    const blackListedTerms = [
      "consentToDeletion",
      "terms.testTerms.siteTerms",
      "terms.testUsingVersioningByNumber",
      "terms.testUsingVersioningByNumber.siteTerms",
      "otherTerms",
    ];

    for (const [key] of Object.entries(consentDetailsList)) {
      if (blackListedTerms.includes(key)) {
        delete consentDetailsList[key];
      }
    }

    return consentDetailsList;
  };

  /**
   * Revoke request
   * @throws Parsing error
   * @throws Fetch error
   */
  revokeRequest = async (): Promise<boolean> => {
    if (this._tokens?.access_token === undefined) return false;

    const request = await this._apiWrapper(`${basePairingUrl}/revoke?token=${this._tokens?.access_token}`, "revoke");

    const res = GigyaRevokeResponse.safeParse(request.json);
    if (res.success === false) return false;
    return res.data.statusCode === 200 && res.data.statusReason === "OK";
  };

  /**
   * Define a readonly getter to avoid modfying user infos from somewhere else
   */
  public get userInfo$(): DS.IListenable<GigyaUserInfo | undefined> {
    return this._userInfo$;
  }

  public get deviceAuthorization$(): DS.IListenable<GigyaDeviceAuthorizationResponse | undefined> {
    return this._deviceAuthorization$;
  }

  isConnected() {
    return this._userInfo$.value !== undefined;
  }
}

export const APIGigyaOIDC = new _APIGigyaOIDC();
