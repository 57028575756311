import { DS } from "~/libs";

import { SearchKeyboardView } from "./searchKeyboardView";

export enum KeyboardType {
  Lowercase = "Lowercase",
  Digits = "Digits",
  Uppercase = "Uppercase",
  Specific = "Specific",
  Accents = "Accents",
}

export enum KeyboardSpecialKey {
  Specific = "Specific",
  Lowercase = "Lowercase",
  Uppercase = "Uppercase",
  Delete = "Delete",
  NoneButton = "noneButton", // used to display empty button (rejectFocus)
  NoneLetter = "noneLetter", // used to display empty letters when a letters line is not full (rejectFocus)
  dotBE = "dotBE",
  dotCOM = "dotCOM",
  Space = "Space",
  Digits = "Digits",
  Accents = "Accents",
}

export type Keyboard = {
  id: KeyboardType;
};

enum TopLevelID {
  keyboardsSwitch = "keyboardsSwitch",
}

export class KeyboardView extends DS.View {
  textToSearch$: DS.Listenable<string>;
  protected _keyboardSwitch: DS.ISwitchComponent<Keyboard, SearchKeyboardView>;

  constructor(
    classname: string,
    textToSearch$: DS.Listenable<string>,
    keyboardSwitch: DS.ISwitchComponent<Keyboard, SearchKeyboardView>
  ) {
    super("keyboardComponent", `${classname}KeyboardComponent`);
    this.textToSearch$ = textToSearch$;
    this._keyboardSwitch = keyboardSwitch;

    this.delegate = DS.createListComponent({
      id: "",
      className: `${classname}KeyboardList`,
      modelSource$: new DS.ModelSource([TopLevelID.keyboardsSwitch]),
      viewFactory: _ => keyboardSwitch,
      scrollingMode: {
        type: DS.ScrollingType.page,
        horizontal: false,
      },
    });
  }
}
