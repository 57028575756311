import "./consentPopupPage.scss";

import { FocusTracker } from "~/components/focusTracker";
import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { Config } from "~/config";
import { DS } from "~/libs";
import { exitApp, navigationStack } from "~/main";
import { ConsentHelper } from "~/tools/consentHelper";
import { DevicePreferenceHelper } from "~/tools/devicePreferencesManager";

import { PreferencePageFull } from "../preference/preferencePage";

enum ButtonType {
  "refuseAll" = "refuseAll",
  "acceptAll" = "acceptAll",
  "configure" = "configure",
}

export class ConsentPopupPageView extends DS.View implements DS.IPage {
  private _focusTracker?: FocusTracker;

  constructor() {
    super("", "consentPopupPageView");

    DevicePreferenceHelper.presentDidomi();

    const consentContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "consentContainer",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: consentContainer,
      className: "consentLogo",
    });
    const titleContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: consentContainer,
      className: "consentTitle",
    });
    DS.DOMHelper.createElement({
      tagName: "span",
      parent: titleContainer,
      className: "consentTitleText",
      innerText: t("consent.pageTitle"),
    });

    const contentDescriptionContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: consentContainer,
      className: "consentDescriptionContainer",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: contentDescriptionContainer,
      className: "consentDescription",
      innerText: t("consent.pageDescription_1"),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: contentDescriptionContainer,
      className: "consentDescription",
      innerText: t("consent.pageDescription_2"),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: contentDescriptionContainer,
      className: "consentDescription italic",
      innerText: t("consent.pageDescription_3"),
    });

    this.delegate = DS.createListComponent(
      {
        id: "consentButtonlist",
        className: "consentButtonWidget",
        modelSource$: new DS.ModelSource([ButtonType.configure, ButtonType.refuseAll, ButtonType.acceptAll]),
        viewFactory: item => new ConnectionButton(item),
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
        onSelect: type => {
          switch (type) {
            case ButtonType.configure:
              navigationStack.pushPage(new PreferencePageFull());
              break;
            case ButtonType.acceptAll:
              void ConsentHelper.validateAll();
              break;
            case ButtonType.refuseAll:
              void ConsentHelper.rejectAll();
              break;
          }

          return true;
        },
      },
      list => {
        void list.setFocusOnIndex(0);
        this._focusTracker = new FocusTracker(list, "focusRect", "actionButtonContainer");
        this.collectRelease(this._focusTracker.onRelease);
      }
    );
  }
  onNav(key: DS.Keys): boolean {
    switch (key) {
      case DS.Keys.back:
        exitApp();
        return true;
    }
    return false;
  }
}

export class ConnectionButton extends AcceptsMouseFocusView {
  constructor(buttonType: string) {
    super(buttonType + "Action", "actionButtonContainer");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "actionButton",
      innerText: t("connection.button." + buttonType + "Button"),
    });
  }
}
