import "./offrePremiumPage.scss";
import "~/pages/settings/settingsPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";

import { RootMenuPage } from "../../../rootMenu/rootMenuPage";

export class OffrePremiumPageView extends AcceptsMouseFocusView implements DS.IPage {
  constructor() {
    super("offrePremiumPageView", "offrePremiumPageView settingsPages leftLogo");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageLeftIcon",
      style: {
        backgroundImage: `url(${require("@assets/images/offrePremium/offrePremium_logo.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageTitle",
      innerText: t("offrePremium.offrePremium_title"),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageSubtitle",
      innerText: t("offrePremium.offrePremium_subtitle"),
    });

    const offrePremiumPageDesc = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageDesc",
    });
    DS.DOMHelper.createElement({
      tagName: "span",
      parent: offrePremiumPageDesc,
      className: "pageSubtitle",
      innerText: t("offrePremium.offrePremium_desc"),
    });
    DS.DOMHelper.createElement({
      tagName: "span",
      parent: offrePremiumPageDesc,
      className: "pageDescription",
      innerText: t("offrePremium.offrePremium_text"),
    });
    DS.DOMHelper.createElement({
      tagName: "span",
      parent: offrePremiumPageDesc,
      className: "pageText1",
      innerText: t("offrePremium.offrePremium_desc1"),
    });
    DS.DOMHelper.createElement({
      tagName: "span",
      parent: offrePremiumPageDesc,
      className: "pageWebsite",
      innerText: t("offrePremium.offrePremium_website"),
    });
    DS.DOMHelper.createElement({
      tagName: "span",
      parent: offrePremiumPageDesc,
      className: "pageText1",
      innerText: t("offrePremium.offrePremium_desc2"),
    });

    // TODO: A réactiver plus tard ?
    // this.rootElement.appendChild(createQRCode(t("offrePremium.offrePremium_website"), "settingsQRCode"));
  }
}

export class OffrePremiumPage extends RootMenuPage implements DS.IPage {
  constructor() {
    super("OffrePremiumPage", "RootMenuPage", () => {
      return new OffrePremiumPageView();
    });
  }
}
