export const areArraysEqual = (a: unknown[], b: unknown[]) => {
  /*
      Array-aware equality checker:
      Returns whether arguments a and b are == to each other;
      however if they are equal-lengthed arrays, returns whether their 
      elements are pairwise == to each other recursively under this
      definition.
  */
  if (a instanceof Array && b instanceof Array) {
    if (a.length !== b.length)
      // assert same length
      return false;
    for (
      let i = 0;
      i < a.length;
      i++ // assert each element equal
    )
      // eslint-disable-next-line unused-imports/no-unused-vars
      if (!areArraysEqual(a[i], b[i])) return false;
    return true;
  } else {
    return a === b; // if not both arrays, should be the same
  }
};

export const deepCloneArray = (array: unknown[]) => {
  return JSON.parse(JSON.stringify(array));
};
