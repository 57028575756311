import "./consentListItemView.scss";

import { PartnersCheckBoxView, PreferenceCheckBoxView } from "~/components/views/checkboxView/checkboxView";
import { Config } from "~/config";
import { DS } from "~/libs";
import { ConsentHelper } from "~/tools/consentHelper";
import { PartnerType, PreferenceType } from "~/utils/rtbf/models";

import { AcceptsMouseFocusView } from "../../common/mouseSupport/acceptsMouseFocusView";
import { ConsentListType } from "../list/consentListView";
import { ConsentListItemOpenButton } from "../listItemOpenButton/consentListItemOpenButtonView";

enum ConsentListPreferenceType {
  open = "open",
  checkbox = "checkbox",
}

export class ConsentListItemView extends AcceptsMouseFocusView {
  private _partnerCheckboxView;
  private _list:
    | DS.IListComponent<ConsentListPreferenceType, ConsentListItemOpenButton | PreferenceCheckBoxView>
    | undefined;
  private _expandCallback;
  isOpen = false;

  constructor(item: PreferenceType | PartnerType, type: ConsentListType, expandCallback?: () => void) {
    super("", `consentListItemView ${type}`);

    this._expandCallback = expandCallback;

    // Good way to check if it is a partner ?
    if ("sdk_id" in item) {
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "buttonTitle",
        innerText: item.name,
      });

      this._partnerCheckboxView = new PartnersCheckBoxView(ConsentHelper.getPartnerStatus(item.id), item.id);
      this.rootElement.appendChild(this._partnerCheckboxView.rootElement);
    } else {
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "buttonTitle",
        innerText: t("consent_preferences." + item.id + ".title"),
      });

      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "buttonDescription",
        innerText: t("consent_preferences." + item.id + ".subtitle"),
      });

      this.delegate = this._list = DS.createListComponent({
        id: "",
        className: "preferenceButtons",
        modelSource$: new DS.ModelSource([ConsentListPreferenceType.open, ConsentListPreferenceType.checkbox]),
        mouseSupport: Config.mouseSupport,
        viewFactory: model => {
          switch (model) {
            case ConsentListPreferenceType.open:
              return new ConsentListItemOpenButton();
            case ConsentListPreferenceType.checkbox:
              return new PreferenceCheckBoxView(ConsentHelper.getPurposeStatus(item.id), item.id);
          }
        },
        scrollingMode: {
          type: DS.ScrollingType.page,
          horizontal: true,
        },
        onSelect: btn => {
          if (btn === ConsentListPreferenceType.open) {
            if (this._expandCallback !== undefined) {
              this._expandCallback();
            }
          } else {
            const preferenceCheckboxView = this._list?.viewFromId(btn);
            if (preferenceCheckboxView instanceof PreferenceCheckBoxView) {
              preferenceCheckboxView.onSelect();
            }
          }
          return true;
        },
      });
    }
  }

  /*
   * Used to trigger a select on a partner consent item view, which is a view and not a list like a preference consent
   */
  onSelect() {
    if (this._partnerCheckboxView !== undefined) {
      this._partnerCheckboxView.onSelect();
    }
  }

  expand() {
    this.rootElement.classList.add("expand");
    this.isOpen = true;
  }

  close() {
    this.rootElement.classList.remove("expand");
    this.isOpen = false;
  }
}
