import "./settingsSelectionButtonView.scss";

import { DS } from "dslib-tv";

import { LanguageSelectionButtons } from "../../../../pages/settings/languageSelection/languageSelectionPage";
import { PersonnalizeSubitleButtons } from "../../../../pages/settings/personnalizeSubtitles/personnalizeSubtitlePage";
import { DevicePreferenceHelper } from "../../../../tools/devicePreferencesManager";
import { AcceptsMouseFocusView } from "../../common/mouseSupport/acceptsMouseFocusView";

export type SettingsButtonType = {
  id: LanguageSelectionButtons;
  code: string;
};

class SelectedIconView extends DS.View {
  selectionIcon: HTMLDivElement;
  constructor(parent: HTMLElement, hide = false) {
    super("selectedIconView", "selectedIconView");
    this.selectionIcon = DS.DOMHelper.createElement({
      tagName: "div",
      parent: parent,
      className: `selectedIcon ${hide && "hidden"}`,
      style: {
        backgroundImage: `url(${require("@assets/images/icons/selected.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
  }

  /**
   * hide the tick icon
   */
  hide() {
    this.selectionIcon.classList.add("hidden");
  }

  /**
   * show the tick icon
   */
  show() {
    this.selectionIcon.classList.remove("hidden");
  }
}

export class SettingsSelectionButtonView extends AcceptsMouseFocusView {
  id: LanguageSelectionButtons;
  type: "audio" | "subtitle";
  constructor(id: LanguageSelectionButtons, type: "audio" | "subtitle") {
    super("settingsButtonView", "settingsButtonView");
    this.id = id;
    this.type = type;

    const container = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
    });
    const textContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: container,
      className: "textContainer",
    });
    textContainer.innerText = t("languageSubtitleSelection." + id);

    if (id.toString() === DevicePreferenceHelper.cachedPreferences()[this.type]) {
      new SelectedIconView(this.rootElement);
    }
  }
}

export class SubtitlePersonnalizationButtonView extends AcceptsMouseFocusView {
  id: PersonnalizeSubitleButtons;
  type: "position" | "size" | "style";
  selectedIcon: SelectedIconView;
  constructor(id: PersonnalizeSubitleButtons, type: "position" | "size" | "style") {
    super(id, `subtitlePersonnalizationButtonView ${type}`);
    this.id = id;
    this.type = type;

    const container = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
    });
    type === "style" && container.classList.add("style");
    const textContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: container,
      className: `textContainer ${id}`,
    });
    textContainer.innerText = t("subtitlePersonnalizationSelection." + id);
    this.selectedIcon = new SelectedIconView(this.rootElement, true);
  }

  removeSelection() {
    this.selectedIcon.hide();
  }

  select() {
    this.selectedIcon.show();
  }
}
