/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { z } from "zod";

const isISODate = require("is-iso-date");

const UserConsent = z.object({
  id: z.string(),
  organization_user_id: z.string().optional(),
  metadata: z.object({}).optional(),
  country: z.string().optional(),
  last_seen_country: z.string().optional(),
});

const ChannelConsent = z.object({
  id: z.string(),
  enabled: z.boolean().nullish(),
  metadata: z.object({}).optional(),
});

const ConsentValues = z.object({
  preferenceId1: z
    .object({
      value: z.string(),
    })
    .optional(),
  preferenceId2: z
    .object({
      value: z.string(),
    })
    .optional(),
});

const ConsentChoice = z.object({
  id: z.string(),
  enabled: z.boolean(),
  enabled_li: z.boolean().nullish(),
  metadata: z.object({}).optional(),
  values: ConsentValues.optional(),
});

export type Purposes = z.infer<typeof ConsentChoice>;

const Vendors = z.object({
  enabled: z.array(z.string()).nullish(),
  enabled_li: z.array(z.string()).nullish(),
  disabled: z.array(z.string()).nullish(),
  disabled_li: z.array(z.string()).nullish(),
});

export type Vendors = z.infer<typeof Vendors>;

const Consents = z.object({
  channels: z.array(ChannelConsent).optional(),
  purposes: z.array(ConsentChoice).optional(),
  vendors: Vendors,
  tcfcs: z.string().nullable().optional(),
});

export type Consents = z.infer<typeof Consents>;

const Delegate = z.object({
  id: z.string(),
  name: z.string().optional(),
  metadata: z.object({}).optional(),
});

export const ConsentEvent = z.object({
  id: z.string().optional(),
  organization_id: z.string(),
  created_at: z.string().refine(isISODate, { message: "Not a valid ISO string date " }).or(z.literal("")).optional(),
  status: z.string().optional(),
  user: UserConsent.optional(),
  consents: Consents,
  proofs_id: z.array(z.string()).optional(),
  delegate: Delegate.nullable().optional(),
});

export type ConsentEvent = z.infer<typeof ConsentEvent>;

export const ConsentEventResponse = z.object({ data: z.array(ConsentEvent) });
export type ConsentEventResponse = z.infer<typeof ConsentEventResponse>;

export const ConsentUserCreate = z.object({
  id: z.string(),
  organization_id: z.string(),
  organization_user_id: z.string().optional(),
  version: z.number().optional(),
  metadata: z.string().optional(),
  country: z.string().optional(),
  last_seen_country: z.string().optional(),
  consents: Consents.optional(),
});

export const PartnerType = z.object({
  id: z.string(),
  name: z.string(),
  sdk_id: z.string(),
});
export type PartnerType = z.infer<typeof PartnerType>;

export const PreferenceId = z.enum([
  "cookies",
  "create_ads_profile",
  "create_content_profile",
  "improve_products",
  "market_research",
  "measure_ad_performance",
  "measure_content_performance",
  "select_basic_ads",
  "select_personalized_ads",
  "select_personalized_content",
  "geolocation_data",
]);

export const PreferenceType = z.object({
  id: PreferenceId,
});
export type PreferenceType = z.infer<typeof PreferenceType>;

export type ConsentUserCreate = z.infer<typeof ConsentUserCreate>;

export const ConsentUserCreateResponse = z.array(ConsentUserCreate);
export type ConsentUserCreateResponse = z.infer<typeof ConsentUserCreateResponse>;

export const ConsentUser = z.object({
  id: z.string(),
  organization_id: z.string(),
  organization_user_id: z.string().nullable().optional(),
  version: z.number(),
  created_at: z.string().refine(isISODate, { message: "Not a valid ISO string date " }).or(z.literal("")),
  updated_at: z.string().refine(isISODate, { message: "Not a valid ISO string date " }).or(z.literal("")).nullable(),
  metadata: z.object({}).optional(),
  last_seen_country: z.string().nullable().optional(),
  consents: Consents.optional(),
});

export type ConsentUser = z.infer<typeof ConsentUser>;
