import { RedBeePlayer } from "@redbeemedia/javascript-player";

import { DS } from "~/libs";
import {
  IPlayerAsset,
  IPlayerLoadOptions,
  IPlayerQuality,
  IPlayerTrack,
  PlayerState,
  PlayerStreamType,
} from "~/tools/player";
import { EmbedType, TrackingData } from "~/utils/rtbf/models";

export interface IPlayerEventTime {
  currentTime: number;
  duration: number;
  seekable: {
    start: number;
    end: number;
  };
  utcCurrentTime: number;
  utcDuration: number;
  utcSeekable: { start: number; end: number };
}

// Coming from
// https://redbee.live/docs/Client%20SDKs/JavaScript/#playlist
export interface IAdsOptions {
  uid?: string;
  latitude?: string;
  longitude?: string;
  mute?: boolean;
  autoplay?: boolean;
  consent?: string;
  deviceMake?: string;
  deviceType?: any;
  ifa?: string;
  gdprOptin?: boolean;
  width?: number;
  height?: number;
  pageUrl?: string;
  domain?: string;

  /**
   * These custom parameters will be added to the querystring of the play request.
   * e.g. ?customParam1=value1&customParam2=value2 etc
   */
  [key: string]: any;
}

export type RedBeePlayerType = RedBeePlayer;
export type RedBeePlayerInstanceType = typeof RedBeePlayer;

/**
 * This is the player as seen when exported.
 */
export interface IPlayer {
  /**
   * @deprecated
   * This is the base object. It is here only if really needed.
   *
   * This is Deprecated because developpers should add
   * the function they want in `players.ts` and use it from the player object.
   *
   * Using this, it might break some logic of the player.
   */
  // Constants
  TIME_INCREMENT_FW: number;
  TIME_INCREMENT_RW: number;

  // All listenables
  currentTime$: DS.Listenable<number>;
  seekTime$: DS.Listenable<number | undefined>;
  duration$: DS.Listenable<number>;
  seekable$: DS.Listenable<{ start: number; end: number }>;
  state$: DS.Listenable<PlayerState>;
  asset$: DS.Listenable<IPlayerAsset | undefined>;
  type$: DS.Listenable<PlayerStreamType | undefined>;
  showControls$: DS.Listenable<boolean>;
  showSpinner$: DS.Listenable<boolean>;
  percentRatio$: DS.Listenable<number>;

  /**
   * Get if the player is muted or set the state.
   */
  muted: boolean;
  /**
   * Define if there is some autoplay on the player.
   */
  autoplay: boolean;
  /**
   * Get the actual audio track.
   */
  audioTrack: IPlayerTrack;
  /**
   * Get the actual audio track.
   */
  subtitleTrack: IPlayerTrack;
  /**
   * Set the quality of the player.
   */
  quality: IPlayerQuality;
  /**
   * Get if the player is audioOnly or set the state.
   */
  audioOnly: boolean;
  /**
   * Get the last known asset, even if the player is destroyed.
   * Use with caution.
   */
  lastKnownAsset: IPlayerAsset | undefined;
  /**
   * Check or set if the analytics are disabled.
   */
  analytics: boolean;
  /**
   * Attach the player to a div
   */
  attach: (containerElement: HTMLElement) => void;
  /**
   * Destroy the player
   * @returns True if correctly destroyed, false otherwise
   */
  destroy: () => Promise<boolean>;
  /**
   * Load an asset
   */
  load: (assetID: string, embedType: EmbedType, options?: IPlayerLoadOptions) => Promise<any>;
  /**
   * Play the content
   */
  play: (firstStart?: boolean, restart?: boolean) => void;
  /**
   * Pause the content
   */
  pause: () => void;
  /**
   * Check if the content is playing.
   */
  isPlaying: () => boolean;
  /**
   * Seek with an offset.
   * @param offset The offset
   * @param preview If the seek is a preview, meaning just update the progress bar.
   */
  seek: (offset: number, preview: boolean) => void;
  /**
   * Cancel a seek.
   */
  cancelSeek: () => void;
  /**
   * Forward the stream
   */
  forward: (preview?: boolean) => void;
  /**
   * Rewind the stream
   */
  rewind: (preview?: boolean) => void;
  /**
   * Get all audio tracks.
   */
  audioTracks: () => IPlayerTrack[];
  /**
   * Get all audio tracks.
   */
  subtitleTracks: () => IPlayerTrack[];
  /**
   * Get all quality levels. The first one is the auto quality level.
   */
  qualityLevels: () => IPlayerQuality[];
  /**
   * Check if the current stream is live.
   */
  isLive: () => boolean;
  /**
   * Check if the current stream is premium.
   */
  isPremium: () => boolean;
  /**
   * Go to the current live timestamp.
   */
  seekToLive: () => void;
  /**
   * Track analytics
   */
  trackAnalytics: (action: string, gtag?: Partial<TrackingData>) => void;

  applySubtitleStyle: () => void;
  positionSubtitles: (playerControls?: HTMLElement) => void;
}

export enum RedBeePlayerEvents {
  ALL = "*",
  PLAY = "player:play",
  PAUSE = "player:pause",
  STOP = "player:stopped",
  START = "player:start",
  RESUME = "player:resume",
  PLAYING = "player:playing",
  SEEKING = "player:seeking",
  SEEK_TIME_CHANGE = "player:seek_time_change",
  SEEKED = "player:seeked",
  TIME_UPDATE = "player:timeupdate",
  ENDED = "player:ended",
  VOLUME_CHANGE = "player:volumechange",
  ERROR = "player:error",
  LOADING = "player.loading",
  LOADED = "player:loaded",
  LOAD_START = "player:load_start",
  BUFFERING = "player:buffering",
  BUFFERED = "player:buffered",
  ID3 = "player:id3",
  BITRATE_CHANGED = "player:bitrate_changed",
  CDN_CHANGED = "player:cdn_changed",
  AUDIO_CHANGED = "player:audio_changed",
  SUBTITLE_CHANGED = "player:subtitle_changed",
  LICENSE_EXPIRED = "player:license_expired",
  DROPPED_FRAMES = "player:dropped_frames",
  DRM_UPDATE = "player:drm:update",
  STATE_CHANGED = "player:state_changed",
  PROGRAM_CHANGED = "player:program_changed",
  NOT_ENTITLED = "player:not_entitled",
  BLACKOUT = "player:blackout",
  EMPTY_SLOT = "player:empty_slot",
  CAST_START = "player:cast:start",
  CAST_STOP = "player:cast:stop",
  AIRPLAY_START = "player:airplay:start",
  AIRPLAY_STOP = "player:airplay:stop",
  AD_START = "player:ad:start",
  AD_COMPLETE = "player:ad:complete",
  ADBLOCK_START = "player:adblock:start",
  ADBLOCK_COMPLETE = "player:adblock:complete",
  INTRO_START = "player:intro:start",
  INTRO_END = "player:intro:end",
  CHAPTER_START = "player:chapter:start",
  CHAPTER_END = "player:chapter:end",
  MARKER = "player:marker",
  METADATA_EVENT = "player:metadata_event",
  SESSION_ACQUIRED = "player:session_acquired",
  PLAYER_SETUP_COMPLETED = "player:setup_completed",
  ENTITLEMENT_GRANTED = "player:entitlement_granted",
}
