/* eslint-disable @typescript-eslint/no-explicit-any */
import { z } from "zod";

import { CategoryCard, ChannelCard, TvLiveCard } from ".";
import { Image, PaginatedResponse, Response } from "./base";
import {
  CtaCard,
  EmptyCard,
  FavoriteCard,
  FavoriteCardWithResource,
  GuestCard,
  MediaCard,
  MediaPremiumCard,
  PlayHistoryCard,
  PlayHistoryCardWithMedia,
  PlayHistoryIdsCard,
  ProductCard,
  ProgramCard,
  PromoBoxCard,
  QuickLinkCard,
  RadioLiveCard,
  StateCard,
} from "./cards";

export const WidgetType = z.enum([
  "RADIO_LIVE",
  "BANNER",
  "MEDIA_LIST",
  "CATEGORY_LIST",
  "CHANNEL_LIST",
  "MEDIA_TRAILER",
  "PROGRAM_LIST",
  "MEDIA_PREMIUM_LIST",
  "PRODUCT_PREMIUM_LIST",
  "TAB_LIST",
  "PREMIUM",
  "PROMOBOX",
  "MOSAIC",
  "FAVORITE_LIST",
  "FAVORITE_PREMIUM_LIST",
  "FAVORITE_PROGRAM_LIST",
  "ONGOING_PLAY_HISTORY",
  "PLAY_HISTORY",
  "FAVORITE_LIST_PROGRAM",
  "QUICK_LINK",
  "HERO_LIST",
  "MEDIA_U2C",
]);

export type WidgetType = z.infer<typeof WidgetType>;

export const Widget = z
  .object({
    id: z.string().nullable().optional(),
    type: WidgetType,
    title: z.string(),
    subtitle: z
      .string()
      .nullable()
      .transform(arg => arg ?? undefined)
      .optional(),
    contentPath: z
      .string()
      .nullable()
      .transform(arg => arg ?? undefined)
      .optional(),
    content: z
      .array(z.unknown()) // !! Important: DON'T parse items of the array here, because the content is specialized depending on the WidgetType. So it is better to delegate the parsing to the "WidgetView"
      .nullable()
      .transform(arg => arg ?? undefined)
      .optional(),
    cta: CtaCard.optional()
      .nullable()
      .transform(arg => arg ?? undefined),
    states: StateCard.optional()
      .nullable()
      .transform(arg => arg ?? undefined),
    index: z.number().optional(),
  })
  .transform(({ id, ...input }) => ({ id: Math.random().toString(36).substring(2, 9), widgetId: id, ...input }));

export type Widget = z.infer<typeof Widget>;

export function isWidget(data: any): data is Widget {
  if ((data as Widget).contentPath !== undefined)
    return (data as Widget)?.type !== undefined && (data as Widget)?.contentPath !== undefined;
  else return (data as Widget)?.type !== undefined && (data as Widget)?.content !== undefined;
}

/**  RADIOLIVE */
export const WidgetRadioLive = z.object({
  id: z.string(),
  type: z.literal("RADIO_LIVE"),
  content: z.array(RadioLiveCard),
});
export type WidgetRadioLive = z.infer<typeof WidgetRadioLive>;

/** BANNER */
export const WidgetBanner = z.object({
  id: z.string(),
  type: z.literal("BANNER"),
  title: z.string(),
  description: z.string(),
  image: Image,
  deepLink: z
    .string()
    .nullable()
    .transform(arg => (arg != null ? arg : undefined)),
  externalLink: z
    .string()
    .nullish()
    .transform(arg => (arg != null ? arg : undefined)),
  textPosition: z.enum(["left", "right"]),
  theme: z.enum(["dark", "light"]),
  backgroundColor: z
    .string()
    .nullable()
    .transform(arg => (arg != null ? arg : undefined)),
});
export type WidgetBanner = z.infer<typeof WidgetBanner>;

/**  PROGRAM LIST */
export const WidgetProgramList = z.object({
  id: z.string(),
  type: z.literal("PROGRAM_LIST"),
  title: z.string(),
  content: z.array(ProgramCard),
});
export type WidgetProgramList = z.infer<typeof WidgetProgramList>;

export const WidgetMediaPremiumList = z.object({
  id: z.string(),
  type: z.literal("MEDIA_PREMIUM_LIST"),
  title: z.string(),
  content: z.array(MediaPremiumCard),
});
export type WidgetMediaPremiumList = z.infer<typeof WidgetMediaPremiumList>;

/** MEDIA LIST **/
export const WidgetMediaList = z.object({
  id: z.string(),
  type: z.literal("MEDIA_LIST"),
  content: z.array(z.union([MediaCard, TvLiveCard])),
});

export type WidgetMediaList = z.infer<typeof WidgetMediaList>;

export const WidgetMediaCardOnlyList = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal("MEDIA_LIST"),
    content: z.array(MediaCard),
  }),
});
export type WidgetMediaCardOnlyList = z.infer<typeof WidgetMediaCardOnlyList>;

/** MEDIA TRAILER **/
export const WidgetMediaTrailer = z.object({
  id: z.string(),
  type: z.literal("MEDIA_TRAILER"),
  // content: z.array(MediaItem),
  title: z.string(),
  subtitle: z.string(),
  path: z.string(),
  pathTitle: z.string(),
  mediaId: z.string(),
  mediaTitle: z.string(),
  mediaSubtitle: z.string(),
  programTitle: z.string(),
  programSubtitle: z.string(),
  programDescription: z.string(),
  videoCount: z.number(),
  channelLabel: z.string().optional(),
  illustration: Image.nullable(),
});
export type WidgetMediaTrailer = z.infer<typeof WidgetMediaTrailer>;

/** CATEGORY **/
export const WidgetCategoryList = z.object({
  id: z.string(),
  type: z.literal("CATEGORY_LIST"),
  content: z.array(CategoryCard),
});
export type WidgetCategoryList = z.infer<typeof WidgetCategoryList>;

/**  CHANNEL_LIST */
export const WidgetChannelList = z.object({
  id: z.string(),
  type: z.literal("CHANNEL_LIST"),
  content: z.array(ChannelCard),
});
export type WidgetChannelList = z.infer<typeof WidgetChannelList>;

/**  PRODUCT */
export const WidgetProductPremiumList = z.object({
  id: z.string(),
  type: z.literal("PRODUCT_PREMIUM_LIST"),
  title: z.string(),
  content: z.array(ProductCard),
});
export type WidgetProductPremiumList = z.infer<typeof WidgetProductPremiumList>;

export const WidgetResponse = PaginatedResponse.extend({
  data: z.object({ type: WidgetType }).passthrough(),
});
export type WidgetResponse = z.infer<typeof WidgetResponse>;

export const WidgetMosaicPaginatedResponse = PaginatedResponse.extend({
  data: z.array(z.union([TvLiveCard, MediaCard, ProgramCard, MediaPremiumCard])),
});
export type WidgetMosaicPaginatedResponse = z.infer<typeof WidgetMosaicPaginatedResponse>;

export const WidgetFavoritesMosaicResponse = Response.extend({
  data: z.array(FavoriteCard),
});
export type WidgetFavoritesMosaicResponse = z.infer<typeof WidgetFavoritesMosaicResponse>;

export const WidgetPlayHistoryMosaicResponse = Response.extend({
  data: z.array(PlayHistoryCard),
});
export type WidgetPlayHistoryMosaicResponse = z.infer<typeof WidgetPlayHistoryMosaicResponse>;

/**  MEDIA FLEX */

export const WidgetMediaFlex = z.object({
  id: z.string(),
  type: z.literal("MEDIA"),
  title: z.string(),
  content: z.array(MediaCard),
});
export type WidgetMediaFlex = z.infer<typeof WidgetMediaFlex>;

/** PROMOBOX */
export const WidgetPromoBox = z.object({
  id: z.string(),
  type: z.literal("PROMOBOX"),
  content: z.array(PromoBoxCard),
});

export type WidgetPromoBox = z.infer<typeof WidgetPromoBox>;

/** MOSAIC */
export const WidgetMosaic = z
  .object({
    data: z.array(z.union([MediaPremiumCard, TvLiveCard, MediaCard, ProgramCard])),
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }))
  .transform(({ ...input }) => ({ type: "MOSAIC" as const, ...input }));

export type WidgetMosaic = z.infer<typeof WidgetMosaic>;

export const WidgetMosaicFavorite = z
  .object({
    data: z.array(FavoriteCard),
  })
  .transform(({ data, ...input }) => ({
    id: Math.random().toString(36).substring(2, 9),
    data: data.map(favoriteCard => favoriteCard.resource),
    ...input,
  }));
export type WidgetMosaicFavorite = z.infer<typeof WidgetMosaicFavorite>;

export const WidgetMosaicPlayHistory = z
  .object({
    data: z.array(PlayHistoryCard),
  })
  .transform(({ data, ...input }) => ({
    id: Math.random().toString(36).substring(2, 9),
    data: data.map(playHistoryCard => playHistoryCard.media),
    ...input,
  }));
export type WidgetMosaicPlayHistory = z.infer<typeof WidgetMosaicPlayHistory>;

/** RECO */

export const WidgetRecommandation = z
  .object({
    data: z.array(z.union([TvLiveCard, MediaCard])),
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }))
  .transform(({ ...input }) => ({ type: "RECOS", ...input }));

export type WidgetRecommandation = z.infer<typeof WidgetRecommandation>;

/** QUICK_LINK */

export const WidgetQuickLink = z.object({
  title: z.string(),
  type: z.literal("QUICK_LINK"),
  content: z.array(QuickLinkCard),
});

export type WidgetQuickLink = z.infer<typeof WidgetQuickLink>;

/** TAB_LIST */
export const WidgetTabList = z.object({
  id: z.string(),
  type: z.literal("TAB_LIST"),
  content: z.array(Widget),
});

export type WidgetTabList = z.infer<typeof WidgetTabList>;

export const WidgetTabListPaginatedResponse = PaginatedResponse.extend({
  data: z.object({
    id: z.string(),
    type: z.literal("MEDIA_LIST"),
    content: z.array(MediaCard),
  }),
});
export type WidgetTabListPaginatedResponse = z.infer<typeof WidgetTabListPaginatedResponse>;

/** FAVORITE LIST */
export const WidgetFavorites = z.object({
  data: z.array(FavoriteCard).transform((array: FavoriteCard[]) => {
    const arrayWithResource: FavoriteCardWithResource[] = [];
    for (const card of array) {
      if (card.resource !== null) {
        arrayWithResource.push({ ...card, resource: card.resource });
      }
    }
    return arrayWithResource;
  }),
});
export type WidgetFavorites = z.infer<typeof WidgetFavorites>;

export const WidgetFavoritesResponse = PaginatedResponse.extend({
  data: z.array(FavoriteCard),
});
export type WidgetFavoritesResponse = z.infer<typeof WidgetFavoritesResponse>;

export const WidgetFavoriteList = z.object({
  id: z.string(),
  type: z.literal("FAVORITE_LIST"),
  title: z.string(),
  subtitle: z.string(),
  contentPath: z.string(),
  cta: CtaCard.nullable(),
  states: z
    .object({
      empty: GuestCard,
      guest: EmptyCard,
    })
    .optional(),
});
export type WidgetFavoriteList = z.infer<typeof WidgetFavoriteList>;

/** FAVORITE PROGRAM LIST*/

export const WidgetFavoriteProgramList = z.object({
  id: z.string(),
  type: z.literal("FAVORITE_PROGRAM_LIST"),
  title: z.string(),
  subtitle: z.string(),
  contentPath: z.string(),
  cta: CtaCard.nullable(),
  states: z
    .object({
      empty: GuestCard,
      guest: EmptyCard,
    })
    .optional(),
});
export type WidgetFavoriteProgramList = z.infer<typeof WidgetFavoriteProgramList>;

/** ONGOING PLAY HISTORY */

export const WidgetHistory = z.object({
  data: z.array(PlayHistoryCard).transform((array: PlayHistoryCard[]) => {
    const arrayWithMedia: PlayHistoryCardWithMedia[] = [];
    for (const card of array) {
      if (card.media !== null) {
        arrayWithMedia.push({ ...card, media: card.media });
      }
    }
    return arrayWithMedia;
  }),
});
export type WidgetHistory = z.infer<typeof WidgetHistory>;

export const WidgetHistoryResponse = PaginatedResponse.extend({
  data: z.array(PlayHistoryCard),
});
export type WidgetHistoryResponse = z.infer<typeof WidgetHistoryResponse>;

export const WidgetHistoryIdsResponse = PaginatedResponse.extend({
  data: z.array(PlayHistoryIdsCard),
});
export type WidgetHistoryIdsResponse = z.infer<typeof WidgetHistoryIdsResponse>;

export const WidgetHistoryList = z.object({
  id: z.string(),
  type: z.literal("ONGOING_PLAY_HISTORY"),
  title: z.string(),
  subtitle: z.string(),
  contentPath: z.string(),
  cta: CtaCard.nullable(),
  states: z
    .object({
      empty: GuestCard,
      guest: EmptyCard,
    })
    .optional(),
});
export type WidgetHistoryList = z.infer<typeof WidgetHistoryList>;

/** PLAY HISTORY */

export const WidgetReplayHistoryList = z.object({
  id: z.string(),
  type: z.literal("PLAY_HISTORY"),
  title: z.string(),
  subtitle: z.string(),
  contentPath: z.string(),
  cta: CtaCard.nullable(),
  states: z
    .object({
      empty: GuestCard,
      guest: EmptyCard,
    })
    .optional(),
});
export type WidgetReplayHistoryList = z.infer<typeof WidgetReplayHistoryList>;

/** HERO_LIST */

export const WidgetHeroList = z.object({
  id: z.string(),
  type: z.literal("HERO_LIST"),
  title: z.string(),
  content: z.array(ProgramCard),
});

export type WidgetHeroList = z.infer<typeof WidgetHeroList>;

/** U2C */

export const WidgetU2C = z.object({
  data: z.object({
    content: z.array(MediaCard),
  }),
});

export const WidgetU2CResponse = PaginatedResponse.extend({
  data: z.object({
    type: z.literal("MEDIA_LIST"),
    content: z.array(MediaCard),
  }),
});
