import { DS } from "dslib-tv";
import { IOptions } from "dslib-tv/ui/httpRequest";

import { ConsentHelper } from "../../tools/consentHelper";
import { DevicePreferenceHelper } from "../../tools/devicePreferencesManager";
import { Toast } from "../../tools/uiHelper";
import { RTBF } from "../../utils/rtbf";
import { ConsentEvent } from "../../utils/rtbf/models";
import { Config } from "../consts";

class DidomiAPIImplementation {
  private _didomiUrl = `${Config().DIDOMI.apiServerUrl}`;
  private _didomiToken?: string;
  private _userIdDidomi = DevicePreferenceHelper.getDidomiUserId();
  private _didomiEventId = DevicePreferenceHelper.getDidomiEventId();

  /**
   * Refresh Didomi event, and get tcfcs if not defined
   */
  init = async () => {
    if (DevicePreferenceHelper.getDidomiUserId().length > 0) {
      await DidomiApi.getDidomiEvent();
      if (DevicePreferenceHelper.tcfcs === "") void DidomiApi.updateDidomiEvent(ConsentHelper.consentEvent);
    }
  };

  getTokenDidomi = async () => {
    const url = Config().DIDOMI.authServerUrl;
    const header: IOptions = {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        type: "api-key",
        key: Config().DIDOMI.apiKey,
        secret: Config().DIDOMI.apiSecretKey,
      }),
    };
    const { json } = await DS.HttpRequest.make(url, header);

    return RTBF.DidomiTokens.parse(json).access_token;
  };

  createDidomiUser = async () => {
    const url = `${this._didomiUrl}/consents/users?organization_id=${Config().DIDOMI.organization_id}`;

    let header: IOptions = {};

    header = {
      headers: { authorization: `Bearer ${this._didomiToken}` },
      method: "post",
    };

    try {
      const { json } = await DS.HttpRequest.make(url, header);
      const response = RTBF.ConsentUser.parse(json);
      this._userIdDidomi = response.id;
      DevicePreferenceHelper.save({
        ...DevicePreferenceHelper.cachedPreferences(),
        userIdDidomi: this._userIdDidomi,
      });
    } catch (error: unknown) {
      Log.api.error("Consent User Didomi API - failed to create user", error);
    }
  };

  createDidomiEvent = async (consentEvent: ConsentEvent) => {
    const url = `${this._didomiUrl}/consents/events?organization_id=${Config().DIDOMI.organization_id}`;

    let header: IOptions = {};

    const body = JSON.stringify({
      user: {
        id: this._userIdDidomi,
      },
      ...consentEvent,
    });
    header = {
      headers: { authorization: `Bearer ${this._didomiToken}`, "Content-Type": "application/json" },
      method: "post",
      body,
    };

    try {
      const { json } = await DS.HttpRequest.make(url, header);
      const response = RTBF.ConsentEvent.parse(json);
      this._didomiEventId = response.id ?? "";
      Toast(t("toaster.setConsent_success_text"));
      DevicePreferenceHelper.save({
        ...DevicePreferenceHelper.cachedPreferences(),
        didomiEventId: this._didomiEventId,
        didomiPresented: true,
      });
    } catch (error: unknown) {
      Log.api.error("create didomi event", error);
      Toast(t("toaster.setConsent_error_text"));
    }
  };

  /**
   * This allow to get the TCFCS
   * @param {consentEvent} consentEvent
   */
  private _patchUser = async (consentEvent: ConsentEvent) => {
    const url = `${this._didomiUrl}/consents/users/${this._userIdDidomi}?organization_id=${
      Config().DIDOMI.organization_id
    }&$generate_tcfcs=true`;

    let header: IOptions = {};

    const body = JSON.stringify({
      consents: { ...consentEvent.consents },
    });
    header = {
      headers: { authorization: `Bearer ${this._didomiToken}`, "Content-Type": "application/json" },
      method: "PATCH",
      body,
    };

    try {
      const { json } = await DS.HttpRequest.make(url, header);
      const response = RTBF.ConsentEvent.parse(json);
      DevicePreferenceHelper.tcfcs = response.consents.tcfcs ?? "";
    } catch (error: unknown) {
      Log.api.error("patch user didomi", error);
    }
  };

  updateDidomiEvent = async (consentEvent: ConsentEvent) => {
    const url = `${this._didomiUrl}/consents/events/${this._didomiEventId}?organization_id=${
      Config().DIDOMI.organization_id
    }&user_id=${this._userIdDidomi}`;

    let header: IOptions = {};
    const body = JSON.stringify({
      consents: consentEvent.consents,
    });

    header = {
      headers: { authorization: `Bearer ${this._didomiToken}`, "Content-Type": "application/json" },
      method: "PATCH",
      body,
    };

    try {
      const { json } = await DS.HttpRequest.make(url, header);
      const response = RTBF.ConsentEvent.parse(json);
      Toast(t("toaster.setConsent_success_text"));
      return response;
    } catch (error: unknown) {
      Log.api.error("update didomi event", error);
      Toast(t("toaster.setConsent_error_text"));
    }
  };

  /**
   * Give a didomi event containing datas about the user and his consents.
   */
  getDidomiEvent = async () => {
    let url = `${this._didomiUrl}/consents/events?organization_id=${Config().DIDOMI.organization_id}`;
    url = url.concat(`&user_id=${DevicePreferenceHelper.getDidomiUserId()}`);
    try {
      this._didomiToken = (await this.getTokenDidomi()) ?? "";
    } catch (error) {
      Log.api.error("send didomi Event - Authentication failed");
    }

    let header: IOptions = {};

    header = {
      headers: { authorization: `Bearer ${this._didomiToken}` },
      method: "get",
    };

    try {
      const { json } = await DS.HttpRequest.make(url, header);
      const response = RTBF.ConsentEventResponse.parse(json).data;
      if (response.length > 0) ConsentHelper.setConsents(response[0]);
    } catch (error) {
      Log.api.error("Event didomi API - error : ", error);
    }
  };

  sendDidomiEvent = async (consentEvent: ConsentEvent) => {
    // get didomi token
    try {
      this._didomiToken = (await this.getTokenDidomi()) ?? "";
    } catch (error) {
      Log.api.error("send didomi Event - Authentication failed");
    }

    if (this._didomiToken !== undefined) {
      // create user only if it does not exist
      if (this._userIdDidomi === "") {
        try {
          await this.createDidomiUser();
        } catch (error) {
          Log.api.error("send didomi Event - Create user failed");
        }
      }

      if (this._didomiEventId === "" || this._userIdDidomi === "") {
        await this.createDidomiEvent(consentEvent);
        await this._patchUser(consentEvent);
      } else {
        const event = await this.updateDidomiEvent(consentEvent);
        await this._patchUser(consentEvent);
        ConsentHelper.setConsents(event);
      }
    } else {
      Toast(t("toaster.setConsent_error_text"));
    }
  };
}

export const DidomiApi = new DidomiAPIImplementation();
