import "./playerTitle.scss";

import { DS } from "~/libs";

import { IPlayer, PlayerStreamType } from "../../../tools/player";
import { ITextElement, TextHelper } from "../../../tools/textHelper";
import { getFormatDate } from "../../../tools/time";
import { AudioAnimation } from "../../../tools/uiHelper";

class PlayerRadioSong extends DS.View {
  private _title: ITextElement;
  private _artist: ITextElement;
  constructor(player: IPlayer) {
    super("PlayerRadioSong");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "img",
      style: {
        backgroundImage: `url(${require("@assets/images/player/audio/MUSIC.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    this._title = TextHelper.createTextEmpty(this.rootElement, "title");
    this._artist = TextHelper.createTextEmpty(this.rootElement, "artist");

    player.asset$.didChange(
      value => {
        if (
          value?.resource.radio?.artist !== undefined &&
          value?.resource.radio?.artist !== "" &&
          value?.resource.radio?.song !== undefined &&
          value?.resource.radio?.song !== ""
        ) {
          this._artist.update(value?.resource.radio?.artist);
          this._title.update(value?.resource.radio?.song);
          this._show();
        } else {
          this._hide();
        }
      },
      this,
      true
    );
  }

  private _hide() {
    this.rootElement.style.opacity = "0";
  }

  private _show() {
    this.rootElement.style.opacity = "1";
  }
}

export class PlayerTitle extends DS.View {
  private _title: ITextElement;
  private _top: ITextElement;
  private _bottom: ITextElement;
  private _subtitle: ITextElement;
  private _radioName: ITextElement;
  private _song: PlayerRadioSong;
  player: IPlayer;

  constructor(player: IPlayer) {
    super("PlayerTitle");

    this.player = player;
    this._top = TextHelper.createTextEmpty(this.rootElement, "playerTopTitle");
    this._title = TextHelper.createTextEmpty(this.rootElement, "playerTitle");
    this._bottom = TextHelper.createTextEmpty(this.rootElement, "playerBottomTitle");
    this._subtitle = TextHelper.createTextEmpty(this.rootElement, "playerSubTitle");
    this._radioName = TextHelper.createTextEmpty(this.rootElement, "playerRadioName");

    this.delegate = this._song = new PlayerRadioSong(player);

    player.asset$.didChange(this._update.bind(this), this);
    player.type$.didChange(this._update.bind(this), this);
    this._update();
  }

  private _clear() {
    void this._top.update("");
    void this._bottom.update("");
    this._top.get().classList.remove("live");
    this._top.get().classList.remove("shift");
  }

  private _update() {
    this._clear();
    if (this.player.asset$.value === undefined) return;
    void this._title.update(this.player.asset$.value.resource?.title ?? "");

    switch (this.player.type$.value) {
      case PlayerStreamType.AUDIO:
        this.setWaveAnimation();
        this.audioVideoPlayerTitleUpdate();
        break;
      case PlayerStreamType.VIDEO:
        if (this.player.asset$.value?.isPremium()) {
          const resourceEmbed = this.player.asset$.value?.resource.embed;
          const offerName = resourceEmbed?.playerType === "MEDIA" ? resourceEmbed.products[0]?.label ?? "" : "";
          const categoryName = resourceEmbed?.playerType === "MEDIA" ? resourceEmbed.category?.label ?? "" : "";
          void this._top.update(`{premium}${categoryName}{premium}`);
          void this._bottom.update(`${t("player.meta.movie")} {premium}${offerName}{premium}`);
        } else {
          this.audioVideoPlayerTitleUpdate();
        }
        break;
      case PlayerStreamType.AUDIO_LIVE:
        this._updateRadioLive();
        break;
      case PlayerStreamType.VIDEO_LIVE:
      case PlayerStreamType.VIDEO_LIVE_AD_REPLACEMENT:
        void this._top.update(t("player.meta.live"));
        void this._bottom.update(this.player.asset$.value.resource?.subtitle ?? "");
        this._top.get().classList.add("live");
        break;
      case PlayerStreamType.AUDIO_SHIFT:
        this._updateRadioShift();
        break;
      case PlayerStreamType.VIDEO_SHIFT:
      case PlayerStreamType.VIDEO_SHIFT_AD_REPLACEMENT:
        void this._top.update(t("player.meta.timeshift"));
        void this._bottom.update(this.player.asset$.value.resource?.subtitle ?? "");
        this._top.get().classList.add("shift");
        break;
      case PlayerStreamType.VIDEO_FAST_TV:
      case PlayerStreamType.VIDEO_FAST_TV_PAUSED:
        void this._top.update(t("player.meta.live"));
        this._top.get().classList.add("live");
        void this._bottom.update(this.player.asset$.value.resource?.subtitle ?? "");
        break;
    }
  }

  private _updateRadio() {
    if (this.player.asset$.value === undefined) return;
    this.setWaveAnimation();
    void this._bottom.update(this.player.asset$.value.resource?.subtitle ?? "");
    if (this.player.asset$.value.resource.live) {
      let duration = "";
      if (
        this.player.asset$.value.resource.live.startText !== undefined &&
        this.player.asset$.value.resource.live.endText !== undefined
      ) {
        duration = `${this.player.asset$.value.resource.live.startText} - ${this.player.asset$.value.resource.live.endText}`;
      } else {
        const start = getFormatDate(this.player.asset$.value.resource.live.start);
        const end = getFormatDate(this.player.asset$.value.resource.live.end);
        if (start !== end) {
          duration = `${start} - ${end}`;
        }
      }
      void this._subtitle.update(duration);
    }
    void this._radioName.update(this.player.asset$.value.resource.radio?.extdata?.NomChaine ?? "");
  }

  private _updateRadioLive() {
    this._updateRadio();
    this._top.get().classList.add("live");
    void this._top.update(t("player.meta.live"));
  }

  private _updateRadioShift() {
    this._updateRadio();
    this._top.get().classList.add("shift");
    void this._top.update(t("player.meta.timeshift"));
  }

  audioVideoPlayerTitleUpdate() {
    if (this.player.asset$.value!.resource?.embed?.category !== null) {
      void this._top.update(this.player.asset$.value!.resource?.embed?.category?.label ?? "");
    } else if (this.player.asset$.value!.resource?.embed?.program !== null) {
      void this._top.update(t("player.meta.serie"));
    }
    if (this.player.type$.value === PlayerStreamType.AUDIO) {
      this._top.get().classList.add("wave");
    }
    void this._bottom.update(this.player.asset$.value!.resource?.subtitle ?? "");
  }

  setWaveAnimation() {
    // A container we position where we want the animation to appear
    const animationContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      id: "playerPodcastAudioAnimation",
      className: "playerPodcastAudioAnimation",
    });
    //if (document.querySelector("#playerPodcastAudioAnimation") == null)
    this.rootElement.appendChild(new AudioAnimation(this.player).rootElement);
  }

  onHidden() {
    this.rootElement.style.visibility = "hidden";
  }

  onShown() {
    this.rootElement.style.visibility = "visible";
  }

  rejectsFocus() {
    return true;
  }
}
