import mux from "mux-embed";

import { APIRedbee, Config } from "~/datas";
import { DS } from "~/libs";
import { Listenable } from "~/libs/ui/helpers/Listenable";
import { HandledPlayerErrors } from "~/pages/player/playerPage";
import { HLSEErrorDataType, HLSEventsType, HLSInstance, HlsPlayerType } from "~/player/hls/definitions";
import { PlayerCommon } from "~/player/playerCommon";
import { HTML5PlayerEvent } from "~/player/shaka/definitions";
import { ConsentHelper } from "~/tools/consentHelper";
import { DevicePreferenceHelper } from "~/tools/devicePreferencesManager";
import {
  IPlayer,
  IPlayerLoadOptions,
  IPlayerQuality,
  IPlayerTrack,
  PlayerError,
  PlayerState,
  PlayerStreamType,
} from "~/tools/player";
import { GemiusPlayerEvent, TrackingHelper } from "~/tools/trackingHelper";
import { StatusError } from "~/utils/errors";
import { EmbedType } from "~/utils/rtbf/models";

import { PlayerEvents } from "../../analytics/constants";
import { PlayerCoreConnector } from "../../analytics/PlayerCoreConnector";
import { APIGigyaOIDC } from "../../datas/api/apiGigyaOIDC";

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    Hls?: HlsPlayerType;
  }
}

class Player extends PlayerCommon implements IPlayer {
  video?: HLSInstance;

  uiVisible$ = new Listenable<boolean>(true);
  currentPlayerElt: (HTMLMediaElement & { mux?: IMuxEmbedInstancePlugin }) | null | undefined = null;
  playerInitTime = 0;
  protected playerCoreConnector: PlayerCoreConnector | undefined;
  private _source = "";

  constructor() {
    super();
  }

  private _eventError(event: HLSEventsType, data: HLSEErrorDataType) {
    if (window.Hls) {
      if (data.fatal) {
        switch (data.type) {
          case window.Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            console.log("fatal network error encountered, try to recover");
            this.video?.startLoad();
            break;
          case window.Hls.ErrorTypes.MEDIA_ERROR:
            console.log("fatal media error encountered, try to recover");
            this.video?.recoverMediaError();
            break;
          default:
            // cannot recover
            this.video?.destroy();

            if (this.currentPlayerElt) {
              this.currentPlayerElt.src = "";
            }
            break;
        }
      }
    }
    Log.player.error(`[HLS Player] Error ${event}:`, data);
  }

  private _eventSetState(state: HTML5PlayerEvent) {
    Log.player.warn("event ", state);
    switch (state) {
      case "loadstart":
        this.state$.value = PlayerState.LOADING;
        break;
      case "loadedmetadata":
        {
          if (this._options.audioOnly !== true) {
            // set audio with user's preference by defaut
            const audio = this.getPreferedAudioTrack();
            if (audio !== undefined) {
              this.audioTrack = audio;
            } else {
              Log.player.warn("Can't set up the audio from device preference helper.");
              if (this.video) {
                this.video.audioTrack = 0;
              }
            }

            // set subtitle with user's preference by defaut
            const subtitle = this.getPreferedTextTrack();
            if (subtitle !== undefined) {
              this.subtitleTrack = subtitle;
            } else {
              Log.player.warn("Can't set up the subtitle from device preference helper.");
              if (this.video) {
                this.video.subtitleTrack = 0;
              }
            }
          }
        }
        break;
      case "playing":
        if (this.state$.value !== PlayerState.PLAYING) {
          this.trackAnalytics("play", {
            boolean_value: true,
          });
        }
        this.state$.value = PlayerState.PLAYING;
        this.trackProgramEvent(GemiusPlayerEvent.play);
        this.showSpinner$.value = false;
        if (
          this.type$.value === PlayerStreamType.VIDEO_LIVE ||
          this.type$.value === PlayerStreamType.VIDEO_LIVE_AD_REPLACEMENT ||
          this.type$.value === PlayerStreamType.VIDEO_SHIFT ||
          this.type$.value === PlayerStreamType.VIDEO_SHIFT_AD_REPLACEMENT
        ) {
          this.seekTime$.value = undefined;
        }
        if (this.restarting && this.type$.value !== undefined) {
          this.updateType();
          this.restarting = false;
        }
        break;
      case "timeupdate":
        this.showSpinner$.value = false;
        break;
      case "pause":
        this.state$.value = PlayerState.PAUSED;
        this.trackAnalytics("pause", {
          boolean_value: true,
        });
        break;
      case "waiting":
        this.state$.value = PlayerState.BUFFERING;
        this.trackProgramEvent(GemiusPlayerEvent.buffer);
        break;
      case "seeking":
        this._showSpiner(300);
        this.trackAnalytics("seek", {
          integer_value: this.seekTime$.value,
        });
        break;
      case "ended":
        this.state$.value = PlayerState.ENDED;
        this.trackProgramEvent(GemiusPlayerEvent.complete);
        this.trackAnalytics("ended");
        break;
      case "error":
        this.state$.value = PlayerState.ERROR;
        break;
      default:
        this.state$.value = PlayerState.IDLE;
    }
  }

  private _analyticsEventSetState(event: Event & Partial<{ buffering: boolean; code: number; message: string }>) {
    if (!this.playerCoreConnector) {
      return;
    }

    Log.player.info("EVENT: ", event.type);
    switch (event.type) {
      case window.Hls?.Events.AUDIO_TRACK_LOADING:
        this.playerCoreConnector.triggerCustomEvents(PlayerEvents.LOADING);
        break;
      case PlayerState.BUFFERING:
        this.playerCoreConnector.triggerCustomEvents(
          event.buffering === true ? PlayerEvents.BUFFERING : PlayerEvents.BUFFERED,
          { currentTime: this.currentPlayerElt?.currentTime }
        );
        break;
      case window.Hls?.Events.AUDIO_TRACK_LOADED:
        this.playerCoreConnector.triggerCustomEvents(PlayerEvents.LOADED);
        break;
      case HTML5PlayerEvent.PLAYING: {
        this.state$.value !== PlayerState.PAUSED &&
          this.playerCoreConnector.triggerCustomEvents(PlayerEvents.START, {
            bitrate: this.video?.bandwidthEstimate,
            duration: this.currentPlayerElt?.duration,
            source: this._source,
          });
        break;
      }
      case HTML5PlayerEvent.PAUSE:
        this.playerCoreConnector.triggerCustomEvents(PlayerEvents.PAUSE, {
          currentTime: this.currentPlayerElt?.currentTime,
        });
        break;
      case HTML5PlayerEvent.SEEKED:
        this.playerCoreConnector.triggerCustomEvents(PlayerEvents.SEEKED, {
          currentTime: this.currentPlayerElt?.currentTime,
        });
        break;
      case HTML5PlayerEvent.PLAY:
        this.state$.value === PlayerState.PAUSED &&
          this.playerCoreConnector.triggerCustomEvents(PlayerEvents.RESUME, {
            currentTime: this.currentPlayerElt?.currentTime,
          });
        break;
      case HTML5PlayerEvent.ENDED:
        this.playerCoreConnector.triggerCustomEvents(PlayerEvents.ENDED, {
          currentTime: this.currentPlayerElt?.currentTime,
        });
        break;
      case HTML5PlayerEvent.ERROR:
        this.playerCoreConnector.triggerCustomEvents(PlayerEvents.ERROR, {
          code: event.code,
          message: event.message,
        });
        break;
    }
  }

  private async _registerEvents(video: HLSInstance, destroy = false) {
    if (destroy) {
      video.destroy();
    } else {
      const player = this.currentPlayerElt;
      if (!player) {
        throw new PlayerError(1, "Player - no player element");
      }
      player.onloadstart = () => {
        this._eventSetState(HTML5PlayerEvent.LOADSTART);
      };
      player.onloadedmetadata = () => {
        this._eventSetState(HTML5PlayerEvent.LOADMETADATA);
      };
      player.oncanplay = () => {
        this._eventSetState(HTML5PlayerEvent.CANPLAY);
      };
      player.onplaying = ev => {
        this._analyticsEventSetState(ev);
        this._eventSetState(HTML5PlayerEvent.PLAYING);
      };
      player.onwaiting = () => {
        this._eventSetState(HTML5PlayerEvent.WAITING);
      };
      player.ontimeupdate = () => {
        this.eventTimeUpdate({
          currentTime: player.currentTime,
          duration: player.duration,
          seekable: { start: 0, end: 0 },
          audioTrack: this.audioTrack,
          subtitleTrack: this.subtitleTrack,
        });
      };
      player.onseeking = () => {
        this._eventSetState(HTML5PlayerEvent.SEEKING);
      };
      // Need to use this event because the playing event isn't launched after a seek on Playstation
      player.onseeked = ev => {
        this._eventSetState(HTML5PlayerEvent.PLAYING);
        this._analyticsEventSetState(ev);
      };
      player.onpause = ev => {
        this._eventSetState(HTML5PlayerEvent.PAUSE);
        this._analyticsEventSetState(ev);
      };
      player.onended = ev => {
        this._eventSetState(HTML5PlayerEvent.ENDED);
        this._analyticsEventSetState(ev);
      };

      player.onerror = ev => {
        if (this.currentPlayerElt) {
          this.currentPlayerElt.src = "";
        }
        this._analyticsEventSetState(ev instanceof Event ? ev : ({} as Event));
      };

      player.textTracks.onchange = () => {
        const track = this._getActiveTextTrack(player.textTracks);
        if (track) {
          track.oncuechange = this._changeSubtitlesPosition.bind(this);
        }
      };

      if (window.Hls) {
        video.on(window.Hls.Events.ERROR, (event, data) => {
          this._eventError(event, data);
        });
        video.on(window.Hls.Events.AUDIO_TRACK_LOADING, event => {
          this._analyticsEventSetState(new Event(event));
        });
        video.on(window.Hls.Events.AUDIO_TRACK_LOADED, event => {
          this._analyticsEventSetState(new Event(event));
        });
      }
    }
  }

  attach(containerElement: HTMLElement, audioOnly = false) {
    const tagName = audioOnly ? "audio" : "video";
    this.currentPlayerElt =
      containerElement.querySelector(tagName) ||
      DS.DOMHelper.createElement({
        tagName: tagName,
        parent: containerElement,
        id: "PlayerHLS",
      });
  }

  async destroy() {
    if (await super.destroy()) {
      if (this.playerCoreConnector) {
        this.playerCoreConnector.triggerCustomEvents(PlayerEvents.STOP, {
          currentTime: this.currentPlayerElt?.currentTime,
        });
        this.playerCoreConnector.destroy();
        this.playerCoreConnector = undefined;
      }
      if (this.video) {
        await this._registerEvents(this.video, true);
        return true;
      }
    }
    return false;
  }

  async load(assetID: string, embedType: EmbedType, options?: IPlayerLoadOptions) {
    if (!(window.Hls && window.Hls.isSupported())) {
      throw new PlayerError(1, "Hls is not supported");
    }

    // Check options
    const catchError = options?.catchError ?? true;
    const isRadioLive = options?.isRadioLive ?? false;
    const isTrailer = options?.isTrailer ?? false;
    // Make sure that no player is currently playing
    await this.destroy();

    this.playerInitTime = mux.utils.now();

    this._showSpiner();

    // Get token redbee
    await this.getToken();

    // Load the asset to get the assetID
    try {
      await this.loadAsset(assetID, embedType, isTrailer, isRadioLive);
    } catch (e) {
      // If it's an unconnected error, let it propagate
      if (e instanceof StatusError && e.statusCode in HandledPlayerErrors === true) throw e;
      return this._loadError({}, true, async () => {
        await this.load(assetID, embedType, options);
      });
    }

    // Disable analytics if it's a trailer player
    this.analytics = !isTrailer;

    const radio = await this.getRadioData(assetID, isRadioLive);

    // Get the AssetId to use to launch the playback
    const assetId = await this.getAssetId(assetID, radio, isRadioLive, isTrailer);

    // Return the load status
    try {
      this._options.assetId = assetId;
      const playData = await APIRedbee.getPlayData(this._options, this.ads);

      if (Array.isArray(playData.formats) && playData.formats.length) {
        const formatType = "HLS";
        const format = playData.formats.find(f => f.format === formatType);
        if (format && format.mediaLocator != null) {
          const drmSystems: Partial<
            Record<
              "com.microsoft.playready" | "com.widevine.alpha",
              {
                licenseUrl: string;
              }
            >
          > = {};
          const drmSystemOptions: Partial<Record<string, any>> = {};
          // DRM
          if (format.drm) {
            drmSystems["com.microsoft.playready"] = {
              licenseUrl: format.drm["com.microsoft.playready"]
                ? format.drm["com.microsoft.playready"].licenseServerUrl
                : "",
            };
            drmSystems["com.widevine.alpha"] = {
              licenseUrl: format.drm["com.widevine.alpha"] ? format.drm["com.widevine.alpha"].licenseServerUrl : "",
            };
            if (DS.platform.type === DS.PlatformType.ps5) {
              const persistent = true;
              drmSystemOptions.persistentState = persistent ? "required" : "optional";
              drmSystemOptions.distinctiveIdentifier = "required";
              drmSystemOptions.sessionType = persistent ? "persistent-license" : "temporary";
            }
          }

          // BOOKMARK
          const startTime = playData.bookmarks.lastViewedOffset;

          // Init the player
          if (this.currentPlayerElt) {
            this.video = new window.Hls({
              debug: false,
              startPosition: startTime != null && options?.restart !== true ? startTime / 1000 : -1,
              emeEnabled: !!format.drm,
              drmSystems,
              drmSystemOptions,
            });

            const time = startTime != null && options?.restart !== true ? startTime / 1000 : undefined;
            // Init RedBee Analytics
            if (
              this.analytics &&
              this._options.customer != null &&
              this._options.businessUnit != null &&
              this._options.sessionToken != null
            ) {
              this._source = format.mediaLocator;
              this.playerCoreConnector = new PlayerCoreConnector({
                customer: this._options.customer,
                businessUnit: this._options.businessUnit,
                exposureBaseUrl: this._options.exposureBaseUrl,
                analyticsBaseUrl: playData.analytics?.baseUrl,
                sessionToken: this._options.sessionToken,
                playerName: Config().REDBEE.appName,
                debug: false, // when set to true, the request will not be sent
                device: {
                  id: DS.platform.deviceId,
                  appName: Config().REDBEE.appName,
                  appType: "browser",
                  manufacturer: "Sony",
                  model: DS.platform.type === DS.PlatformType.ps4 ? "PLAYSTATION 4" : "PLAYSTATION 5",
                },
              });
              this.playerCoreConnector.connect(
                {
                  playSessionId: playData.playSessionId,
                  requestId: playData.requestId,
                  cdn: playData.cdn,
                  analytics: playData.analytics,
                  playbackFormat: "DASH",
                  assetId: this._options.assetId,
                  autoplay: this._options.autoplay,
                  startTime: time,
                },
                {
                  name: "Hls",
                  version: window.Hls.version,
                },
                true
              );
            }

            // Events
            await this._registerEvents(this.video);

            this.video.attachMedia(this.currentPlayerElt);

            if (!isTrailer) {
              /* eslint-disable @typescript-eslint/naming-convention */
              mux.monitor(this.currentPlayerElt, {
                ...TrackingHelper.muxData(
                  {
                    video_stream_type: this.isLive() || isRadioLive ? "live" : "on-demand",
                    video_id: assetId,
                    video_duration: this.asset$.value?.resource.duration,
                    video_title: assetID + " - " + this.asset$.value?.resource.title,
                    video_series: this.asset$.value?.resource.embed?.program?.title ?? "",
                    viewer_user_id: ConsentHelper.areAllPartnersAccepted()
                      ? APIGigyaOIDC.userInfo$.value?.uid ?? ""
                      : "",
                    player_init_time: this.playerInitTime,
                    // sub_property_id: ['drm' | 'no_drm'], We are waiting for the info from the RAM API
                  },
                  {
                    name: "HLS.js",
                    version: window.Hls.version,
                  }
                ),
                hlsjs: this.video,
                Hls: window.Hls,
              });
            }

            this.video.loadSource(format.mediaLocator);
          } else {
            throw new PlayerError(1, "Player - no player element");
          }
        } else {
          throw new PlayerError(1, "Player - no HLS data");
        }
      } else {
        throw new PlayerError(1, "Player - no format found");
      }
    } catch (error: unknown) {
      this._loadError(error, catchError, async () => {
        await this.load(assetID, embedType, options);
      });
      throw new PlayerError(1, "Player - bad asset");
    }

    await this.loadRadioLive(isRadioLive, radio);

    // Tracking
    await this.initTracking(this.currentPlayerElt);

    if (this._options.autoplay === true) {
      void this.currentPlayerElt.play();
    }

    if (this._options.muted === true) {
      this.currentPlayerElt.muted = true;
    }
  }

  private _showSpiner = (delay?: number) => {
    if (typeof delay === "number") {
      setTimeout(() => {
        if (this.video && this.state$.value === PlayerState.BUFFERING && !this.isPlaying()) {
          this.showSpinner$.value = true;
        }
      }, 300);
    } else {
      this.showSpinner$.value = true;
    }
  };

  play(firstStart = false, restart = false) {
    if (!this.currentPlayerElt) {
      return;
    }
    this.restarting = restart;
    if (restart) {
      if (this.asset$.value?.isLive() === true && this.asset$.value.resource.live?.start.getTime() !== undefined) {
        // TODO: need to implements the feature from playerRedbee (restart at a specific datetime)
        // this.video?.seekToUTC(this.asset$.value.resource.live?.start.getTime());
        this.currentPlayerElt.currentTime = 0;
      } else {
        this.currentPlayerElt.currentTime = 0;
      }
      this.showControls$.value = true;
      this._showSpiner(300);
    }
    if (typeof this.seekTime$.value === "number") {
      this.currentTime$.value = this.seekTime$.value;
      this.seekTime$.value = undefined;
      this.currentPlayerElt.currentTime = this.currentTime$.value;
      this._showSpiner(300);

      // Gemius don't want seek event to be send on startup so we need to send the hit in specific occasion
      this.trackProgramEvent(GemiusPlayerEvent.seek);
    }
    super.play();
    void this.currentPlayerElt.play();
  }

  pause(isUserAction = false) {
    this.updateType();

    super.pause(isUserAction);
    this.currentPlayerElt?.pause();
  }

  isPlaying() {
    return this.state$.value === PlayerState.PLAYING;
  }

  seekTo(playbackPos?: number) {
    if (this.currentPlayerElt && typeof playbackPos === "number") {
      this._showSpiner();
      this.currentPlayerElt.currentTime = playbackPos;
    }
  }

  forward(preview = false) {
    this.seek(this._TIME_INCREMENT_FW, preview);
    this._showSpiner();
    !preview && this.play();
    this.trackAnalytics("forward", {
      boolean_value: true,
    });
  }

  rewind(preview = false) {
    this.updateType();
    this.seek(this._TIME_INCREMENT_RW, preview);
    this._showSpiner();
    !preview && this.play();
    this.trackAnalytics("rewind", {
      boolean_value: true,
    });
  }

  get audioTrack() {
    const activeTrack = this.video ? this.video.audioTracks[this.video.audioTrack] : null;
    return {
      id: "0",
      active: true,
      track: activeTrack
        ? {
            id: String(activeTrack.id),
            label: activeTrack.name !== null ? activeTrack.name : "",
            language: activeTrack.lang,
            raw: {
              active: true,
              roles: [],
            },
          }
        : {
            id: "0",
            label: "Not Found",
            language: "Not Found",
            raw: { active: true, roles: [] },
          },
    };
  }

  set audioTrack(track: IPlayerTrack) {
    if (this.video) {
      const index = this.video.audioTracks.findIndex(t => t.id === Number(track.track.id));
      if (index !== -1) {
        this.video.audioTrack = index;
        this.trackAnalytics("change_audio", {
          boolean_value: true,
          string_value: track.id,
        });
      }
    }
  }

  audioTracks() {
    const selectedTrack = this.audioTrack;
    const labels: string[] = [];
    return [
      this.audioTrack,
      ...(
        this.video?.audioTracks.filter(function (item) {
          if (item.name != null && item.name !== selectedTrack.track.label && !labels.includes(item.name)) {
            labels.push(item.name);
            return true;
          } else {
            return false;
          }
        }) ?? []
      ).map((item, index) => {
        return {
          id: `${index + 1}`,
          active: false,
          track: {
            id: String(item.id),
            label: item.name !== null ? item.name : "",
            language: item.lang,
            raw: { active: false, roles: [] },
          },
        };
      }),
    ];
  }

  get subtitleTrack() {
    const activeTrack = this.video ? this.video.subtitleTracks[this.video.subtitleTrack] : null;
    return {
      id: "0",
      active: true,
      track: activeTrack
        ? {
            id: String(activeTrack.id),
            label: activeTrack.name !== null ? activeTrack.name : "",
            language: activeTrack.lang,
            raw: { active: true, roles: activeTrack.type === "CLOSED-CAPTIONS" ? ["caption"] : [] },
          }
        : {
            id: "DOTSCREEN_SUBTITLE_EMPTY",
            label: "DOTSCREEN_SUBTITLE_EMPTY",
            language: "Not Found",
            raw: { active: true, roles: [] },
          },
    };
  }

  set subtitleTrack(track: IPlayerTrack) {
    if (!this.video) {
      return;
    }

    if (track.track.id === "DOTSCREEN_SUBTITLE_EMPTY") {
      this.video.subtitleDisplay = false;
      return;
    }
    try {
      this.video.subtitleTrack = Number(track.track.id);
      this.video.subtitleDisplay = true;
      this.trackAnalytics("change_subtitle", {
        string_value: track.id,
        boolean_value: true,
      });
    } catch (error: unknown) {
      Log.player.error("Error when setting subtitles: ", error);
    }
  }

  subtitleTracks() {
    let subtitleEnable = false;

    const playerTrackArr: IPlayerTrack[] = [
      ...(this.video?.subtitleTracks ?? []).map((item, index) => {
        const activeSub = this.video?.subtitleTrack;
        const active = activeSub != null && String(item.id) === String(activeSub);
        const track = {
          id: `${index + 1}`,
          active,
          track: {
            id: String(item.id),
            label: item.name ?? "",
            language: item.lang,
            raw: { roles: item.type === "CLOSED-CAPTIONS" ? ["caption"] : [], active },
          },
        };
        if (active) subtitleEnable = true;

        return track;
      }),
    ];

    const emptyTrack = {
      id: "0",
      active: !subtitleEnable,
      track: {
        id: "DOTSCREEN_SUBTITLE_EMPTY",
        label: "Aucun",
        language: "DISABLE",
        raw: { active: !subtitleEnable, roles: [] },
      },
    };
    playerTrackArr.unshift(emptyTrack);
    if (!subtitleEnable) this.subtitleTrack = emptyTrack;
    return playerTrackArr;
  }

  qualityLevels() {
    return this.video
      ? this.video.levels.map(lvl => {
          return {
            id: lvl.id,
            name: lvl.name,
            bandwidth: lvl.bitrate,
            width: lvl.width,
            height: lvl.height,
          };
        })
      : [];
  }

  set quality(quality: IPlayerQuality) {
    if (this.video) {
      const index = this.video.levels.findIndex(lvl => lvl.id === quality.id);
      if (index !== -1) {
        this.video.currentLevel = index;
      }
    }

    this.trackAnalytics("change_quality", {
      integer_value: quality.width,
    });
  }

  seekToLive() {
    if (this.video && this.currentPlayerElt) {
      if (!this.isPlaying()) {
        void this.currentPlayerElt.play();
      }
      if (typeof this.video?.liveSyncPosition === "number") {
        this.currentPlayerElt.currentTime = this.video.liveSyncPosition;
        this._showSpiner(300);
      }
    }
  }

  applySubtitleStyle() {
    if (this.currentPlayerElt) {
      this.currentPlayerElt.classList.add(DevicePreferenceHelper.cachedPreferences().subtitlePersonnalization.size);
      this.currentPlayerElt.classList.add(DevicePreferenceHelper.cachedPreferences().subtitlePersonnalization.style);
    }
  }

  private _getActiveTextTrack(list: TextTrackList) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].mode === "showing") {
        return list[i];
      }
    }
  }

  private _changeSubtitlesPosition() {
    if (this.currentPlayerElt) {
      const isVisible = this.uiVisible$.value;
      const currentTrack = this._getActiveTextTrack(this.currentPlayerElt.textTracks);
      if (currentTrack) {
        const size = DevicePreferenceHelper.cachedPreferences().subtitlePersonnalization.size;
        type LineType = { [p in typeof size]: [number, number] };
        const linePS4: LineType = {
          SMALL: [16, 21],
          MEDIUM: [13, 17],
          BIG: [10, 12],
        };
        const linePS5: LineType = {
          SMALL: [7, 21],
          MEDIUM: [8, 17],
          BIG: [9, 13],
        };
        const line: LineType = {
          SMALL: [16, -1],
          MEDIUM: [12, -1],
          BIG: [9, -1],
        };
        const cues = currentTrack.activeCues;
        if (cues && cues.length) {
          const activeCue = cues[0];
          if (activeCue instanceof VTTCue && !activeCue.vertical) {
            const list =
              DS.platform.type === DS.PlatformType.ps4
                ? linePS4[size]
                : DS.PlatformType.ps5
                ? linePS5[size]
                : line[size];
            if (activeCue.line === -1 || activeCue.line === list[!isVisible ? 0 : 1]) {
              activeCue.line = list[isVisible ? 0 : 1];
            }
          }
        }
      }
    }
  }

  positionSubtitles(playerControls?: HTMLElement) {
    if (playerControls !== undefined && this.currentPlayerElt) {
      this.uiVisible$.value = playerControls.style.visibility !== "hidden";
      this._changeSubtitlesPosition();
    }
  }

  async getTimelineSpriteCues() {
    return [];
  }
}

export class PlayerVideoHLS extends Player {
  constructor() {
    super();
  }

  async load(assetID: string, embedType: EmbedType, options?: IPlayerLoadOptions) {
    await super.load(assetID, embedType, options);
    // Setup the garbage collector
    options?.collector?.collectRelease(() => {
      void this.destroy();
    });
    if (embedType === "MEDIA") {
      this.type$.value = PlayerStreamType.VIDEO;
    } else if (embedType === "LIVE") {
      this.type$.value =
        this.asset$.value?.resource.isAdReplacement === true
          ? PlayerStreamType.VIDEO_LIVE_AD_REPLACEMENT
          : PlayerStreamType.VIDEO_LIVE;
    }
  }

  seekToLive() {
    this.type$.value =
      this.asset$.value?.resource.isAdReplacement === true
        ? PlayerStreamType.VIDEO_LIVE_AD_REPLACEMENT
        : PlayerStreamType.VIDEO_LIVE;
    super.seekToLive();
  }
}

export class PlayerAudioHLS extends Player {
  constructor() {
    super();
    this._options = {
      ...this._options,
      audioOnly: true,
      autoplay: true,
    };
  }

  async destroy() {
    await super.destroy();
    this.pause();
    return true;
  }

  async load(assetID: string, embedType: EmbedType, options?: IPlayerLoadOptions) {
    await super.load(assetID, embedType, options);
    if (embedType === "MEDIA") {
      this.type$.value = PlayerStreamType.AUDIO;
    } else if (embedType === "LIVE") {
      this.type$.value = PlayerStreamType.AUDIO_LIVE;
    }
    this._TIME_INCREMENT_FW = Player._RADIO_INCREMENT_FW;
    this._TIME_INCREMENT_RW = -Player._RADIO_INCREMENT_RW;
  }

  seekToLive() {
    this.type$.value = PlayerStreamType.AUDIO_LIVE;
    super.seekToLive();
  }
}
