import "./checkboxView.scss";

import { DS } from "~/libs";

import { ConsentHelper } from "../../../tools/consentHelper";
import { AcceptsMouseFocusView } from "../common/mouseSupport/acceptsMouseFocusView";

const toggleOn = require("@assets/images/consent/toggleYes.png");
const toggleOff = require("@assets/images/consent/toggleNo.png");

export class ConsentCheckbox extends AcceptsMouseFocusView {
  private _buttonLogo: HTMLElement;
  protected _buttonState: boolean;

  constructor(toggleState: boolean) {
    super("consentCheckbox", "consentCheckbox");
    this._buttonState = toggleState;

    const logo = toggleState === true ? toggleOn : toggleOff;
    this._buttonLogo = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "img",
      style: {
        backgroundImage: `url(${logo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
  }

  setLogoContent() {
    this._buttonLogo.style.background = `url(${!this._buttonState ? toggleOff : toggleOn}) 50% 50% no-repeat`;
  }

  onSelect() {
    this._buttonState = !this._buttonState;
    this.setLogoContent();

    return true;
  }
}

export class PreferenceCheckBoxView extends ConsentCheckbox {
  private _preference: string;

  constructor(toggleState: boolean, preference: string) {
    super(toggleState);
    this._preference = preference;
  }

  onSelect(): boolean {
    super.onSelect();
    ConsentHelper.togglePurpose(this._preference);
    return true;
  }
}

export class PartnersCheckBoxView extends ConsentCheckbox {
  private _partnerId: string;

  constructor(initialState: boolean, partnerId: string) {
    super(initialState);
    this._partnerId = partnerId;
  }

  onSelect(): boolean {
    super.onSelect();
    ConsentHelper.togglePartner(this._partnerId);
    return true;
  }
}
