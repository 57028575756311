import "./profilPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";
import { GenericIncentivePageFull, IncentiveType } from "~/pages/incentive/incentivePage";
import { ParentalControlPage } from "~/pages/parentalControl/parentalControlPage";

import {
  ClassType,
  ContentButton,
  ContentButtonsContainerView,
  ContentButtonWidgetType,
} from "../../components/views/content/contentButtonView/contentButtonView";
import { Config } from "../../config";
import { APIGigyaOIDC } from "../../datas/api/apiGigyaOIDC";
import { RootMenuPage } from "../../rootMenu/rootMenuPage";
import { OffrePremiumPage } from "../settings/offrePremium/offrePremiumPage";
import { AccessAuvioPage } from "./accessAuvio/accessAuvioPage";

export class ProfilPageView extends AcceptsMouseFocusView implements DS.IPage {
  private _classType: ClassType = ClassType.profil;
  private _buttons: ContentButton[] = [
    {
      id: "ParentalGenericContentButton",
      type: ContentButtonWidgetType.genericContentButton,
      genericLink: {
        id: "parentalButton",
        label: t("profil.button.parentalControlButton"),
        icon: "parentalControl",
        page: () => {
          return new ParentalControlPage();
        },
      },
    },
    {
      id: "AccessAuvioGenericContentButton",
      type: ContentButtonWidgetType.genericContentButton,
      genericLink: {
        id: "AccessAuvioButton",
        label: t("profil.button.auvioAccessButton"),
        icon: "auvioAccess",
        page: () => {
          return new AccessAuvioPage();
        },
      },
    },
    {
      id: "PremiumOffersGenericContentButton",
      type: ContentButtonWidgetType.genericContentButton,
      genericLink: {
        id: "PremiumOffersButton",
        label: t("profil.button.premiumOffersButton"),
        icon: "premiumOffers",
        page: () => {
          return new OffrePremiumPage();
        },
      },
    },
    {
      id: "LogoutGenericContentButton",
      type: ContentButtonWidgetType.genericContentButton,
      genericLink: {
        id: "LogoutButton",
        label: t("profil.button.logoutButton"),
        icon: "logout",
        page: () => {
          return new GenericIncentivePageFull(IncentiveType.logout);
        },
      },
    },
  ];

  constructor() {
    super("profilPageView", "profilPageView");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "profilPageTitle",
      innerText: t("profil.profil_title"),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "profilPicture",
      style: {
        backgroundImage: `url(${require("@assets/images/icons/user-anonymous-selected.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "profilPicture",
      style: {
        backgroundImage: `url(${APIGigyaOIDC.userInfo$.value?.picture ?? "@assets/images/icons/user-anonymous.png"})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "profilUsername",
      innerText: APIGigyaOIDC.userInfo$.value?.given_name ?? "Anonymous",
    });

    this.delegate = DS.createListComponent({
      id: "profilWidget",
      className: "profilWidget",
      modelSource$: new DS.ModelSource<"Buttons">(["Buttons"]),
      viewFactory: item => {
        switch (item) {
          case "Buttons":
            return new ContentButtonsContainerView(this._buttons, undefined, this._classType);
        }
      },
      scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
      scrollDuration: Config.scrollDuration,
      mouseSupport: Config.mouseSupport,
    });
  }
}

export class ProfilPage extends RootMenuPage implements DS.IPage {
  constructor() {
    super("ProfilPage", "RootMenuPage", () => {
      return new ProfilPageView();
    });
  }
}
