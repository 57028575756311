import "./languageSubtitlePage.scss";
import "~/pages/settings/settingsPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";
import { LanguageSelectionPage } from "~/pages/settings/languageSelection/languageSelectionPage";
import { PersonnalizeSubtitlesPage } from "~/pages/settings/personnalizeSubtitles/personnalizeSubtitlePage";
import { RootMenuPage } from "~/rootMenu/rootMenuPage";

import { FocusTracker } from "../../../components/focusTracker";
import { SettingsLongButtonView } from "../../../components/views/settings/settingsLongButtonView/settingsLongButtonView";
import { Config } from "../../../config";
import { navigationStack } from "../../../main";

export enum LanguageSubtitleButtons {
  personnalize = "personnalize",
  defaultLanguage = "defaultLanguage",
  defaultSubtitle = "defaultSubtitle",
}

export class LanguageSubtitlePage extends RootMenuPage implements DS.IPage {
  constructor() {
    super("LanguageSubtitletitlePage", "RootMenuPage", () => {
      return new LanguageSubtitleSettingsPageView();
    });
  }
}

export class LanguageSubtitleSettingsPageView extends AcceptsMouseFocusView implements DS.IPage {
  private _focusTracker?: FocusTracker;
  private _modelSource$ = new DS.ModelSource<LanguageSubtitleButtons>([
    LanguageSubtitleButtons.defaultLanguage,
    LanguageSubtitleButtons.defaultSubtitle,
    LanguageSubtitleButtons.personnalize,
  ]);

  constructor() {
    super("languageSubtitlePageView", "languageSubtitlePageView settingsPages");

    const pageLogo = require("@assets/images/languageSubtitle/languageSubtitle_logo.png");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageTitle",
      innerText: t("languageSubtitlePage.title"),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageIcon",
      style: {
        backgroundImage: `url(${pageLogo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });

    const pageDesc = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "pageDesc",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: pageDesc,
      className: "pageSubtitle",
      innerText: t("languageSubtitlePage.subtitle"),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: pageDesc,
      className: "pageDescription",
      innerText: t("languageSubtitlePage.description"),
    });

    this.delegate = DS.createListComponent(
      {
        id: `languageSubtitle/list`,
        className: "languageSubtitleList",
        modelSource$: this._modelSource$,
        viewFactory: idButton => {
          return new SettingsLongButtonView(idButton);
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
        onSelect: button => {
          switch (button) {
            case LanguageSubtitleButtons.defaultSubtitle:
              navigationStack.pushPage(new LanguageSelectionPage(LanguageSubtitleButtons.defaultSubtitle));
              return true;
            case LanguageSubtitleButtons.defaultLanguage:
              navigationStack.pushPage(new LanguageSelectionPage(LanguageSubtitleButtons.defaultLanguage));
              return true;
            case LanguageSubtitleButtons.personnalize:
              navigationStack.pushPage(new PersonnalizeSubtitlesPage());
              return true;
            default:
              return true;
          }
        },
      },
      list => {
        this._focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        this.collectRelease(this._focusTracker.onRelease);
        void list.setFocusOnIndex(0);
      }
    );
  }
}
