import "./pageTest.scss";

import { DS } from "dslib-tv";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { mouseSupportArrowHoriParams } from "~/components/views/common/mouseSupport/mouseSupportArrow";
import { APIGigyaOIDC } from "~/datas/api/apiGigyaOIDC";
import { DevicePreferenceHelper } from "~/tools/devicePreferencesManager";
import { ParentalControlHelper } from "~/tools/parentalControlHelper";
import { Toast } from "~/tools/uiHelper";
import { ParentalControlLevel } from "~/utils/gigya/models";

import { FocusTracker } from "../../components/focusTracker";
import { LoadingTile } from "../../components/tiles/loading/loadingTile";
import { WidgetView } from "../../components/widgets/widgetView";
import { Config } from "../../config";
import { navigationStack } from "../../main";
import { ConsentPopupPageView } from "../consent/popup/consentPopupPage";
import { GenericPage, GenericPageFull } from "../generic/genericPage";
import { OnboardingPageView } from "../onBoarding/onBoardingPage";
import {
  CreatePinParentalPopupPage,
  DesactivateParentalPopupPage,
  ExitKidsPopupPage,
  ModifyPinParentalPopupPage,
  ValidateLevelParentalPopupPage,
  VerifyPinPopupPage,
} from "../parentalControl/parentalPopupPage";

/*
 * Test widget
 */
type TestWidget = {
  id: string;
  title: string;
  content: TestCard[];
};

/**
 * Test card
 */
type BaseTestCard = {
  id: string;
  title: string;
};

type TestCard =
  | BaseTestCard
  | (BaseTestCard & {
      path: string;
      full: boolean;
    });

// Where we add the main information about the new service we are creating
// one object with the service and the true data
// one object with the service and the mock data
const testWidgets: TestWidget[] = [
  {
    id: "serviceCard1",
    title: "Général",
    content: [
      {
        id: "connection",
        title: "connection",
      },
      {
        id: "mosaicFilter",
        title: "mosaic filter",
        path: "/archives",
        full: true,
      },
      {
        id: "premium",
        title: "Premium",
        path: "/premium",
        full: false,
      },
      {
        id: "contenu_vpn",
        title: "Contenu VPN",
        path: "/media/seconde-main-les-metiers-de-l-occasion-seconde-main-les-metiers-de-l-occasion-3111045",
        full: false,
      },
    ],
  },
  {
    id: "serviceCardKidsParental",
    title: "Contrôle parental et onboarding kids",
    content: [
      {
        id: "resetOnboarding",
        title: "Reset Onboarding preference",
      },
      {
        id: "resetParental",
        title: "Reset parental 3 hours timer",
      },
      {
        id: "resetpin",
        title: "Reset pin",
      },
      {
        id: "createPin",
        title: "Create pin popup",
      },
      {
        id: "modifyPin",
        title: "Modify pin popup",
      },
      {
        id: "validatePin",
        title: "Verify pin popup (-12)",
      },
      {
        id: "verifyPin",
        title: "Validate pin popup",
      },
      {
        id: "desactivatePin",
        title: "Desactivate pin popup",
      },
      {
        id: "exitKids",
        title: "Exit kids pin popup",
      },
      {
        id: "onboardingNotSecured",
        title: "Onboarding page not secured",
      },
      {
        id: "onboardingSecured",
        title: "Onboarding page secured",
      },
    ],
  },
];

class TestCardView extends AcceptsMouseFocusView {
  constructor(testCard: TestCard) {
    super(testCard.id, "tile");
    const cardContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      id: "cardContainer",
      className: "cardContainer",
    });

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "serviceTitle",
      innerText: testCard.title,
    });
  }
}

class TestWidgetView extends WidgetView {
  focusTracker?: FocusTracker;
  list;

  constructor(testWidget: TestWidget, focus: boolean) {
    super("", "serviceWidget");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "widgetTitle",
      innerText: testWidget.title,
    });

    this.delegate = this.list = DS.createListComponent(
      {
        id: `serviceWidget/list`,
        className: "widgetList",
        modelSource$: (this.modelSource = new DS.ModelSource(testWidget.content)),
        viewFactory: testCard => {
          return new TestCardView(testCard);
        },
        loadingPlaceholderFactory: () => new LoadingTile(),
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && mouseSupportArrowHoriParams(),
        onSelect: card => {
          switch (card.id) {
            case "connection":
              navigationStack.pushPage(new ConsentPopupPageView());
              break;
            case "resetpin":
              void ParentalControlHelper.setPin("");
              Toast("Account pin is reset");
              break;
            case "resetParental":
              void ParentalControlHelper.updateParental(
                APIGigyaOIDC.userInfo$?.value?.data?.pin,
                APIGigyaOIDC.userInfo$?.value?.data?.parentalControl ?? ParentalControlHelper.defaultParentalControl,
                new Date(0)
              );
              Toast("Parental timer was disabled");
              break;
            case "resetOnboarding":
              DevicePreferenceHelper.disableKidsSecurity();
              Toast("Kids security disabled");
              break;
            case "createPin":
              navigationStack.pushPage(new CreatePinParentalPopupPage());
              break;
            case "modifyPin":
              navigationStack.pushPage(new ModifyPinParentalPopupPage());
              break;
            case "validatePin":
              navigationStack.pushPage(new ValidateLevelParentalPopupPage(ParentalControlLevel.twelve));
              break;
            case "verifyPin":
              navigationStack.pushPage(new VerifyPinPopupPage());
              break;
            case "desactivatePin":
              navigationStack.pushPage(
                new DesactivateParentalPopupPage(() => {
                  Toast("Pin desactivated");
                })
              );
              break;
            case "exitKids":
              navigationStack.pushPage(
                new ExitKidsPopupPage(() => {
                  Toast("It's a success !");
                })
              );
              break;
            case "onboardingSecured":
              OnboardingPageView.showOnBoardingSecuredPage();
              break;
            case "onboardingNotSecured":
              OnboardingPageView.showOnBoardingNotSecuredPage();
              break;
            default:
              if ("path" in card && "full" in card) {
                card.full
                  ? navigationStack.pushPage(new GenericPageFull(card.path))
                  : navigationStack.pushPage(new GenericPage(card.path));
              }
              break;
          }
          return true;
        },
      },
      list => {
        this.focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        this.collectRelease(this.focusTracker.onRelease);
        focus && void list.setFocusOnIndex(0);
      }
    );
  }
}

export class PageTestView extends DS.View implements DS.IPage {
  constructor() {
    super("", "pageTestView");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "pageTitle",
      innerText: "Page de tests",
    });

    this.delegate = DS.createListComponent({
      id: "pageTestContentList",
      className: "pageTestContentList",
      modelSource$: new DS.ModelSource(testWidgets),
      viewFactory: (_, index) => {
        return new TestWidgetView(testWidgets[index], index === 0);
      },
      scrollingMode: { type: DS.ScrollingType.elasticWindow, horizontal: false },
      scrollDuration: Config.scrollDuration,
      mouseSupport: Config.mouseSupport && {
        focusRange: "visible",
        wheelScrollBy: 432,
      },
    });
  }
}
