import "./splashPage.scss";

import { DS } from "~/libs";

class SplashPage extends DS.View implements DS.IPage {
  constructor() {
    super("SplashPage", "full");
    const splash = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "splash",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: splash,
      className: "logo",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: splash,
      className: "headline",
      innerText: t("splash.headline"),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: splash,
      className: "spinner",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: splash,
      className: "version",
      innerText: `${_APP_VERSION_}-${_APP_HASH_}-${_IS_DEV_ ? "dev" : "prod"}`,
    });
  }

  isPopup = () => true;
}

export const splashPageSingleton = new SplashPage();

class LoaderPage extends DS.View implements DS.IPage {
  constructor() {
    super("SplashPage", "full");
    const splash = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "splash",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: splash,
      className: "spinner",
    });
  }

  isPopup = () => true;
}

export const loaderPageSingleton = new LoaderPage();
