import { APIAuvio } from "~/datas/api/apiAuvio";
import { APIGigyaOIDC } from "~/datas/api/apiGigyaOIDC";
import { DS } from "~/libs";
import { PlatformType } from "~/libs/ui/typings";
import { navigationStack } from "~/main";
import { GenericPage } from "~/pages/generic/genericPage";
import { GenericIncentivePageFull, IncentiveType } from "~/pages/incentive/incentivePage";
import { ExitKidsPopupPage } from "~/pages/parentalControl/parentalPopupPage";
import { PlayerPage } from "~/pages/player/playerPage";
import { DevicePreferenceHelper } from "~/tools/devicePreferencesManager";
import { showErrorRetryPopup } from "~/tools/errorPageHelper";

export class DeeplinkManager {
  callback?: () => void;
  private _appInitilized = false;

  private _lastPayload = "";
  private _isChecking = false;
  private _intervalCheckDeeplink = 0;

  init() {
    this.callback = () => {
      this.callback = undefined;
      this._appInitilized = true;
      void deeplinkManager.checkDeeplinkOnStartup();
    };

    switch (DS.platform.type) {
      case DS.PlatformType.tizen:
        window.addEventListener("appcontrol", this.checkDeeplinkAfterEvent.bind(this));
        break;
      case PlatformType.ps4:
        //  it's a workaround because onLaunchArgument no longer work after a subtitle is displayed
        this._launchCheckDeeplinkCheckInterval();
        break;
      case PlatformType.ps5:
        window.msdk && window.msdk.addEventListener("onLaunchArgument", this.checkDeeplinkAfterEvent.bind(this));
        break;
      default:
        break;
    }
  }

  private _stopCheckDeeplinkInterval() {
    clearInterval(this._intervalCheckDeeplink);
  }

  private _launchCheckDeeplinkCheckInterval() {
    this._stopCheckDeeplinkInterval();

    this._intervalCheckDeeplink = window.setInterval(() => {
      this._appInitilized &&
        !this._isChecking &&
        typeof this.callback !== "function" &&
        void this.checkDeeplinkAfterEvent();
    }, 1000);
  }

  private async _getPayload() {
    switch (DS.platform.type) {
      case PlatformType.ps4:
      case PlatformType.ps5:
        if (window.msdk) {
          return await window.msdk.device.getLaunchArgument();
        }
        break;
      case DS.PlatformType.tizen: {
        const requestedAppControl = tizen.application.getCurrentApplication().getRequestedAppControl();
        const appControlData = requestedAppControl.appControl.data;

        for (let i = 0; i < appControlData.length; i++) {
          if (appControlData[i].key === "PAYLOAD") {
            return JSON.parse(appControlData[i].value[0]).values;
          }
        }
        break;
      }
    }
  }

  private async _getParsedData() {
    try {
      //let payload = "path=" + encodeURIComponent("/emission/la-nuit-du-12-27454") + "&autoplay=false"; // To test PS version
      let payload = await this._getPayload();
      if (payload == null) payload = "";

      if (DS.platform.type === PlatformType.ps4) {
        if (payload === this._lastPayload) return;
        else this._lastPayload = payload;
      }

      if (payload != null) {
        console.log("[deeplink] payload= " + payload);
        let parsed: Record<string, string> = {};

        switch (DS.platform.type) {
          case PlatformType.ps4:
          case PlatformType.ps5:
            payload.replace(/([^=&]+)=([^&]*)/g, function (m: string, key: string, value: string) {
              parsed[decodeURIComponent(key)] = decodeURIComponent(value);
              return parsed[key];
            });
            break;
          case PlatformType.tizen:
            parsed = JSON.parse(payload);
            break;
        }

        return { path: parsed.path ?? "", autoplay: ["true", true].includes(parsed.autoplay) };
      }
    } catch (e) {
      console.error("[deeplink] FATAL " + e);
    }
  }

  private _exitSecuredKids(callback: () => void) {
    if (DevicePreferenceHelper.kidsEnable$.value && DevicePreferenceHelper.isUserKidsSecured()) {
      this.callback = () => {
        callback();
        this.callback = undefined;
      };
      navigationStack.pushPage(
        new ExitKidsPopupPage(function () {
          DevicePreferenceHelper.switchKids(false);
          deeplinkManager.callback?.();
        })
      );
    } else {
      callback();
    }
  }

  private _launchHomePage() {
    navigationStack.destroyStack();

    if (DevicePreferenceHelper.kidsEnable$.value) navigationStack.pushPage(new GenericPage("/kids"));
    else navigationStack.pushPage(new GenericPage("/home"));
  }

  private _launchAssetPage(path: string) {
    this._exitSecuredKids(() => {
      navigationStack.destroyStack();
      navigationStack.pushPage(new GenericPage(path));
    });
  }

  private async _launchPlayerPage(path: string) {
    try {
      const page = await APIAuvio.page(path);
      const media = (() => {
        if (page.data.content.pageType === "PROGRAM") return page.data.content.media;
        if (page.data.content.pageType === "MEDIA") return page.data.content;
      })();
      if (media) {
        this._exitSecuredKids(() => {
          navigationStack.destroyStack();
          void PlayerPage.playAsset(media.id, media.type, "MEDIA", true, {
            rating: media.rating,
          });
        });
        return;
      } else {
        console.error("[deeplink] failed to get data for deeplink " + path + ", trying to open it as an asset page");
        this._launchAssetPage(path);
        return;
      }
    } catch (e) {
      console.error("[deeplink] FATAL: " + e);
    }

    showErrorRetryPopup(async () => {
      await this._launchPlayerPage(path);
    }, this._launchHomePage.bind(this));
  }

  private async _handleDeeplink(onStartup = false) {
    //console.log("[deeplink] _handleDeeplink started");
    this._isChecking = true;

    if (!this._appInitilized) return console.error("[deeplink] the app is not initialized");

    const data = await this._getParsedData();
    if (!data) return; // console.warn("[deeplink] failed to parse the deeplink data/no deeplink");

    console.log("[deeplink] data= " + JSON.stringify(data));

    const { path, autoplay } = data;
    if (!path) return console.error("[deeplink] a path is required");

    if (autoplay) {
      if (APIGigyaOIDC.isConnected()) {
        await this._launchPlayerPage(path);
      } else if (onStartup) {
        this._launchAssetPage(path);
      } else {
        deeplinkManager.callback = () => {
          this.callback = undefined;
          if (APIGigyaOIDC.isConnected()) {
            void this._launchPlayerPage(path);
          } else {
            this._launchAssetPage(path);
          }
        };
        navigationStack.destroyStack();
        navigationStack.pushPage(new GenericIncentivePageFull(IncentiveType.connexion));
      }
    } else {
      this._launchAssetPage(path);
    }

    return true;
  }

  async checkDeeplinkOnStartup() {
    switch (DS.platform.type) {
      case PlatformType.ps4:
      case PlatformType.ps5:
      case DS.PlatformType.tizen:
        {
          try {
            const handled = await this._handleDeeplink(true);
            handled !== true && this._launchHomePage();
          } catch (e: any) {
            console.error("[deeplink] FATAL " + e.message);
          }
          this._isChecking = false;
        }
        break;
      default:
        this._launchHomePage();
        break;
    }
  }

  async checkDeeplinkAfterEvent() {
    try {
      await this._handleDeeplink();
      this._isChecking = false;
    } catch (e: any) {
      console.error("[deeplink] FATAL " + e.message);
    }
    this._isChecking = false;
  }

  exitDeeplinkPage() {
    if (
      navigationStack.pages$.value.length === 1 &&
      ((navigationStack.topPage instanceof GenericPage && !["/home", "/kids"].includes(navigationStack.topPage.path)) ||
        navigationStack.topPage instanceof PlayerPage)
    ) {
      this._launchHomePage();
      return true;
    }
    return false;
  }
}

export const deeplinkManager = new DeeplinkManager();
