import { z } from "zod";

export type RtbfEventName =
  | "card_interaction"
  | "page_view"
  | "widget_interaction"
  | "widget_shown"
  | "user_properties"
  | "widget_trailer_action"
  | "player_ui_interaction"
  | "search"
  | "search_select"
  | "menu_item_selected"
  | "loading_finished"
  | "loading_cancel";

// BASE

export const TrackingBase = z.object({
  platform: z.string(),
  user_id: z.string().optional(),
  version: z.string(),
  interface: z.enum(["remote_control"]),
  page_id: z.string(),
  page_type: z.string(),
  page_referrer: z.string(),
  page_title: z.string(),
});
export type TrackingBase = z.infer<typeof TrackingBase>;

// WIDGETS

export const TrackingWidget = TrackingBase.extend({
  widget_id: z.string(),
  widget_title: z.string(),
  widget_type: z.string(),
  widget_order: z.number().optional(),
  action: z.string(),
  area: z.string(),
});
export type TrackingWidget = z.infer<typeof TrackingWidget>;

export const TrackingWidgetBanner = TrackingWidget.extend({
  media_id: z.string(),
  promo_title: z.string(),
  promo_subtitle: z.string(),
  redirection_ressource: z.enum(["page", "external"]),
});
export type TrackingWidgetBanner = z.infer<typeof TrackingWidgetBanner>;

export const TrackingWidgetTrailer = TrackingWidget.extend({
  media_type: z.enum(["trailer_video"]),
  media_program: z.string(),
  media_title: z.string(),
  media_id: z.string(),
  action: z.enum(["call_to_action", "play", "pause", "sound_on", "sound_off"]),
  area: z.enum(["button", "video"]),
});
export type TrackingWidgetTrailer = z.infer<typeof TrackingWidgetTrailer>;

// CARDS

export const TrackingCard = TrackingWidget.extend({
  card_order: z.number(),
});
export type TrackingCard = z.infer<typeof TrackingCard>;

export const TrackingMediaCard = TrackingCard.extend({
  media_id: z.string(),
  media_title: z.string(),
  media_type: z.enum(["live_video", "live_audio", "vod", "aod", "premium_vod", "trailer_video"]),
  media_category: z.string(),
  media_program: z.string(),
  media_duration: z.number(),
});
export type TrackingMediaCard = z.infer<typeof TrackingMediaCard>;

export const TrackingMediaCardChannel = TrackingMediaCard.extend({
  channel_id: z.string(),
  channel_name: z.string(),
  broadcast_title: z.string(),
});
export type TrackingMediaCardChannel = z.infer<typeof TrackingMediaCardChannel>;

export const TrackingCategoryCard = TrackingCard.extend({
  category_id: z.string(),
  category_name: z.string(),
});
export type TrackingCategoryCard = z.infer<typeof TrackingCategoryCard>;

export const TrackingProductPremiumCard = TrackingCard.extend({
  product_id: z.string(),
  product_name: z.string(),
});
export type TrackingProductPremiumCard = z.infer<typeof TrackingProductPremiumCard>;

export const TrackingQuickLinkCard = TrackingCard.extend({
  string_value: z.string(),
});
export type TrackingQuickLinkCard = z.infer<typeof TrackingQuickLinkCard>;

export const TrackingPromoBoxCard = TrackingCard.extend({
  promo_title: z.string(),
  promo_subtitle: z.string(),
  media_id: z.string(),
});
export type TrackingPromoBoxCard = z.infer<typeof TrackingPromoBoxCard>;

// PLAYER

export const TrackingPlayer = TrackingBase.extend({
  action: z.string(),
  integer_value: z.number().nullish(),
  string_value: z.string().nullish(),
  boolean_value: z.boolean().nullish(),
  media_type: z.enum(["live_video", "live_audio", "vod", "aod", "premium_vod", "trailer_video"]),
  media_category: z.string(),
  media_program: z.string(),
  media_duration: z.string(),
  media_title: z.string(),
  media_id: z.string(),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  media_current_audiostream: z.string().nullish(),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  media_current_quality: z.string().nullish(),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  media_current_subtitle: z.string().nullish(),
});
export type TrackingPlayer = z.infer<typeof TrackingPlayer>;

// EXPLORER

export const TrackingSearch = TrackingBase.extend({
  search_term: z.string(),
  submission: z.null(),
});
export type TrackingSearch = z.infer<typeof TrackingSearch>;

// MENU

export const TrackingMenu = TrackingBase.extend({
  menu_entry: z.string(),
});
export type TrackingMenu = z.infer<typeof TrackingMenu>;

// SPLASH

export const TrackingSplashFinished = TrackingBase.extend({
  duration: z.number(),
});
export type TrackingSplashFinished = z.infer<typeof TrackingSplashFinished>;

export type TrackingData =
  | TrackingBase
  | TrackingWidget
  | TrackingWidgetBanner
  | TrackingWidgetTrailer
  | TrackingCard
  | TrackingMediaCard
  | TrackingMediaCardChannel
  | TrackingCategoryCard
  | TrackingProductPremiumCard
  | TrackingQuickLinkCard
  | TrackingPromoBoxCard
  | TrackingPlayer
  | TrackingSearch
  | TrackingMenu
  | TrackingSplashFinished;
