import "./textboxView.scss";

import { DS } from "~/libs";

import { AcceptsMouseFocusView } from "../common/mouseSupport/acceptsMouseFocusView";
import { EyeIconView } from "../eyeIcon/eyeIconView";

export class TextboxView extends AcceptsMouseFocusView {
  textToSearch: HTMLInputElement;
  textToSearchBox: HTMLElement;
  textToSearch$: DS.IListenable<string>;

  constructor(textToSearch$: DS.IListenable<string>, placeholder?: string) {
    super("TabTextBox", "TabTextBox");
    this.textToSearch$ = textToSearch$;
    this.textToSearchBox = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "TabTextToSearchBox",
    });
    this.textToSearch = DS.DOMHelper.createElement({
      tagName: "input",
      parent: this.textToSearchBox,
      id: "TabTextToSearch",
      className: "TabTextToSearch",
    });

    // input configuration
    this.textToSearch.spellcheck = false;
    this.textToSearch.readOnly = true;
    this.textToSearch.placeholder = placeholder ?? "";

    // prevent native keyboard to show
    this.textToSearch.onfocus = e => {
      e.preventDefault();
    };

    // prevent input scrolling to the beginning on blur event
    this.textToSearch.onblur = _ => {
      const scrollLeft = this.textToSearch.scrollLeft;
      window.setTimeout(() => {
        this.textToSearch.scrollLeft = scrollLeft;
      }, 0);
    };

    textToSearch$.didChange(newValue => {
      this.updateTextFields(newValue);
    }, null);
  }

  updateTextFields = (newValue: string) => {
    // update input value
    this.textToSearch.value = newValue;
    // this part will scroll the input
    const end = this.textToSearch.value.length;
    this.textToSearch.focus();
    this.textToSearch.setSelectionRange(end, end);
    setTimeout(() => {
      this.textToSearch.blur();
    }, 0);
  };

  rejectsFocus() {
    return true;
  }

  setBorderRed() {
    this.textToSearchBox.style.border = "8px solid #cf6679";
  }

  removeBorderRed() {
    this.textToSearchBox.style.border = "solid 8px transparent";
  }
}

export class ExplorerTextBoxView extends TextboxView {
  icon: HTMLImageElement;
  constructor(textToSearch$: DS.IListenable<string>, placeholder: string) {
    super(textToSearch$, placeholder);
    this.icon = DS.DOMHelper.createElement({
      tagName: "img",
      parent: this.textToSearchBox,
      className: "explorerInputIcon",
    });
    this.icon.src = require("@assets/images/icons/exploreBar.png");
  }
}

export class PasswordTextBoxView extends TextboxView {
  private _buttonState$ = new DS.Listenable(true);
  constructor(textToSearch$: DS.Listenable<string>, placeholder?: string) {
    super(textToSearch$, placeholder);

    this.delegate = new EyeIconView(this._buttonState$);

    // default behavior
    this.textToSearch.setAttribute("type", "password");
  }

  rejectsFocus() {
    if (this.textToSearch$.value.length > 0) return false;
    return true;
  }

  onSelect() {
    this._buttonState$.value = !this._buttonState$.value;
    if (this._buttonState$.value) this.textToSearch.setAttribute("type", "password");
    else this.textToSearch.setAttribute("type", "text");
    this.textToSearch.scrollTo(99999, 0);
  }
}
