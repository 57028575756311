export enum PreferenceList {
  COOKIES = "cookies",
  CREATE_ADS_PROFILE = "create_ads_profile",
  CREATE_CONTENT_PROFILE = "create_content_profile",
  IMPROVE_PRODUCTS = "improve_products",
  MARKET_RESEARCH = "market_research",
  MEASURE_AD_PERFORMANCE = "measure_ad_performance",
  MEASURE_CONTENT_PERFORMANCE = "measure_content_performance",
  SELECT_BASIC_ADS = "select_basic_ads",
  SELECT_PERSONALIZED_ADS = "select_personalized_ads",
  SELECT_PERSONALIZED_CONTENT = "select_personalized_content",
  GEOLOCATION_DATA = "geolocation_data",
}

// WARNING: If you modify this list, make sure you update areAllPartnersAccepted function inside consent helper
export const Partners = [
  { id: "1plusxag-bji4k6we", name: "1plusX AG", sdk_id: "92" },
  { id: "adswizz", name: "AdsWizz Inc.", sdk_id: "507" },
  { id: "adyoulike", name: "ADYOULIKE SA", sdk_id: "259" },
  { id: "gemius", name: "Gemius SA", sdk_id: "328" },
  { id: "googleana-4TXnJigR", name: "Google Analytics Products", sdk_id: "c:googleana-4TXnJigR" },
  { id: "improve-digital", name: "Improve Digital", sdk_id: "253" },
  { id: "loopmelt-Ta33ZkDy", name: "LoopMe Limited", sdk_id: "109" },
  { id: "rubicon", name: "Magnite, Inc.", sdk_id: "52" },
  { id: "openx", name: "OpenX", sdk_id: "69" },
  { id: "pubmatic", name: "PubMatic, Inc.", sdk_id: "76" },
  { id: "smaato", name: "Smaato Inc.", sdk_id: "82" },
  { id: "smart-adserver", name: "Smart Adserver", sdk_id: "45" },
  { id: "taboola", name: "Taboola Europe Limited", sdk_id: "42" },
  { id: "teads", name: "Teads", sdk_id: "132" },
  { id: "appnexus", name: "Xandr, Inc.", sdk_id: "32" },
  { name: "AdForm", id: "adform", sdk_id: "50" },
  { name: "The Trade Desk", id: "SyGstvxTG", sdk_id: "21" },
  { name: "Mediamath", id: "mediamath", sdk_id: "79" },
  { name: "Amobee", id: "amobeeinc-KEC9PAKY", sdk_id: "23" },
  { name: "LiquidM", id: "liquidmt-YWaU2sQ2", sdk_id: "254" },
  { name: "Criteo", id: "criteo", sdk_id: "91" },
  { name: "Bidswitch", id: "bidswitch", sdk_id: "128" },
];

export const NumberOfProperties = Partners.length + Object.values(PreferenceList).length;
