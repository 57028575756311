import { PlayerAudioHLS } from "~/player/hls/playerHls";
import { PlayerAudio, PlayerVideo } from "~/player/redbee/playerRedBee";
import { PlayerVideoShaka } from "~/player/shaka/playerShaka";
import { StatusError } from "~/utils/errors";
import {
  EmbedLive,
  EmbedMedia,
  EmbedType,
  Geoloc,
  MediaCard,
  TrackingData,
  TvLiveCard,
  WidgetRecommandation,
} from "~/utils/rtbf/models";

import { DS } from "../libs";
import { QUANTUMCAST } from "../utils/quantumcast";
import { RTBF } from "../utils/rtbf";
import { loadJS } from "./snippets/scripts";

export const WRAPPER_VIDEO_ID = "videoBackground";
export const WRAPPER_AUDIO_ID = "audioBackground";

export class PlayerError extends StatusError {
  errorCode: number;

  constructor(num: number, message: string) {
    super(100 + num, message);
    this.errorCode = num;
  }
}

export enum PlayerState {
  IDLE = "idle",
  LOADING = "loading",
  PLAYING = "playing",
  PAUSED = "paused",
  BUFFERING = "buffering",
  SEEKING = "seeking",
  ENDED = "ended",
  ERROR = "error",
}

export type PlayerErrorObject = {
  code?: number;
  status?: number;
  error: string;
  message: string;
  originalError: {
    message: string;
    httpCode: number;
  };
};

export interface IPlayerAsset {
  type: EmbedType;

  isLive(): boolean;

  isFastTV: boolean;

  isPremium(): boolean;

  isTrailer: boolean;

  meta: RTBF.EmbedMeta | undefined;
  resource: {
    id: string;
    assetId: string;
    title: string;
    subtitle: string;
    background: {
      s: string;
      m: string;
    };
    description: string;
    live?: {
      start: Date;
      end: Date;
      startText?: string;
      endText?: string;
    };
    embed?: EmbedMedia | EmbedLive;
    radio?: QUANTUMCAST.MetadataChannel;
    ramRadio?: RTBF.EmbedRadio;
    geoloc?: Geoloc;
    duration: number;
    isAdReplacement: boolean;
    streamId?: string;
  };
  recommended: WidgetRecommandation | undefined;
  binge: MediaCard | TvLiveCard | undefined;
  timestamp: number;
}

export interface IPlayerQuality {
  id: number;
  name?: string;
  bandwidth?: number;
  framerate?: number;
  height?: number;
  width?: number;
}

export interface IPlayerTrack {
  id: string;
  active: boolean;
  track: {
    id: string;
    label: string;
    language: string | undefined;
    raw?: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
      active: boolean;
      roles: string[];
    };
  };
}

export interface IPlayerLoadOptions {
  collector?: DS.IReleaseCollector;
  catchError?: boolean;
  isRadioLive?: boolean;
  isTrailer?: boolean;
  restart?: boolean;
  isPromoBoxTrailer?: boolean;
}

export enum PlayerStreamType {
  AUDIO = "audio",
  AUDIO_LIVE = "audioLive",
  AUDIO_SHIFT = "audioShift",
  VIDEO = "video",
  VIDEO_LIVE = "videoLive",
  VIDEO_LIVE_AD_REPLACEMENT = "videoLiveAdReplacement",
  VIDEO_SHIFT = "videoShift",
  VIDEO_SHIFT_AD_REPLACEMENT = "videoShiftAdReplacement",
  VIDEO_FAST_TV = "videoFastTv",
  VIDEO_FAST_TV_PAUSED = "videoFastTvPaused",
}

export type PortabilityStatus = "expired" | "active" | "inactive" | "noresident";

/**
 * This is the player as seen when exported.
 */
export interface IPlayerCommon {
  /**
   * @deprecated
   * This is the base object. It is here only if really needed.
   *
   * This is Deprecated because developpers should add
   * the function they want in `players.ts` and use it from the player object.
   *
   * Using this, it might break some logic of the player.
   */
  // Constants
  TIME_INCREMENT_FW: number;
  TIME_INCREMENT_RW: number;

  // All listenables
  currentTime$: DS.Listenable<number>;
  seekTime$: DS.Listenable<number | undefined>;
  duration$: DS.Listenable<number>;
  seekable$: DS.Listenable<{ start: number; end: number }>;
  state$: DS.Listenable<PlayerState>;
  asset$: DS.Listenable<IPlayerAsset | undefined>;
  type$: DS.Listenable<PlayerStreamType | undefined>;
  showControls$: DS.Listenable<boolean>;
  showSpinner$: DS.Listenable<boolean>;
  percentRatio$: DS.Listenable<number>;
  adReplacement$: DS.Listenable<number | undefined>;

  /**
   * Get if the player is muted or set the state.
   */
  muted: boolean;
  /**
   * Define if there is some autoplay on the player.
   */
  autoplay: boolean;
  /**
   * Get if the player is audioOnly or set the state.
   */
  audioOnly: boolean;
  /**
   * Get the last known asset, even if the player is destroyed.
   * Use with caution.
   */
  lastKnownAsset: IPlayerAsset | undefined;
  /**
   * Check or set if the analytics are disabled.
   */
  analytics: boolean;
  /**
   * Destroy the player
   * @returns True if correctly destroyed, false otherwise
   */
  destroy: () => Promise<boolean>;
  /**
   * Play the content
   */
  play: (firstStart?: boolean, restart?: boolean) => void;
  updateType: () => void;
  /**
   * Check if the current stream is live.
   */
  isLive: () => boolean;
  /**
   * Check if the current stream is premium.
   */
  isPremium: () => boolean;
  /**
   * Seek with an offset.
   * @param offset The offset
   * @param preview If the seek is a preview, meaning just update the progress bar.
   */
  seek: (offset: number, preview: boolean) => boolean | undefined;
  /**
   * Cancel a seek.
   */
  cancelSeek: () => void;
  /**
   * Track analytics
   */
  trackAnalytics: (action: string, gtag?: Partial<TrackingData>) => void;

  hideTooltip: () => void;
}

export interface IPlayer extends IPlayerCommon {
  /**
   * Get the actual audio track.
   */
  audioTrack: IPlayerTrack;
  /**
   * Get the actual audio track.
   */
  subtitleTrack: IPlayerTrack;
  /**
   * Set the quality of the player.
   */
  quality: IPlayerQuality;
  /**
   * Attach the player to a div
   */
  attach: (containerElement: HTMLElement, audioOnly?: boolean) => void;
  /**
   * Load an asset
   */
  load: (assetID: string, embedType: EmbedType, options?: IPlayerLoadOptions) => Promise<void>;
  /**
   * Pause the content
   */
  pause: (isUserAction?: boolean) => void;
  /**
   * Check if the content is playing.
   */
  isPlaying: () => boolean;
  /**
   * Forward the stream
   */
  forward: (preview?: boolean) => void;
  /**
   * Rewind the stream
   */
  rewind: (preview?: boolean) => void;
  /**
   * Get all audio tracks.
   */
  audioTracks: () => IPlayerTrack[];
  /**
   * Get all audio tracks.
   */
  subtitleTracks: () => IPlayerTrack[];
  /**
   * Seek with an offset.
   * @param offset The offset
   * @param preview If the seek is a preview, meaning just update the progress bar.
   */
  seekTo: (time?: number) => void;
  /**
   * Go to the current live timestamp.
   */
  seekToLive: () => void;
  /**
   * Get all quality levels. The first one is the auto quality level.
   */
  qualityLevels: () => IPlayerQuality[] | undefined;

  applySubtitleStyle: () => void;
  positionSubtitles: (playerControls?: HTMLElement) => void;

  /**
   * @returns if the player is loading before starting playing
   */
  isLoading: () => boolean;

  registerBackIntent: () => void;
}

/**
 * This is the implementation of the IPlayer interface.
 * When you add a function here, please do not forget to add it
 * in the interface.
 * This allow to expose to developpers only functions members
 * that they can trust.
 */
export let playerVideo: IPlayer;
export let playerAudio: IPlayer;

export const loadPlayer = async () => {
  if (
    DS.platform.type === DS.PlatformType.tizen ||
    DS.platform.type === DS.PlatformType.webos ||
    DS.platform.type === DS.PlatformType.other
  ) {
    fixSubtitleWebOS();
    await loadJS("./player/redbee-player_" + _APP_HASH_ + ".js");
    if (typeof window.redBeeMedia === "undefined") {
      throw new PlayerError(1, "Failed to load Redbee player");
    }

    playerAudio = new PlayerAudio();
    playerVideo = new PlayerVideo();
  } else {
    await Promise.all([
      loadJS("./player/shaka-player_" + _APP_HASH_ + ".js"),
      loadJS("./player/hls.js_" + _APP_HASH_ + ".js"),
    ]);

    if (typeof window.shaka === "undefined") {
      throw new PlayerError(1, "Failed to load Shaka player");
    }
    if (typeof window.Hls === "undefined") {
      throw new PlayerError(1, "Failed to load Hls player");
    }

    playerAudio = new PlayerAudioHLS();
    playerVideo = new PlayerVideoShaka();
  }

  // For testing purpose only
  window.dotscreen.playerAudio = playerAudio;
  window.dotscreen.playerVideo = playerVideo;
};

/**
 * RedBee player implemented a fix for proper subtitle diplay (it can appear twice) on WebOS
 * The player detect webOS by testing : window.webOS.platform.tv === true
 *
 * Problem is even if this branch fix webOS lib loading, I don't have consistent window.webOS.platform results whilte testing :
 * 1) When I load a debug app pointing to the , platform.tv === true, so it's all fine
 * 2) When I use meteonews, platform.tv === undefined (instead platform.unknow === true) even if it runs on a TV
 *
 * This function force platform.tv === true if we detect webOS and it's undefined, as it's supposed to, see WebOS Doc :
 * https://webostv.developer.lge.com/develop/references/webostvjs-webos#platform
 */
function fixSubtitleWebOS() {
  if (DS.platform.type === DS.PlatformType.webos) {
    if (window.webOS?.platform?.tv !== true) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.webOS = { platform: { tv: true } };
    }
  }
}
