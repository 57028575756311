import "./partnerPage.scss";
import "~/pages/settings/settingsPage.scss";

import { ConsentListType, ConsentListView } from "~/components/views/consent/list/consentListView";
import { ConsentSidebar } from "~/components/views/consent/sidebar/consentSidebar";
import { PartnersChoiceButtonType } from "~/components/views/consent/sidebarButton/consentSidebarButton";
import { Config } from "~/config";
import { DS } from "~/libs";
import { RootMenuPage } from "~/rootMenu/rootMenuPage";
import { ConsentHelper } from "~/tools/consentHelper";
import { PartnerType } from "~/utils/rtbf/models";

export enum PartnerWidgetType {
  consentList,
  consentSidebar,
}

export class PartnerPageView extends DS.View implements DS.IPage {
  list: DS.IListComponent<PartnerWidgetType, ConsentListView | ConsentSidebar>;
  partners: PartnerType[];
  choices: PartnersChoiceButtonType[];
  constructor() {
    super("", "partnerPageView settingsPages");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageTitle",
      innerText: t("consent_partners.title"),
    });

    this.partners = ConsentHelper.partners;

    this.choices = [
      PartnersChoiceButtonType.acceptAllPartners,
      PartnersChoiceButtonType.rejectAllPartners,
      PartnersChoiceButtonType.validatePartners,
    ];

    const descriptionContainer = DS.DOMHelper.createElement({
      tagName: "div",
      className: "partnerPageDesc",
    });
    DS.DOMHelper.createElement({
      tagName: "p",
      innerHTML:
        t("consent_partners.description_1") +
        `<a>${t("consent_partners.description_website")}</a>` +
        t("consent_partners.description_2"),
      parent: descriptionContainer,
    });
    DS.DOMHelper.createElement({
      tagName: "p",
      innerHTML: t("consent_partners.description_3"),
      parent: descriptionContainer,
    });
    this.rootElement.appendChild(descriptionContainer);

    this.delegate = this.list = DS.createListComponent({
      id: "",
      className: "partnerPageViewList",
      modelSource$: new DS.ModelSource([PartnerWidgetType.consentList, PartnerWidgetType.consentSidebar]),
      viewFactory: item => {
        switch (item) {
          case PartnerWidgetType.consentList:
            return new ConsentListView({
              type: ConsentListType.partners,
              partners: this.partners,
            });
          case PartnerWidgetType.consentSidebar:
            return new ConsentSidebar(this.choices);
        }
      },
      scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
      scrollDuration: Config.scrollDuration,
      mouseSupport: Config.mouseSupport,
    });
  }
}

export class PartnerPage extends RootMenuPage implements DS.IPage {
  constructor() {
    super("PartnerPage", "RootMenuPage", () => {
      return new PartnerPageView();
    });
  }
}

export class PartnerPageFull extends DS.View implements DS.IPage {
  constructor() {
    super("PartnerPage", "PartnerPageFull");
    this.delegate = new PartnerPageView();
  }
}
