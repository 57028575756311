import { z } from "zod";

import { Response } from "./base";
import { Widget } from "./widget";

export const SearchResult = z.array(Widget);

export type SearchResult = z.infer<typeof SearchResult>;

export const SearchResponse = Response.extend({
  data: SearchResult,
});
export type SearchMediaResponse = z.infer<typeof SearchResponse>;
