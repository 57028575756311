import "./consentSidebarButton.scss";

import { DS } from "~/libs";

import { AcceptsMouseFocusView } from "../../common/mouseSupport/acceptsMouseFocusView";

export enum PreferenceChoiceButtonType {
  acceptAllPreference = "acceptAllPreference",
  rejectAllPreference = "rejectAllPreference",
  validatePreferences = "validatePreferences",
  partners = "partners",
  cookies = "cookies",
  cgu = "cgu",
  privacy = "privacy",
}

export enum PartnersChoiceButtonType {
  acceptAllPartners = "acceptAllPartners",
  rejectAllPartners = "rejectAllPartners",
  validatePartners = "validatePartners",
}

export class ConsentSidebarButtonView extends AcceptsMouseFocusView {
  private _type: PreferenceChoiceButtonType | PartnersChoiceButtonType;

  constructor(type: PreferenceChoiceButtonType | PartnersChoiceButtonType) {
    super(type + "TileView", "choiceButtonTileView");
    this._type = type;
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      id: type,
      className: "choiceButtonTile",
      innerText: t("generic." + this._formatedType()),
    });
  }

  private _formatedType() {
    switch (this._type) {
      case PreferenceChoiceButtonType.acceptAllPreference:
      case PartnersChoiceButtonType.acceptAllPartners:
        return "accept_all";
      case PreferenceChoiceButtonType.rejectAllPreference:
      case PartnersChoiceButtonType.rejectAllPartners:
        return "reject_all";
      case PreferenceChoiceButtonType.validatePreferences:
      case PartnersChoiceButtonType.validatePartners:
        return "validate";
      default:
        return this._type;
    }
  }
}
