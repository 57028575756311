/* eslint-disable @typescript-eslint/no-explicit-any */
import { z } from "zod";
const isISODate = require("is-iso-date");

export const Response = z.object({
  status: z.number(),
});
export type Response = z.infer<typeof Response>;

export const PaginatedResponse = Response.extend({
  meta: z
    .object({
      page: z.object({
        current: z.number(),
        from: z.number().nullable(),
        to: z.number().nullable(),
        last: z.number().nullable(),
        path: z.string(),
        limit: z.number(),
        totalCount: z.number().nullable(),
      }),
    })
    .nullish()
    .transform(arg => (arg != null ? arg : undefined)),
  links: z
    .object({
      first: z.string(),
      last: z.string().nullable(),
      prev: z
        .string()
        .nullable()
        .transform(arg => (arg != null ? arg : undefined)),
      next: z
        .string()
        .nullable()
        .transform(arg => (arg != null ? arg : undefined)),
    })
    .nullish()
    .transform(arg => (arg != null ? arg : undefined)),
});
export type PaginatedResponse = z.infer<typeof PaginatedResponse>;

export const Error = z.object({
  status: z.number(),
  type: z.string(),
  title: z.string(),
  detail: z.string().optional(),
  code: z.string().optional(),
});
export type Error = z.infer<typeof Error>;
export function isError(data: any) {
  return data?.status !== undefined && ((data as Response).status < 200 || (data as Response).status >= 300);
}

export const Tokens = z.object({
  token_type: z.string(),
  expires_in: z.number(),
  access_token: z.string(),
  refresh_token: z.string(),
});
export type Tokens = z.infer<typeof Tokens>;

export const DidomiTokens = z.object({
  access_token: z.string().nullish(),
});
export type DidomiTokens = z.infer<typeof DidomiTokens>;

export const Image = z.object({
  xs: z.string(),
  s: z.string(),
  m: z.string(),
  l: z.string(),
  xl: z.string(),
});
export type Image = z.infer<typeof Image>;

export const GeoLoc = z.object({
  id: z.number(),
  key: z.string(),
  label: z.string(),
});

export type GeoLoc = z.infer<typeof GeoLoc>;

export const Logo = z.object({
  light: z.object({
    svg: z.string(),
    png: z.string(),
  }),
  dark: z.object({
    svg: z.string(),
    png: z.string(),
  }),
});
export type Logo = z.infer<typeof Logo>;

export const LogoFast = z.object({
  url: z.string(),
  width: z.number(),
  height: z.number(),
  position: z
    .object({
      x: z.number(),
      y: z.number(),
    })
    .nullable(),
});
export type LogoFast = z.infer<typeof LogoFast>;

export const Rating = z
  .enum(["-10", "-12", "-16"])
  .nullish()
  .transform(arg => arg ?? undefined);
export type Rating = z.infer<typeof Rating>;

export const Stamp = z.object({
  label: z.string(),
  textColor: z.string(),
  backgroundColor: z.string(),
});
export type Stamp = z.infer<typeof Stamp>;

export const Cta = z.object({
  label: z.string(),
  path: z.string().optional(),
});
export type Cta = z.infer<typeof Cta>;

export const Casting = z.object({
  name: z.string(),
  role: z.string(),
});
export type Casting = z.infer<typeof Casting>;

export const Tag = z
  .object({
    name: z.string(),
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }));
export type Tag = z.infer<typeof Tag>;

export const AdditionalLink = z
  .object({
    label: z.string(),
    icon: z.string(),
    path: z.string().nullable(),
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }));
export type AdditionalLink = z.infer<typeof AdditionalLink>;

export const SEO = z.object({
  title: z
    .string()
    .nullish()
    .transform(arg => arg ?? undefined),
  description: z
    .string()
    .nullish()
    .transform(arg => arg ?? undefined),
  image: z
    .object({
      url: z.string(),
      width: z
        .string()
        .nullish()
        .transform(arg => arg ?? undefined),
      height: z
        .string()
        .nullish()
        .transform(arg => arg ?? undefined),
    })
    .nullish()
    .transform(arg => arg ?? undefined),
});
export type SEO = z.infer<typeof SEO>;

export const StringOrNumber = z.union([z.string(), z.number()]).transform(arg => `${arg}`);
export const SmartAds = z.object({
  siteId: StringOrNumber,
  pageId: StringOrNumber,
  fmtId: z
    .string()
    .nullish()
    .transform(arg => arg ?? undefined),

  categoryList: z
    .array(z.string())
    .nullish()
    .transform(arg => arg ?? undefined),

  ctid: z.string(),
  ctk: z
    .array(z.string())
    .nullish()
    .transform(arg => arg ?? undefined),
  ctn: z.string(),
  ctt: z.enum(["live", "aod", "vod"]),
  ctc: z
    .string()
    .nullish()
    .transform(arg => arg ?? undefined),
  ctd: z.number(),
  ctp: z.string(),
  ctmsid: z.string(),
  cte: z
    .string()
    .nullish()
    .transform(arg => arg ?? undefined),
  cts: z
    .string()
    .nullish()
    .transform(arg => arg ?? undefined),
  ctr: z
    .string()
    .nullish()
    .transform(arg => arg ?? undefined),
});

export type SmartAds = z.infer<typeof SmartAds>;

export const GemiusPlayer = z.object({
  channel: z
    .string()
    .nullish()
    .transform(arg => arg ?? undefined),
  se: z.string(),
  ct: z.string(),
  scd: z.string().optional(),
  sc: z.string().optional(),
  sct: z.string(),
  st: z.string(),
  scte: z.string(),
  tv: z.union([z.number(), z.string()]),
});
export type GemiusPlayer = z.infer<typeof GemiusPlayer>;

export const Gemius = z.object({
  id: z.string().optional(),
  key: z.string().nullish(),
  subs: z.string().nullish(),
  free: z.string().nullish(),
  player: GemiusPlayer.nullish().transform(arg => arg ?? undefined),
});
export type Gemius = z.infer<typeof Gemius>;

export const Chartbeat = z.object({
  section: z.string(),
  author: z.string(),
});
export type Chartbeat = z.infer<typeof Chartbeat>;

export const AIP = z.object({
  AdSID: z.string().nullish(),
  AdZID: z.string().nullish(),
  CDbr: z.string().nullish(),
  CKey: z.string().nullish(),
  CLgth: z.number().nullish(),
  CMdCmsID: z.string().nullish(),
  CMdID: z.string().nullish(),
  CMdTtl: z.string().nullish(),
  CPvr: z.string().nullish(),
  CR: z.string().nullish(),
  CSsn: z.string().nullish(),
  Ccat: z.string().nullish(),
  Cep: z.string().nullish(),
});
export type AIP = z.infer<typeof AIP>;

export const SocialNetworks = z.object({
  facebook: z.object({
    appId: z.string(),
    pages: z.string(),
  }),
  twitter: z.object({
    siteId: z.string(),
    site: z.string(),
  }),
});

export const CategoryTable = z.array(
  z.object({
    id: z.string(),
    label: z.string(),
  })
);

export type CategoryTable = z.infer<typeof CategoryTable>;

export const PeriodTable = z.array(
  z
    .object({
      key: z.string(),
      label: z.string(),
    })
    .transform(({ ...input }) => ({ id: input.key, ...input }))
);

export type PeriodTable = z.infer<typeof PeriodTable>;

export const Filters = z.object({
  categories: CategoryTable.optional(),
  periods: PeriodTable.optional(),
});
export type FilterTypes = "categories" | "both" | false;

export type Filters = z.infer<typeof Filters>;

export const Price = z.object({
  amount: z.number().optional(),
  fractionDigits: z.number().optional(),
  currency: z.string().optional(),
});
export type Price = z.infer<typeof Price>;

export const ProductOffering = z.object({
  productIds: z.array(z.string()),
  productOfferingId: z.string(),
  productOfferingType: z.string(),
  customer: z.string().optional(),
  businessUnit: z.string().optional(),
  externalId: z.string().optional(),
  productRequiresSelectAsset: z.boolean().optional(),
  recurrence: z.string(),
  localizedMetadata: z.array(
    z.object({
      local: z.string().optional(),
      name: z.string().optional(),
    })
  ),
  paymentMethodType: z.string().optional(),
  salesStart: z.string().refine(isISODate, { message: "Not a valid ISO string date " }).or(z.literal("")),
  offeringPrice: z.object({
    price: Price.optional(),
    countryCode: z.string().optional(),
    vat: z
      .object({
        percentage: z.number().optional(),
        included: z.boolean().optional(),
      })
      .optional(),
  }),
  discount: z
    .object({
      price: Price.optional(),
      numberOfRecurringPayments: z.number().optional(),
      freePeriod: z.string().optional(),
    })
    .optional(),
});
export type ProductOffering = z.infer<typeof ProductOffering>;
