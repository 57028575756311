import { DS } from "dslib-tv";

import { IOptions } from "~/libs/ui/httpRequest";
import { IAdsOptions } from "~/player/redbee/definitions";
import { getDeviceUUID } from "~/tools/deviceHelper";
import { IQueryStringParameters, objectToQueryString } from "~/tools/snippets/objectToQueryString";
import { ApiResponse } from "~/utils/api";
import {
  ApiAccountPurchasesResponse,
  ApiLocationResponse,
  ApiPlayResponse,
  ApiPurchase,
  SessionTokens,
} from "~/utils/redbee/models";
import { ApiChannelOnNowResponse } from "~/utils/redbee/models/epg";

import { REDBEE } from "../../utils/redbee";
import { Config } from "..";
import { APIGigyaOIDC } from "./apiGigyaOIDC";

export type RedBeeApiVersions = "v1" | "v2";

const LOCATION_BASE_URL = `customer/${Config().REDBEE.customer}/businessunit/${Config().REDBEE.businessUnit}/`;

export interface IShakaPlayerOptions {
  assetId?: string;
  sessionToken?: string;
  muted?: boolean;
  autoplay?: boolean;
  audioOnly?: boolean;
}

class _APIRedbee {
  private _localStorageTokens = "RedbeeTokens";
  private _tokens: SessionTokens | undefined = undefined;

  constructor() {
    this._tokens = this._loadFromLocalStorage();
  }

  private async _apiWrapper(
    query: string,
    options?: IOptions,
    apiVersion?: RedBeeApiVersions,
    retry?: true
  ): Promise<ApiResponse> {
    const url = APIRedbee._queryToUrl(query, apiVersion);
    // WARNING: Trying to use the shouldRenewTokens here, create an infinite loop (as seen during pairing refactor, 27/05/2024)

    const res = await DS.HttpRequest.make(url, await APIRedbee._requestOptions(options));
    const response = ApiResponse.passthrough().parse(res);

    if (res.status === 401) {
      this.saveTokens(await this.renewRedbeeTokens(getDeviceUUID(), await APIGigyaOIDC.idToken()));
      if (retry !== true) {
        return await this._apiWrapper(url, options, apiVersion, true);
      }
    }

    if (res.status === 200) {
      return response;
    }

    Log.api.error("RedBee API error", res, JSON.stringify(res));
    throw new Error("RedBee API couldn't be made");
  }

  private _loadFromLocalStorage(): SessionTokens | undefined {
    const localStorage = DS.Storage.getItem(this._localStorageTokens);
    if (localStorage === null || localStorage === "") return undefined;

    const parsed = SessionTokens.safeParse(JSON.parse(localStorage));
    return parsed.success === true ? parsed.data : undefined;
  }

  private _shouldRenewTokens = (tokens?: REDBEE.SessionTokens) => {
    if (!tokens) return true;
    const expires_at: number = tokens.expirationDateTime;
    return Date.now() > expires_at - 5 * 60 * 1000;
  };

  /*
   * Return Gigya id_token, and renew it if necessary
   * @throws An error if the token renewal failed;
   */
  async sessionToken(): Promise<string | undefined> {
    if (this._tokens?.sessionToken === undefined || this._shouldRenewTokens(this._tokens)) {
      this.saveTokens(await this.renewRedbeeTokens(getDeviceUUID(), await APIGigyaOIDC.idToken()));
    }
    return this._tokens?.sessionToken;
  }

  async renewRedbeeTokensLogged(deviceId: string, gigyaIdToken: string): Promise<SessionTokens> {
    const res = await DS.HttpRequest.make(
      this._queryToUrl("auth/oidcLogin"),
      await this._requestOptions({
        method: "post",
        body: JSON.stringify({
          jwt: gigyaIdToken,
          device: {
            deviceId,
            name: "Tizen",
            type: "SMART_TV",
          },
        }),
      })
    );

    return REDBEE.SessionTokens.parse(res.json);
  }
  async renewRedbeeTokensAnonymous(deviceId: string): Promise<SessionTokens> {
    const res = await DS.HttpRequest.make(
      this._queryToUrl("auth/anonymous"),
      await this._requestOptions({
        method: "post",
        body: JSON.stringify({
          device: {
            name: "Tizen",
            type: "SMART_TV",
          },
          deviceId,
        }),
      })
    );
    return REDBEE.SessionTokens.parse(res.json);
  }

  async renewRedbeeTokens(deviceId: string, gigyaIdToken: string | undefined): Promise<SessionTokens> {
    try {
      if (gigyaIdToken !== undefined) {
        return await this.renewRedbeeTokensLogged(deviceId, gigyaIdToken);
      } else {
        return await this.renewRedbeeTokensAnonymous(deviceId);
      }
    } catch (e) {
      return await this.renewRedbeeTokensAnonymous(deviceId);
    }
  }

  saveTokens(tokens: SessionTokens | undefined) {
    if (tokens === undefined) {
      this.clear();
    } else {
      this._tokens = tokens;
      DS.Storage.setItem(this._localStorageTokens, JSON.stringify(tokens));
    }
  }

  clear() {
    this._tokens = undefined;
    DS.Storage.removeItem(this._localStorageTokens);
  }

  /**
   * Create and return headers with RedBee bearer authentification
   * @param options Optional headers extra data
   */
  private async _requestOptions(options?: IOptions): Promise<IOptions> {
    const headers = {
      Authorization: `Bearer ${this._tokens?.sessionToken}`,
      accept: "application/json",
      "content-type": "application/json",
    };
    return { ...(options ?? {}), headers };
  }

  refreshToken = async (deviceId: string, gigyaIdToken: string): Promise<SessionTokens> => {
    try {
      const res = await this._apiWrapper(`auth/oidcLogin`, {
        method: "post",
        body: JSON.stringify({
          jwt: gigyaIdToken,
          device: {
            deviceId,
            name: "Tizen",
            type: "SMART_TV",
          },
        }),
      });

      return REDBEE.SessionTokens.parse(res.json);
    } catch (e) {
      const res = await this._apiWrapper(`auth/anonymous`, {
        method: "post",
        body: JSON.stringify({
          device: {
            name: "Tizen",
            type: "SMART_TV",
          },
          deviceId: deviceId,
        }),
      });
      return REDBEE.SessionTokens.parse(res.json);
    }
  };

  redbeeLogout = async () => {
    await this._apiWrapper(
      `auth/login`,
      await this._requestOptions({
        method: "delete",
      })
    );
  };

  getPurchase = async (): Promise<ApiPurchase> => {
    const result = await this._apiWrapper(
      `store/purchase`,
      await this._requestOptions({
        method: "get",
      })
    );

    return ApiPurchase.parse(result.json);
  };

  getAccountPurchases = async (): Promise<ApiAccountPurchasesResponse> => {
    const result = await this._apiWrapper(
      `store/account/purchases`,
      await this._requestOptions({
        method: "get",
      })
    );

    return ApiAccountPurchasesResponse.parse(result.json);
  };

  getLocation = async (): Promise<ApiLocationResponse> => {
    const result = await this._apiWrapper(`location`);
    return REDBEE.ApiLocationResponse.parse(result.json);
  };

  /**
   * Generate parameters for RedBee entitlement API, make the query to get the informations to initialize the player and return the response
   *
   * @param {IShakaPlayerOptions} options
   * @param {IAdsOptions} adsOptions ads
   * @return {Promise<ApiPlayResponse>}
   */
  getPlayData = async (options: IShakaPlayerOptions, adsOptions?: IAdsOptions): Promise<ApiPlayResponse> => {
    const parameters: IQueryStringParameters = {
      supportedFormats: "dash,hls,mss,mp3",
      supportedDrms: "playready,widevine",
      audioOnly: options.audioOnly?.toString() ?? "false",
      deviceType:
        DS.platform.type === DS.PlatformType.ps4
          ? "ps-4"
          : DS.platform.type === DS.PlatformType.ps5
          ? "ps-5"
          : "desktop",
      width: "1920",
      height: "1080",
      autoplay: options.autoplay?.toString() ?? "false",
      mute: options.muted?.toString() ?? "false",
      ifa: getDeviceUUID(),
      appName: Config().REDBEE.appName,
      ...adsOptions,
    };

    const res = await this._apiWrapper(
      `entitlement/${options.assetId}/play?${objectToQueryString(parameters)}`,
      options.sessionToken != null && options.sessionToken.length
        ? {
            headers: {
              Authorization: `Bearer ${options.sessionToken}`,
            },
          }
        : undefined
    );

    return REDBEE.ApiPlayResponse.parse(res.json);
  };

  /**
   * Get programs list for a Fast TV channel, by default only the current one
   * @param channelId The channel id to reques
   * @param minutesForward Include future assets that start sooner than this number of minutes ahead
   * @returns {ApiChannelOnNowResponse}
   */
  getFastTVPrograms = async (channelId: string, minutesForward = 0): Promise<ApiChannelOnNowResponse> => {
    const res = await this._apiWrapper(
      `channel/onnow/${channelId}?minutesForward=${minutesForward}`,
      {
        method: "get",
      },
      "v1"
    );

    return ApiChannelOnNowResponse.parse(res.json);
  };

  private _queryToUrl(query: string, apiVersion?: RedBeeApiVersions) {
    return query !== "location"
      ? `${Config().REDBEE.exposureBaseUrl}/${apiVersion ?? "v2"}/${LOCATION_BASE_URL}${query}`
      : `${Config().REDBEE.exposureBaseUrl}/v1/${LOCATION_BASE_URL}${query}`;
  }
}

export const APIRedbee = new _APIRedbee();
