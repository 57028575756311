import "./contentView.scss";

import { AcceptsMouseFocusView } from "../views/common/mouseSupport/acceptsMouseFocusView";

export class ContentView extends AcceptsMouseFocusView {
  constructor(id: string, classname: string) {
    super(id, `content ${classname}`);
  }
}

export enum ContentItem {
  subtitle = "subtitle",
  categoryChannel = "categoryChannel",
  description = "description",
  casting = "casting",
  tags = "tags",
  buttonWidget = "buttonWidget",
  contentButtonsContainer = "contentButtonsContainer",
  csaRating = "csaRating",
  iconMeta = "iconMeta",
  duration = "duration",
  counter = "counter",
  timeline = "timeline",
  title = "title",
  titleGraphic = "titleGraphic",
  meta = "meta",
  buttonPlay = "buttonPlay",
  categoryFilter = "categoryFilter",
  periodFilter = "periodFilter",
}
