import "./consentListView.scss";

import { ConsentListItemView } from "~/components/views/consent/listItem/consentListItemView";
import { Config } from "~/config";
import { DS } from "~/libs";
import { PartnerType, PreferenceType } from "~/utils/rtbf/models";

export enum ConsentListType {
  preferences = "preferences",
  partners = "partners",
}

export type ConsentList =
  | {
      type: ConsentListType.preferences;
      preferences: PreferenceType[];
    }
  | {
      type: ConsentListType.partners;
      partners: PartnerType[];
    };

export class ConsentListView extends DS.View {
  private _list: DS.IListComponent<PreferenceType | PartnerType, ConsentListItemView>;
  private _modelSource$: DS.IModelSource<PreferenceType | PartnerType>;
  private _nbViewsPerPage: number | undefined;

  constructor(list: ConsentList) {
    super("", `consentItems ${list.type}`);

    this.delegate = this._list = DS.createListComponent(
      {
        id: "",
        className: `consentListView ${list.type}`,
        modelSource$: (this._modelSource$ = new DS.ModelSource<PreferenceType | PartnerType>(
          list.type === ConsentListType.partners ? list.partners : list.preferences
        )),
        viewFactory: (item, index) => {
          return new ConsentListItemView(item, list.type, () => this.expandedIndex(index));
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && {
          focusRange: "visible",
          wheelScrollBy: 152,
        },
        onSelect: item => {
          const view = this._list.viewFromId(item.id);
          view?.onSelect();
          return true;
        },
      },
      list => {
        if (list.pageRange$.value?.last !== undefined) {
          this._nbViewsPerPage = list.pageRange$.value?.last + 1;
        }
      }
    );

    this._list.focusedView$.willChange(
      (_, oldView) => {
        oldView?.rootElement.classList.remove("expand");
        if (oldView !== undefined && "isOpen" in oldView) {
          oldView.isOpen = false;
        }
        this.rootElement.classList.remove("expand-move-up");
        this._resetExpandStyles();
      },
      this,
      true
    );

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "more-items",
      style: {
        backgroundImage: `url(${require("@assets/images/icons/more-items.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });

    this._list?.focusedIndex$.didChange(
      _ => {
        this._toggleIndicator();
      },
      this,
      true
    );
  }

  expandedIndex(index: number) {
    if (this._list.focusedView$.value?.isOpen === false) {
      if (this._list.pageRange$.value?.last !== undefined && this._list.pageRange$.value?.first !== undefined) {
        for (let v = this._list.pageRange$.value.first; v <= this._list.pageRange$.value.last; v++) {
          const view = this._list.viewFromIndex(v);

          // Check if it's the last item on the page is expanded and then if the page is full of items before moving all items up
          if (
            index === this._list.pageRange$.value.last &&
            this._nbViewsPerPage === this._list.pageRange$.value.last - this._list.pageRange$.value.first + 1
          ) {
            view?.rootElement.classList.add("move-up");
          } else if (v > index) {
            view?.rootElement.classList.add("move-down");
          }
        }
      }
      this._list.focusedView$.value.expand();
    } else {
      this._list.focusedView$.value?.close();
      this._resetExpandStyles();
    }
  }

  /*
   * Reset expand styles on all items
   */
  private _resetExpandStyles(): void {
    for (let index = 0; index <= this._modelSource$.value.length; index++) {
      const view = this._list.viewFromIndex(index);
      view?.rootElement.classList.remove("move-up", "move-down");
    }
  }

  /**
   * Show or remove an indicator to show that we have more items or not
   */
  private _toggleIndicator() {
    if (this._modelSource$.value.length - 1 === this._list.pageRange$.value.last) {
      this.rootElement.classList.add("hideMoreItems");
    } else {
      this.rootElement.classList.remove("hideMoreItems");
    }
  }
}
