import { z } from "zod";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const isISODate = require("is-iso-date");

export enum ParentalControlLevel {
  twelve = "-12",
  sixteen = "-16",
  all = "all",
}
export const ParentalControlRating = z.nativeEnum(ParentalControlLevel);
export type ParentalControlRating = z.infer<typeof ParentalControlRating>;

/** Log consent **/
export const GigyaAccountConsent = z.object({
  isConsentGranted: z.boolean(),
  customData: z
    .array(z.record(z.string(), z.string()))
    .nullish()
    .transform(arg => arg ?? undefined),
  lastConsentModified: z
    .string()
    .nullish()
    .transform(arg => arg ?? undefined),
  docDate: z
    .string()
    .nullish()
    .transform(arg => arg ?? undefined),
  locales: z
    .object({
      fr: z.object({
        docDate: z
          .string()
          .nullish()
          .transform(arg => arg ?? undefined),
      }),
    })
    .nullish()
    .transform(arg => arg ?? undefined),
});
export type GigyaAccountConsent = z.infer<typeof GigyaAccountConsent>;

export function isGigyaAccountConsent(data: unknown) {
  return GigyaAccountConsent.safeParse(data).success;
}

export const GigyaAccountConsentList = z.record(z.string(), GigyaAccountConsent);
export type GigyaAccountConsentList = z.infer<typeof GigyaAccountConsentList>;

export const GigyaConsentDetail = z.object({
  isActive: z.boolean(),
  isMandatory: z.boolean(),
  customData: z
    .array(z.record(z.string(), z.string()))
    .nullish()
    .transform(arg => arg ?? undefined),

  consentVaultRetentionPeriod: z.number(),
  legalStatements: z.object({
    fr: z.object({
      documentUrl: z.string(),
      currentDocDate: z
        .string()
        .nullish()
        .transform(arg => arg ?? undefined),
      minDocDate: z
        .string()
        .nullish()
        .transform(arg => arg ?? undefined),
    }),
  }),
});
export type GigyaConsentDetail = z.infer<typeof GigyaConsentDetail>;

export const SiteConsentDetails = z.record(z.string(), GigyaConsentDetail);
export type SiteConsentDetails = z.infer<typeof SiteConsentDetails>;

export const GigyaConsentInfos = z.object({
  apiVersion: z.number(),
  errorCode: z.number(),
  errorDetails: z
    .string()
    .nullish()
    .transform(arg => arg ?? undefined),
  errorMessage: z
    .string()
    .nullish()
    .transform(arg => arg ?? undefined),
  statusCode: z
    .number()
    .nullish()
    .transform(arg => arg ?? undefined),
  siteConsentDetails: SiteConsentDetails,
});

// Pairing
export const GigyaTokens = z.object({
  access_token: z.string(),
  id_token: z.string(),
  refresh_token: z.string(),
  expires_in: z.number(),
});
export type GigyaTokens = z.infer<typeof GigyaTokens>;
export const GigyaTokensResponse = z.object({
  json: GigyaTokens,
});
export type GigyaTokensResponse = z.infer<typeof GigyaTokensResponse>;

export const GigyaDeviceAuthorizationResponse = z.object({
  device_code: z.string(),
  user_code: z.string(),
  expires_in: z.number(),
  interval: z.number(),
  verification_uri: z.string(),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  verification_uri_complete: z.string(),
});
export type GigyaDeviceAuthorizationResponse = z.infer<typeof GigyaDeviceAuthorizationResponse>;

export const GigyaTokenPollingError = z.object({
  error: z.string(),
  error_description: z.string(),
});
export type GigyaTokenPollingError = z.infer<typeof GigyaTokenPollingError>;

export const GigyaTokenPollingErrorResponse = z.object({
  json: GigyaTokenPollingError,
});
export type GigyaTokenPollingErrorResponse = z.infer<typeof GigyaTokenPollingErrorResponse>;

export const GigyaTokenPollingResponse = z.union([GigyaTokenPollingError, GigyaTokens]);

/** User info **/
const GigyaUserInfoBase = z.object({
  uid: z.string(),
  data: z
    .object({
      belgian: z
        .boolean()
        .nullish()
        .transform(arg => arg ?? undefined),
      belgianVerifiedTimestamp: z
        .number()
        .nullish()
        .transform(arg => arg ?? undefined),
      parentalControl: ParentalControlRating.or(z.string().transform(_ => ParentalControlLevel.all)).or(
        z.undefined().transform(_ => ParentalControlLevel.all)
      ),
      parentalControlDisabled: z
        .string()
        .refine(isISODate, { message: "Not a valid ISO string date " })
        .or(z.literal("").transform(_ => undefined))
        .or(z.undefined()),
      pin: z
        .string()
        .nullish()
        .transform(arg => arg ?? undefined),
    })
    .optional(),
  address: z
    .object({
      prefix: z
        .string()
        .nullish()
        .transform(arg => arg ?? undefined),
      country: z
        .string()
        .nullish()
        .transform(arg => arg ?? undefined),
    })
    .optional(),
  email: z
    .string()
    .optional()
    .transform(arg => (arg != null ? arg : undefined)),
  given_name: z
    .string()
    .optional()
    .transform(arg => (arg != null ? arg : undefined)),
  family_name: z
    .string()
    .optional()
    .transform(arg => (arg != null ? arg : undefined)),
  picture: z
    .string()
    .nullish()
    .transform(arg => arg ?? undefined),
  name: z
    .string()
    .nullish()
    .transform(arg => arg ?? undefined),
});

export const GigyaUserInfoRawPref = GigyaUserInfoBase.extend({
  preferences: z.unknown(),
});

export const GigyaUserInfo = GigyaUserInfoBase.extend({
  preferences: GigyaAccountConsentList.optional(),
});
export type GigyaUserInfo = z.infer<typeof GigyaUserInfo>;

/** Aoi responses **/
export const Response = z.object({
  errorCode: z.number(),
});

export const GetGigyaConsentInfos = Response.merge(GigyaConsentInfos);

export const GetJWTResponse = Response.extend({
  id_token: z.string().optional(),
});

export const GigyaErrorReponse = z.object({
  regToken: z.string().nullish(),
});
export type GigyaErrorReponse = z.infer<typeof GigyaErrorReponse>;

export const GigyaRevokeResponse = Response.extend({
  statusCode: z
    .number()
    .nullish()
    .transform(arg => arg ?? undefined),
  statusReason: z
    .string()
    .nullish()
    .transform(arg => arg ?? undefined),
});
export type GigyaRevokeResponse = z.infer<typeof GigyaRevokeResponse>;
