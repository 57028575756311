import { z } from "zod";

import { Response } from "./base";
import { FavoriteCard } from "./cards";

// IS FAVORITE
export const FavoritesResult = z.array(FavoriteCard);

export type FavoritesResult = z.infer<typeof FavoritesResult>;

export const FavoritesResponse = Response.extend({
  data: FavoritesResult,
});
export type FavoritesResponse = z.infer<typeof FavoritesResponse>;

// DELETE FAVORITE
export const deleteFavoriteResponse = Response.extend({
  status: z.number(),
});
export type DeleteFavoriteResponse = z.infer<typeof deleteFavoriteResponse>;

// GET USER FAVORITES

export const GetUserFavoritesResult = z.object({
  medias: z.array(z.string()),
  programs: z.array(z.string()),
});

export type GetUserFavoritesResult = z.infer<typeof GetUserFavoritesResult>;

export const GetUserFavoritesResponse = Response.extend({
  data: GetUserFavoritesResult,
});

export type GetUserFavoritesResponse = z.infer<typeof GetUserFavoritesResponse>;
