/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-var-requires
const isISODate = require("is-iso-date");
import { z } from "zod";

export const SessionTokens = z.object({
  // TODO:
  expirationDateTime: z.string().refine(isISODate, { message: "Not a valid ISO string date " }),
  sessionToken: z.string(),
});
export type SessionTokens = z.infer<typeof SessionTokens>;

export const ApiQueryParameter = z.object({
  name: z.string().optional(),
  value: z.string().optional(),
});

export const ApiUserCapabilities = z.object({
  canChangePassword: z.boolean().optional(),
  canChangeUserNameAndEmail: z.boolean().optional(),
  canChangeEmail: z.boolean().optional(),
  canManageAccount: z.boolean().optional(),
  canManageDevices: z.boolean().optional(),
  canManagePayments: z.boolean().optional(),
  canManagePurchases: z.boolean().optional(),
});

export const ApiUserProfile = z.object({
  username: z.string().optional(),
  displayName: z.string().optional(),
  emailAddress: z.string().optional(),
  userId: z.string().optional(),
  child: z.boolean().optional(),
  owner: z.boolean().optional(),
  emailAddressRequired: z.boolean().optional(),
  language: z.string().optional(),
  capabilities: ApiUserCapabilities.optional(),
});

export const ApiLoginResponse = SessionTokens.extend({
  accountId: z.string().optional(),
  userId: z.string().optional(),
  accountStatus: z.string().optional(),
  crmToken: z.string().optional(),
  expirationDateTime: z.string().optional(),
  sessionToken: z.string().optional(),
  informationCollectionConsentGiven: z.string().optional(),
  informationCollectionConsentRequiredDate: z.string().optional(),
  language: z.string().optional(),
  isOverDeviceLimit: z.boolean().optional(),
  child: z.boolean().optional(),
  configReloadQueryParameter: ApiQueryParameter.optional(),
  userProfile: ApiUserProfile.optional(),
});
