import { DS } from "dslib-tv";

import { deeplinkManager } from "~/tools/deeplinkManager";

import { APIRedbee, Config } from "./datas";
import { APIAuvio } from "./datas/api/apiAuvio";
import { DidomiApi } from "./datas/api/apiDidomi";
import { APIGigyaOIDC } from "./datas/api/apiGigyaOIDC";
import { navigationStack } from "./main";
import { LogConsentPageView } from "./pages/consent/log/logConsentPage";
import { ConsentPopupPageView } from "./pages/consent/popup/consentPopupPage";
import { GenericPage } from "./pages/generic/genericPage";
import { GenericIncentivePageFull, IncentiveType } from "./pages/incentive/incentivePage";
import { PairingPageView } from "./pages/pairing/pairingPage";
import { RootMenuView } from "./rootMenu/rootMenuView";
import { ConsentHelper } from "./tools/consentHelper";
import { getDeviceUUID } from "./tools/deviceHelper";
import { DevicePreferenceHelper } from "./tools/devicePreferencesManager";
import { FavoriteHelper } from "./tools/favoriteHelper";
import { LogConsentHelper } from "./tools/logConsentHelper";
import { loadPlayer, playerAudio } from "./tools/player";
import { PlayHistoryHelper } from "./tools/playHistoryHelper";
import { PremiumHelper } from "./tools/premiumHelper";
import { SettingsHelper } from "./tools/settingsHelper";
import { StopWatch } from "./tools/time";
import { TrackingHelper } from "./tools/trackingHelper";
import { CallbackVoid } from "./typings";

export class CallFlow {
  static loginSuccessCallback?: CallbackVoid = undefined;

  static async initHome(shouldLoadPlayer = false, loadingStopWatch?: StopWatch) {
    // Making sure the player is loaded only once to prevent the deletion of listenables functions each time the home is loaded
    const promiseLoadPlayer = shouldLoadPlayer === true ? loadPlayer() : Promise.resolve();

    // Init Menu && settings helper
    const settings = await APIAuvio.settings();
    SettingsHelper.instance.setData(settings.data);
    RootMenuView.data = {
      menu: settings.data.menu.primary,
      menuKids: settings.data["menu-kids"].primary,
    };

    // Prevent an infinite Auvio loading screen if the Gigya API is KO
    try {
      await APIGigyaOIDC.updateUserInfo();
    } catch (error) {
      Log.app.error("Error while fetching userinfo", error);
    }

    // Init Trackings
    TrackingHelper.initGemiusData(settings.data.globalMeta.gemius);
    TrackingHelper.initGtagData(Config().GTAG.id);

    console.log("[DIDOMI HELPER] initHome");

    // Refresh consents from Didomi if already validated
    await DidomiApi.init();
    const path = DevicePreferenceHelper.kidsEnable$.value === false ? "/home" : "/kids";
    ConsentHelper.areAllPartnersAccepted() &&
      TrackingHelper.initChartbeatData(settings.data.globalMeta.chartbeat, path);

    if (loadingStopWatch) {
      TrackingHelper.track({
        event_name: "loading_finished",
        gtag: {
          duration: loadingStopWatch.time,
        },
      });
    }
    // Init home
    try {
      await promiseLoadPlayer;
    } catch (e) {
      console.error(e);
    }
    if (!DevicePreferenceHelper.hasDidomiPresented()) {
      navigationStack.destroyStack();
      navigationStack.pushPage(new ConsentPopupPageView());
    } else {
      if (APIGigyaOIDC.isConnected() === false) {
        navigationStack.destroyStack();
        navigationStack.pushPage(new GenericIncentivePageFull(IncentiveType.launchConnexion));
      } else {
        await this._initializeUserData();

        navigationStack.destroyStack();

        if (typeof deeplinkManager.callback === "function") deeplinkManager.callback();
        else navigationStack.pushPage(new GenericPage(path));
      }
    }
  }

  /**
   * Init user purchases, favorites, playHistory and checks log consent
   * @param lazyLoadLogConsent Define to false to wait for the log consent response (solve a bug when combined with loginCallback)
   */
  private static async _initializeUserData(lazyLoadLogConsent = true) {
    void PremiumHelper.updatePurchases();
    await Promise.all([
      APIAuvio.getUserFavorites().then(favoriteList => FavoriteHelper.setIds(favoriteList ?? [])),
      APIAuvio.getUserPlayHistory().then(playHistoryList => PlayHistoryHelper.setList(playHistoryList ?? [])),
    ]);
    if (lazyLoadLogConsent === true) void LogConsentPageView.refreshConsentsAndEventuallyShowPopup();
    else await LogConsentPageView.refreshConsentsAndEventuallyShowPopup();
  }

  static async loginWithCallback(loginCallback: CallbackVoid) {
    try {
      await APIGigyaOIDC.updateUserInfo();
    } catch (error) {
      Log.app.error("Error while fetching userinfo", error);
    }
    await this._initializeUserData(false);
    navigationStack.pages$.value.forEach(page => {
      if (page instanceof PairingPageView || page instanceof GenericIncentivePageFull) {
        navigationStack.removePage(page);
      }
    });
    loginCallback();
  }

  static async initialPairing() {
    await APIGigyaOIDC.deviceAuthorization();

    while (APIGigyaOIDC.deviceAuthorization$.value !== undefined) {
      if (APIGigyaOIDC.deviceAuthorization$.value?.device_code === undefined) return;
      const res = await APIGigyaOIDC.tokenRequest(APIGigyaOIDC.deviceAuthorization$.value?.device_code);

      if (res !== undefined && "id_token" in res) {
        APIGigyaOIDC.saveTokens(res);
        APIGigyaOIDC.endDeviceAuthorization();
        APIAuvio.saveTokens(await APIAuvio.renewTokens(getDeviceUUID(), undefined, res.id_token));
        APIRedbee.saveTokens(await APIRedbee.renewRedbeeTokens(getDeviceUUID(), res.id_token));
        if (this.loginSuccessCallback !== undefined) {
          await this.loginWithCallback(this.loginSuccessCallback);
        } else {
          await CallFlow.initHome();
        }
        break;
      } else if (res?.error === "expired_token") {
        await APIGigyaOIDC.deviceAuthorization();
      }
      await DS.delay(10 * 1000);
    }
  }

  static async logout() {
    // Logout from Gigya
    const isLoggedOut = await APIGigyaOIDC.revokeRequest();
    if (isLoggedOut === true) {
      APIGigyaOIDC.clear();
    }

    // logout from redbee too
    try {
      await APIRedbee.redbeeLogout();
      APIRedbee.clear();
    } catch (e) {
      Log.app.error("RedBee logout error", e);
    }

    // Deleting Auvio tokens
    APIAuvio.clear();

    // clear favorite list
    FavoriteHelper.clearFavoriteList();

    // clear play history list
    PlayHistoryHelper.clearlist();

    // Reset log consent date check
    LogConsentHelper.updateLastConsentVerificationDate(true);

    void PremiumHelper.clearPurchases();
    void playerAudio.destroy();

    await APIAuvio.renewTokens(getDeviceUUID());

    RootMenuView.refreshMenu();
    await CallFlow.initHome();
  }
}
