import "./eyePinView.scss";

import { DS } from "~/libs";

import { Config } from "../../../config";
import { AcceptsMouseFocusView } from "../common/mouseSupport/acceptsMouseFocusView";
import { EyeIconView } from "../eyeIcon/eyeIconView";
import { PinCallback, PinView } from "../pin/pinView";

export enum PinTypes {
  Pin = "Pin",
  Eye = "Eye",
}
type EyePinView = PinView | EyeIconView;

export class EyePinViewList extends AcceptsMouseFocusView {
  private _list: DS.IListComponent<PinTypes, EyePinView>;
  private _showPin$ = new DS.Listenable(false);
  private _rejectFocus = false;
  private _pinView?: PinView;

  constructor(pinCallback: PinCallback, label?: string, rejectsFocus?: true) {
    super("", "eyePinListView");

    this.delegate = this._list = DS.createListComponent({
      id: "",
      className: "",
      modelSource$: new DS.ModelSource([PinTypes.Pin, PinTypes.Eye]),
      viewFactory: item => {
        switch (item) {
          case PinTypes.Pin:
            return (this._pinView = new PinView(pinCallback, this._showPin$, label));
          case PinTypes.Eye:
            return new EyeIconView(this._showPin$);
        }
      },
      scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
      scrollDuration: Config.scrollDuration,
      mouseSupport: Config.mouseSupport && { focusRange: "visible" },
      onSelect: item => {
        switch (item) {
          case PinTypes.Eye:
            this._showPin$.value = !this._showPin$.value;
            return true;
          case PinTypes.Pin:
            return true;
        }
      },
    });

    this._list.viewsUpdateCompletedTrigger.didSignal(_ => {
      rejectsFocus === true && this.disable();
    }, this);
  }

  resetPinCode() {
    const pinView = this._list.viewFromId(PinTypes.Pin);
    pinView instanceof PinView && pinView.resetPinCode();
  }

  enable() {
    this._rejectFocus = false;
    this._pinView?.enable();
  }

  disable() {
    this._rejectFocus = true;
    this._pinView?.disable();
  }

  rejectsFocus() {
    return this._rejectFocus;
  }
}
