import { z } from "zod";

import { PageType } from ".";
import { Chartbeat, Gemius, Response, SEO, SmartAds, SocialNetworks } from "./base";
import { PageContent } from "./contents";
import { Widget } from "./widget";

export const PageMeta = z.object({
  seo: SEO.nullish().transform(arg => arg ?? undefined),
  smartAds: SmartAds.nullish().transform(arg => arg ?? undefined),
  gemius: Gemius.nullish().transform(arg => arg ?? undefined),
  chartbeat: Chartbeat.nullish().transform(arg => arg ?? undefined),
  socialNetworks: SocialNetworks.optional(),
  shareUrl: z.string().optional(),
});
export type PageMeta = z.infer<typeof PageMeta>;

export const PageData = z.object({
  id: z.string(),
  pageType: PageType,
  widgets: z.array(Widget),
  layout: z.enum(["kids", "default"]),
  content: PageContent,
});

export type PageData = z.infer<typeof PageData>;

export const Page = Response.extend({
  meta: PageMeta,
  data: PageData,
});
export type Page = z.infer<typeof Page>;
